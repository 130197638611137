import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { IoMdArrowRoundBack, IoIosWarning } from "react-icons/io";
import { gsap } from "gsap";
import axios from "axios";
import "../style/offer_details.css";
function Transaction_details() {
  const [searchParams, setSearchParams] = useSearchParams();
  const states = searchParams.get("states");
  const messageSVG = useRef(null);
  const message_content_Ref = useRef(null);
  const messageRef = useRef(null);
  const [result, setresult] = useState([]);
  const [state, setstate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCategories, setFilteredCategories] = useState(result);

  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  useEffect(() => {
    get_transactions();
  }, []);
  async function get_transactions() {
    var tl = gsap.timeline({});
    try {
      const data = {
        state: states,
      };
      tl.to(".fix", { opacity: 1, pointerEvents: "all" });
      const response = await axios.post(
        "https://auctionbackend.netlify.app/.netlify/functions/server/get/transactions",
        // "http://localhost:5001/get/transactions",
        data
      );

      if (response.data.status === true) {
        setresult(response.data.result);
      } else {
        showMessage(response.data.message, true);
      }
    } catch (error) {
      console.error(error);
      showMessage("حدث خطاء", true);
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }
  useEffect(() => {
    if (result.length > 0) {
      offer_animation();
    }
  }, [result]);
  function offer_animation() {
    var tl = gsap.timeline({});
    tl.fromTo(".offer", { opacity: 0 }, { opacity: 1, stagger: 0.2 });
  }
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm((prev) => (prev = newSearchTerm));

    const filtered = result.filter(
      (data) =>
        data.client_name.toLowerCase().includes(newSearchTerm) ||
        data.id.toString().includes(newSearchTerm)
    );
    setFilteredCategories((prev) => (prev = filtered));
    setstate((prev) => (prev = event.target.value));
    offer_animation();
  };

  return (
    <form className="menuClose" id="main_form">
      <div className="title">
        <h1> المعاملات </h1>
      </div>
      <div id="search">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            handleSearchChange(e);
          }}
          id="filter"
          placeholder="ادخل اسم العميل او رقم المعاملة"
        />
      </div>
      <article className="results">
        {searchTerm.length === 0
          ? result.map((data, index) => (
              <div className="offer" key={index}>
                <div className="info">
                  <h2> {data.client_name}</h2>
                  <span>{data.id}</span>
                </div>
                <Link to={`/view_contract?item=${data.id}`}>
                  <IoMdArrowRoundBack />
                </Link>
              </div>
            ))
          : filteredCategories.map((data, index) => (
              <div className="offer" key={index}>
                <div className="info">
                  <h2> {data.client_name}</h2>
                  <span>{data.id}</span>
                </div>
                <Link to={`/view_contract?item=${data.id}`}>
                  <IoMdArrowRoundBack />
                </Link>
              </div>
            ))}
      </article>

      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}

export default Transaction_details;
