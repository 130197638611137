// import {Link} from 'react-router-dom';
import localforage from "localforage";
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { AiOutlineFileAdd } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import { IoIosAddCircleOutline } from "react-icons/io";
import DateObject from "date-object";
import arabic from "date-object/calendars/cjs/arabic";
import axios from "axios";
import QRCode from "qrcode";
const files = new Map();
function Contract() {
  const typeRef1 = useRef(null);
  const typeRef2 = useRef(null);
  const typeRef5 = useRef(null);
  const typeRef6 = useRef(null);
  const typeRef7 = useRef(null);
  const typeRef8 = useRef(null);
  const messageRef = useRef(null);
  const prop_info2 = useRef(null);
  const RefAlert = useRef(null);
  const RefForm = useRef(null);
  const messageSVG = useRef(null);
  const QRCode_imgRef = useRef(null);
  const canvasRef = useRef(null);
  const printRef = useRef(null);
  const message_content_Ref = useRef(null);
  const [state, setState] = useState(false);
  const [offer, setoffer] = useState({
    client: {},
  });
  const [offer_info, setoffer_info] = useState();
  const [client_info, setclient_info] = useState({});
  const [prop, setProp] = useState({
    table_row_no: "0",
    desc: "",
    Date_of_instrument: "",
    schema_number: "",
    land_number: "",
    owner_name: "",
    svg_edit: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g id="Edit"><g><path d="M3.548,20.938h16.9a.5.5,0,0,0,0-1H3.548a.5.5,0,0,0,0,1Z"></path><path d="M9.71,17.18a2.587,2.587,0,0,0,1.12-.65l9.54-9.54a1.75,1.75,0,0,0,0-2.47l-.94-.93a1.788,1.788,0,0,0-2.47,0L7.42,13.12a2.473,2.473,0,0,0-.64,1.12L6.04,17a.737.737,0,0,0,.19.72.767.767,0,0,0,.53.22Zm.41-1.36a1.468,1.468,0,0,1-.67.39l-.97.26-1-1,.26-.97a1.521,1.521,0,0,1,.39-.67l.38-.37,1.99,1.99Zm1.09-1.08L9.22,12.75l6.73-6.73,1.99,1.99Zm8.45-8.45L18.65,7.3,16.66,5.31l1.01-1.02a.748.748,0,0,1,1.06,0l.93.94A.754.754,0,0,1,19.66,6.29Z"></path></g></g></svg>`,
    svg_delete: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>`,
  });
  const [contract_props, set_contract_props] = useState({
    f_id: [],
    desc: [],
    Date_of_instrument: [],
    schema_number: [],
    land_number: [],
    owner_name: [],
  });
  useEffect(() => {
    console.log(contract_props);
    setData((prevData) => ({
      ...prevData,
      contract_props, // Dynamic property access for any client field
    }));
  }, [contract_props]);
  var [Counter, set_Counter] = useState(0);
  const [main_Table, set_main_Table] = useState([]);
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  const [documents_, setdocuments] = useState();
  async function get_files_info(key, data) {
    if (data.file) {
      files.set(key, data);
    } else {
      var object = files.get(key);
      if (object) {
        var file = object.file;
        object.file = file;
        object.des = data.des;
        files.set(key, object);
      } else {
        files.set(key, { file: null, des: data.des });
      }
    }

    // var document = Object.fromEntries(files);
    // setData((prevData) => ({
    //   ...prevData,
    //   document, // Dynamic property access for any client field
    // }));
    const listItems1 = [];

    files.forEach((doc, key) => {
      listItems1.push(
        <li dir="rtl" key={key}>
          {doc.des}
        </li>
      );
    });

    setdocuments(listItems1);
  }
  const [count, setCount] = useState(0); // State for file counter
  const add_new_file = () => {
    const key = count;

    const labelFiles = document.querySelector(".label_files"); // Get the container element

    // Create a new container element
    const newContainer = document.createElement("div");
    newContainer.classList.add("container"); // Add the 'container' class
    newContainer.id = `container_${count}`;
    // Create delete button
    const action_file = document.createElement("div");
    action_file.classList.add("action_file");
    action_file.id = count;
    action_file.addEventListener("click", () => {
      document.querySelector(`#container_${action_file.id}`).remove();
      var value = action_file.id;
      files.delete(value);
      // var document = Object.fromEntries(files);
      // setData((prevData) => ({
      //   ...prevData,
      //   document, // Dynamic property access for any client field
      // }));
      setCount(count - 1);
    });
    var delete_svg = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>`;

    // Create the input elements and icon
    const inputSpan = document.createElement("span");
    inputSpan.classList.add("span_input");
    const fileInput = document.createElement("input");
    fileInput.type = "text";
    fileInput.id = `file_des_${count}`; // Use template literal for clear naming
    fileInput.name = `file_des_${count}`; // Use template literal for clear naming
    fileInput.placeholder = "اسم الملف";
    fileInput.dir = "rtl";

    const fileSpan = document.createElement("span");
    fileSpan.classList.add("span_file");
    const fileIcon = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g id="File_On"><path d="M19.485,7.35l-4.97-4.86a1.466,1.466,0,0,0-1.05-.43h-6.9a2.5,2.5,0,0,0-2.5,2.5V19.44a2.507,2.507,0,0,0,2.5,2.5h10.87a2.507,2.507,0,0,0,2.5-2.5V8.42A1.49,1.49,0,0,0,19.485,7.35Zm-1.27.15h-2.34a1.5,1.5,0,0,1-1.5-1.5V3.75Zm.72,11.94a1.5,1.5,0,0,1-1.5,1.5H6.565a1.5,1.5,0,0,1-1.5-1.5V4.56a1.5,1.5,0,0,1,1.5-1.5h6.81V6a2.5,2.5,0,0,0,2.5,2.5h3.06Z"></path></g></svg>`;
    const fileUploadInput = document.createElement("input");
    fileUploadInput.type = "file";
    fileUploadInput.id = `file_${count}`; // Use template literal for clear naming
    fileUploadInput.name = `file_${count}`; // Use template literal for clear naming
    fileUploadInput.className = "file_input"; // Add a class for styling

    // Append input elements to their respective spans
    inputSpan.appendChild(fileInput);
    fileSpan.innerHTML = fileIcon;
    fileSpan.appendChild(fileUploadInput);
    action_file.innerHTML = delete_svg;
    action_file.addEventListener("click", () => {
      files.delete(key);
    });
    // Append both spans to the new container
    newContainer.appendChild(inputSpan);
    newContainer.appendChild(fileSpan);
    newContainer.appendChild(action_file);
    // Append the new container to the `label_files` element
    labelFiles.appendChild(newContainer);
    fileInput.addEventListener("input", () => {
      get_files_info(key, {
        des: fileInput.value,
      });
    });

    fileUploadInput.addEventListener("change", (event) => {
      const file = event.target.files[0];
      console.log(file);
      const des = document.getElementById(`file_des_${count}`);
      get_files_info(key, {
        file: file,
        des: des.value,
      });
    });
    // Increment the counter for unique file names
    setCount(count + 1);
  };
  const handelOnclick = (type) => {
    var no_nav = type.split("-")[1];
    var arr = [];
    document.querySelectorAll("article").forEach((article) => {
      arr.push(article.id);
    });
    var ar_type = type.split("");
    ar_type.pop();
    ar_type = ar_type.join("");

    for (let i = 0; i < arr.length - 1; i++) {
      document.querySelectorAll("article[data-active=true]").forEach((item) => {
        item.dataset.active = false;
      });
      document.querySelector(`#s-${no_nav}`).dataset.active = true;
    }
  };
  useLayoutEffect(() => {
    var count = 1;
    document.querySelectorAll(".part").forEach((part) => {
      part.dataset.type = `s-` + count + `-`;
      count++;
    });
    var count_article = 1;
    document.querySelectorAll("article").forEach((article) => {
      if (count_article === 1) {
        article.dataset.active = true;
      } else {
        article.dataset.active = false;
      }
      article.id = `s-` + count_article;
      count_article++;
    });
  }, []);
  function addProps() {
    gsap.to(prop_info2.current, { display: "flex" });
    gsap.to(prop_info2.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
      delay: 0.2,
    });
  }
  function fadeOut() {
    gsap.to(prop_info2.current, { display: "none", opacity: 0, duration: 0.5 });
  }
  const handlePropChange = (event) => {
    const updatedPropData = { ...prop }; // Copy the existing formData object
    updatedPropData[event.target.name] = event.target.value; // Update specific property
    setProp(updatedPropData);
  };
  var stop = false;
  function createTableRow() {
    const tbody = document.querySelector(".main_tbody");
    const tr = document.createElement("tr");
    const cells = []; // Empty array to store cells
    const data = [];
    // Iterate through object properties using destructuring
    for (const [key, value] of Object.entries(prop)) {
      if (value === "") {
        for (const [key, value] of Object.entries(contract_props)) {
          contract_props[key].pop();
        }
        return showMessage("لايمكن ترك حقل فارع"), (stop = true);
      } else {
        tr.id = "table_row_" + Counter;
        tr.dataset.id = Counter;
        const td = document.createElement("td");
        // Clear the input element's value
        if (key == "svg_edit" || key == "svg_delete") {
          td.classList.add("btns");
          td.dataset.id = Counter;
          if (key == "svg_edit") {
            td.dataset.action = "svg_edit";
            td.dataset.clicked = "false";
            td.addEventListener("click", () => {
              var tr = document.querySelector(`#table_row_${td.dataset.id}`);
              if (tr) {
                if (td.dataset.clicked === "false") {
                  tr.querySelectorAll("td").forEach((td) => {
                    if (td.classList.contains("btns")) {
                    } else {
                      td.classList.add("edit_on");
                      td.setAttribute("contentEditable", true);
                    }
                  });
                  td.dataset.clicked = "true";
                } else if (td.dataset.clicked === "true") {
                  tr.querySelectorAll("td").forEach((td) => {
                    if (td.classList.contains("btns")) {
                    } else {
                      td.classList.remove("edit_on");
                      td.setAttribute("contentEditable", false);
                    }
                  });
                  td.dataset.clicked = "false";
                  resign(tr);
                }
              }
            });
          } else {
            td.dataset.action = "svg_delete";
            td.addEventListener("click", () => {
              var tbody = document.querySelector(".main_tbody");
              var tr = document.getElementById(`table_row_${td.dataset.id}`);
              console.log(td.dataset.id);
              console.log(tr);
              if (tr) {
                for (let i = 0; i <= td.dataset.id; i++) {
                  // console.log(contract_props.f_id.at(i)+" "+td.dataset.id);
                  if (contract_props.f_id.at(i) == td.dataset.id) {
                    contract_props.desc.splice(td.dataset.id, 1);
                    contract_props.Date_of_instrument.splice(td.dataset.id, 1);
                    contract_props.schema_number.splice(td.dataset.id, 1);
                    contract_props.land_number.splice(td.dataset.id, 1);
                    contract_props.owner_name.splice(td.dataset.id, 1);
                    tr.remove();
                    contract_props.f_id.pop();
                    var count = 0;
                    tbody
                      .querySelectorAll("#table_row_count")
                      .forEach((tr, index) => {
                        tr.dataset.row_no = count;
                        count++;
                      });
                    count = 0;
                  }
                }
              }

              tbody.querySelectorAll(`tr`).forEach((tr, index) => {
                if (tr) {
                  var x = contract_props.owner_name.length - 1;
                  if (x > -1) {
                    set_Counter(contract_props.owner_name.length);
                  } else {
                    set_Counter(0);
                  }
                  tr.id = `table_row_` + index;
                  tr.dataset.id = index;
                  tr.querySelectorAll(".btns")[0].dataset.id = index;
                  tr.querySelectorAll(".btns")[1].dataset.id = index;
                }
              });
            });
          }
          td.innerHTML = value;
          cells.push(td); // Push cell to the array
        } else if (key == "table_row_no") {
          td.textContent = Counter + 1;
          td.id = "table_row_count";
          contract_props.desc.push(prop.desc);
          contract_props.Date_of_instrument.push(prop.Date_of_instrument);
          contract_props.schema_number.push(prop.schema_number);
          contract_props.land_number.push(prop.land_number);
          contract_props.owner_name.push(prop.owner_name);
          var x = contract_props.owner_name.length - 1;
          contract_props.f_id.push(x);

          cells.push(td); // Push cell to the array
        } else {
          td.dataset.type = key;
          td.id = "data";
          td.textContent = value;
          data.push(value);
          cells.push(td); // Push cell to the array
        }
      }
    }
    if (stop == false) {
      // Reverse cell array order
      cells.reverse();
      // Append cells in reversed order
      for (const cell of cells) {
        tr.appendChild(cell);
      }
      main_Table.push(data.reverse());
      tbody.appendChild(tr);
      fadeOut2();
      var x = contract_props.owner_name.length - 1;
      if (x > -1) {
        set_Counter((prevCount) => prevCount + 1);
      } else {
        set_Counter(0);
      }
      setProp({
        ...prop,
        desc: "",
        Date_of_instrument: "",
        schema_number: "",
        land_number: "",
        owner_name: "",
      });
    }
    fadeOut();
  }
  function resign(tr) {
    const entries = Object.entries(contract_props);
    for (const [key, value] of entries) {
      if (key !== "f_id") {
        contract_props[key][tr.dataset.id] = tr.querySelector(
          `[data-type="${key}"]`
        ).textContent;
      }
    }
    setData((prevData) => ({
      ...prevData,
      contract_props, // Dynamic property access for any client field
    }));
  }
  async function print_contract() {
    var ref = document.querySelector(".contract_pdf");
    var iframe = document.createElement("iframe");
    iframe.classList.add("contract_pdf");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    var pri = iframe.contentWindow;

    await pri.document.open();
    await pri.document.write(
      `<style>
      * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contract_pdf,
body {
  position: relative;
  padding: 0.5rem;
  width: 8.27in;
  height: 11.45in;
  max-height: 11.45in;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  color: #000;
  font-size: 1rem;
}
.contract_pdf section,
body section {
  position: relative;
  z-index: 1;
  width: 8.27in;
  height: 11.45in;
  min-height: 11.45in;
  background: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
}
.contract_pdf section .content,
body section .content {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
}
.contract_pdf section .content p,
body section .content p {
  color: #000;
}
.contract_pdf section .content h3,
body section .content h3 {
  padding-top: 0.5rem;
}
.contract_pdf section .content h1,
.contract_pdf section .content h3,
body section .content h1,
body section .content h3 {
  color: blue;
}
.contract_pdf section .content ol,
body section .content ol {
  padding-right: 1rem;
  font-size: 0.8rem;
}
.contract_pdf section .content ul,
body section .content ul {
  padding-right: 1rem;
  list-style: disc;
  font-size: 0.8rem;
}
.contract_pdf section > .content > ul > li > div, body section > .content > ul > li > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0.5rem;
}
.contract_pdf section .content .sign,
body section .content .sign {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.5rem;
}
.contract_pdf section .content .sign div,
body section .content .sign div {
  padding: 0rem 0.5rem;
}
.contract_pdf section .content .sign .left,
body section .content .sign .left {
  width: 100%;
  display: flex;
  align-items: flex-end !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
  gap: 0.5rem;
}
.contract_pdf section .content .sign .right,
body section .content .sign .right {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
  gap: 0.5rem;
}
.contract_pdf section:nth-child(1) .content,
body section:nth-child(1) .content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}/*# sourceMappingURL=contract.css.map */
      </style>`
    );
    console.log(pri.document);
    await pri.document.write(ref.innerHTML);
    await pri.document.close();
    await pri.focus();
    await pri.print();
    iframe.remove();
  }
  var date = new DateObject({ calendar: arabic });
  var en_date = new Date();
  const day = en_date.getDate(); // Day of the month (1-31)
  const month = en_date.getMonth(); // Month (0-based index, January = 0, December = 11)
  const year = en_date.getFullYear(); // Year (full year, e.g., 2024)
  const formattedDate = `${day}/${month + 1}/${year}`;
  const [date_ar, setDate_ar] = useState(date.format());
  //?
  const [report_type, set_report_type] = useState("");
  const [waring_pireod, setwaring_pireod] = useState("");
  const [search, set_search] = useState("");

  useEffect(() => {
    if (state == false) {
      gsap.fromTo(
        RefAlert.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5 }
      );
      gsap.fromTo(
        RefForm.current,
        { opacity: 0, y: 10 },
        { y: 0, opacity: 1, duration: 0.5, delay: 0.5 }
      );
      setState(true);
    } else {
      gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
      gsap.to(RefAlert.current, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.5,
      });
    }
  }, []);
  function fadeOut2() {
    setState(true);
    gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
    gsap.to(RefAlert.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
      delay: 0.5,
    });
  }
  useEffect(() => {
    console.log(contract_props);
  }, [contract_props]);
  function formatDateToYYYYMMDDHHMMSS(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const currentDate = new Date();

  const [data, setData] = useState({
    client: {},
    contract_props: {},
    country: "المملكة العربية السعودية",
    date: formatDateToYYYYMMDDHHMMSS(currentDate),
  });
  useEffect(() => {
    console.log(data);
  }, [data]);
  const handleInputChange = (event) => {
    const { name, value } = event.target; // Destructure event object
    setData((prevData) => ({
      ...prevData,
      client: {
        ...prevData.client,
        [name]: value, // Dynamic property access for any client field
      },
    }));
  };
  const InputChange = (event) => {
    const { name, value } = event.target; // Destructure event object
    setData((prevData) => ({
      ...prevData,
      [name]: value, // Dynamic property access for any client field
    }));
  };
  async function get_offer() {
    try {
      var tl = gsap.timeline({});
      if (search !== null && search !== "") {
        tl.to(".fix", { opacity: 1, pointerEvents: "all" });
        const data = {
          id: search,
        };
        const response = await axios.post(
          "https://auctionbackend.netlify.app/.netlify/functions/server/get/offer",
          // "http://localhost:5001/get/offer",
          data
        );
        console.log(response.data);
        if (response.data.status == 200) {
          setoffer(response.data);
          setData((prevData) => ({
            ...prevData,
            offer_id: response.data.offer.offer_id,
            client_id: response.data.offer.client_id,
          }));

          fadeOut2();
        } else {
          showMessage(response.data.message, true);
        }
      } else {
        showMessage("الرجاء ادخال بيانات", true);
      }
    } catch (error) {
      showMessage("حدث خطاء", true);
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }
  useEffect(() => {
    console.log(offer);
  }, [offer]);
  const [contract_id, set_contract_id] = useState("");
  async function submit() {
    try {
      var tl = gsap.timeline({});
      tl.to(".fix", { opacity: 1, pointerEvents: "all" });

      const formData = new FormData();
      const arrayOfObjects = Array.from(files.entries()).map(
        ([key, value]) => ({
          key,
          value,
        })
      );
      for (let i = 0; i < arrayOfObjects.length; i++) {
        formData.append("file", arrayOfObjects[i].value.file);
        formData.append("des", arrayOfObjects[i].value.des);
        console.log(arrayOfObjects[i].value.des);
      }
      formData.append("data", JSON.stringify(data));
      const response = await axios.post(
        "https://auctionbackend.netlify.app/.netlify/functions/server/submit/contract",
        // "http://localhost:5001/submit/contract",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      set_contract_id(response.data.contract);
      const contract = JSON.stringify(response.data.contract);
      if (response.data.status == 200) {
        await QRCode.toCanvas(canvasRef.current, contract);
        QRCode_imgRef.current.src = canvasRef.current.toDataURL("image/png");
        gsap.to(printRef.current, {
          opacity: 1,
          pointerEvents: "all",
          duration: 0.5,
        });
        gsap.to(QRCode_imgRef.current, {
          opacity: 1,
          duration: 0.5,
        });
        tl.to(".fix", { opacity: 0, pointerEvents: "none" });
        showMessage(response.data.message, false);
      } else {
        showMessage(response.data.message, true);
      }
    } catch (error) {
      console.error(error);
      showMessage("حدث خطاء", true);
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }
  return (
    <form className="menuClose" id="main_form">
      <div className="alert" ref={RefAlert}>
        <div action="#" id="search" ref={RefForm}>
          <label htmlFor="">
            <p dir="rtl">ادخل رقم عرض السعر</p>
            <input
              type="text"
              value={search}
              onChange={(e) => set_search(e.target.value)}
            />
          </label>
          <span>
            <button
              form="fake"
              onClick={() => {
                get_offer();
              }}
            >
              بحث
            </button>
            {/* <button form="fake" onClick={(e) => fadeOut2()}>
              الغاء
            </button> */}
          </span>
        </div>
      </div>
      <div className="title">
        <h1>عقد عمل</h1>
      </div>
      <div className="navigation">
        <div
          className="part"
          ref={typeRef7}
          onClick={() => handelOnclick(typeRef7.current.dataset.type)}
        >
          بيانات اساسية
        </div>
        <div
          className="part"
          ref={typeRef1}
          onClick={() => handelOnclick(typeRef1.current.dataset.type)}
        >
          بيانات الاصل
        </div>

        <div
          className="part"
          ref={typeRef2}
          onClick={() => handelOnclick(typeRef2.current.dataset.type)}
        >
          ارفاق المستندات
        </div>
        <div
          className="part"
          ref={typeRef5}
          onClick={() => handelOnclick(typeRef5.current.dataset.type)}
        >
          الالتزامات و النسخ
        </div>
        <div
          className="part"
          ref={typeRef6}
          onClick={() => handelOnclick(typeRef6.current.dataset.type)}
        >
          فسخ العقد
        </div>

        <div
          className="part"
          ref={typeRef8}
          onClick={() => handelOnclick(typeRef8.current.dataset.type)}
        >
          اصدار العقد
        </div>
      </div>
      <article>
        <div className="sub-title">
          <h3>بيانات اساسية</h3>
        </div>
        <div className="inputs_feild">
          <div className="right grid2">
            <label htmlFor="marketing_record">
              <p>السجل التجاري</p>
              <input
                type="number"
                name="marketing_record"
                value={data.client.marketing_record || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="identity_number">
              <p>رقم الهوية</p>
              <input
                type="number"
                name="identity_number"
                value={data.client.identity_number || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="national_address">
              <p>العنوان الوطني</p>
              <input
                type="number"
                name="national_address"
                value={data.client.national_address || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="country">
              <p>الدولة</p>
              <input
                type="text"
                name="country"
                value={data.country || ""}
                onChange={InputChange}
              />
            </label>
            <label htmlFor="city">
              <p>المدينة</p>
              <input
                type="text"
                name="city"
                value={data.city || ""}
                onChange={InputChange}
              />
            </label>
          </div>
        </div>
      </article>
      <article className="prop">
        <div className="sub-title">
          <h3>بيانات الاصل</h3>
        </div>
        <div className="property_btn">
          <button form="fake" onClick={addProps}>
            <p>اضافة بيانات</p>
            <IoIosAddCircleOutline />
          </button>
        </div>
        <div className="input_prop" ref={prop_info2}>
          <div className="title">
            <h1>اضف اصل جديد</h1>
          </div>
          <div className="inputs-prop">
            <label htmlFor="desc">
              <p>وصف الصك</p>
              <input
                type="text"
                name="desc"
                placeholder="ادخل وصف الصك"
                value={prop.desc}
                onChange={handlePropChange}
              />
            </label>
            <label htmlFor="Date_of_instrument">
              <p>تاريخ الصك</p>
              <input
                type="date"
                name="Date_of_instrument"
                placeholder="ادخل تاريخ الصك "
                value={prop.Date_of_instrument}
                onChange={handlePropChange}
              />
            </label>
            <label htmlFor="land_number">
              <p>رقم القطعة</p>
              <input
                type="number"
                id="land_number"
                name="land_number"
                placeholder="ادخل رقم القطعة"
                value={prop.land_number}
                onChange={handlePropChange}
              />
            </label>
            <label htmlFor="schema_number">
              <p>رقم المخطط</p>
              <input
                type="number"
                id="schema_number"
                name="schema_number"
                placeholder="ادخل رقم المخطط"
                value={prop.schema_number}
                onChange={handlePropChange}
              />
            </label>
            <label htmlFor="owner_name">
              <p>اسم المالك</p>
              <input
                type="text"
                id="owner_name"
                name="owner_name"
                placeholder="ادخل اسم المالك"
                value={prop.owner_name}
                onChange={handlePropChange}
              />
            </label>
          </div>
          <div className="props_actions">
            <button form="fake" id="save" onClick={createTableRow}>
              اضافة
            </button>
            <button form="fake" id="cancel" onClick={fadeOut}>
              الغاء
            </button>
          </div>
        </div>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th colSpan="2">تعديل</th>
                <th>اسم المالك</th>
                <th>رقم المخطط</th>
                <th> رقم القطعة</th>
                <th>تاريخ الصك</th>
                <th>وصف الاصل</th>
                <th> الترتيب</th>
              </tr>
            </thead>
            <tbody className="main_tbody"></tbody>
          </table>
        </div>
      </article>
      <article>
        <div className="left">
          <div className="sub-title">
            <h3>ارفاق مستندات</h3>
          </div>
          <div className="files">
            <label htmlFor="add_file_btn" id="files_btn">
              <button form="fake" onClick={add_new_file}>
                <p>اضافة مستند</p>
                <AiOutlineFileAdd />
              </button>
            </label>
            <span className="label_files"></span>
          </div>
        </div>
      </article>
      <article className="responsibility">
        <div className="sub-title">
          <h3>التزامات الطرفين</h3>
        </div>
        <div className="inputs-responsibility">
          <label htmlFor="responsibility_reports">
            <p>نوع التقرير</p>
            <div className="radio">
              <label htmlFor="report_type_short">
                <p>مختصر</p>
                <input
                  type="radio"
                  name="report_type"
                  id="report_type_short"
                  value={"مختصر"}
                  onChange={(e) => {
                    InputChange(e);
                  }}
                />
              </label>
              <label htmlFor="report_type_detailed">
                <p>تفصيلي</p>
                <input
                  type="radio"
                  name="report_type"
                  id="report_type_detailed"
                  value={"تفصيلي"}
                  onChange={(e) => {
                    InputChange(e);
                  }}
                />
              </label>
            </div>
          </label>
          <label htmlFor="handover">
            <p>طريقة التسليم</p>
            <select
              dir="rtl"
              name="handover"
              value={data.handover || ""}
              onChange={(e) => {
                InputChange(e);
              }}
              id="handover"
            >
              <option value=""></option>
              <option value="ورقي">ورقي</option>
              <option value="نسخة الكترونية">نسخة الكترونية</option>
              <option value="ذاكرة خارجية">ذاكرة خارجية</option>
              <option value="رابط الكتروني">رابط الكتروني</option>
            </select>
          </label>
        </div>
        <div className="sub-title">
          <h3> النسخ </h3>
        </div>
        <div className="inputs-responsibility">
          <label htmlFor="terms_number">
            <p>عدد البنود</p>
            <input
              type="number"
              name="terms_number"
              id="terms_number"
              value={data.terms_number || ""}
              onInput={(e) => {
                InputChange(e);
              }}
              placeholder="ادخل عدد البنود"
            />
          </label>
          <label htmlFor="paper_number">
            <p>عدد الاوراق</p>
            <input
              type="number"
              name="paper_number"
              id="paper_number"
              value={data.paper_number || ""}
              onInput={(e) => {
                InputChange(e);
              }}
              placeholder="ادخل عدد الاوراق"
            />
          </label>
          <label htmlFor="copies_number">
            <p>عدد نسخ العقد الاصلية</p>
            <input
              type="number"
              name="copies_number"
              id="copies_number"
              value={data.copies_number || ""}
              onInput={(e) => {
                InputChange(e);
              }}
              placeholder="ادخل عدد الاوراق"
            />
          </label>
        </div>
      </article>
      <article className="contract">
        <div className="sub-title">
          <h3>فسخ و انهاء العقد</h3>
        </div>
        <div className="inputs-contract">
          <label
            htmlFor="period_days"
            title="إذا لم يـِفِ أحـد الطـرفني بـأي مـن الالتزامـات الواجبـة عليـه، جـاز للطـرف الآخـر -بعـد إعـذاره كتابـة ومنحـه مـدة
قدرهــا [تحــدد الـ مدة بالأيــام] يومــًاً مــن تاريــخ الإعــذار لاســتدراك الخلــل أو التقــصير الصــادر منــه"
          >
            <p>تحديد المدة الاعذاز بالايام</p>
            <input
              type="number"
              name="period_days"
              value={data.period_days || ""}
              onInput={(e) => {
                InputChange(e);
              }}
              id="period_days"
            />
          </label>
          <label htmlFor="conflict">
            <p>الجهة المختصة في النزاعات</p>
            <select
              dir="rtl"
              name="conflict"
              value={data.conflict || ""}
              onChange={(e) => {
                InputChange(e);
              }}
              id="conflict"
            >
              <option value=""></option>
              <option value="القضاء">القضاء</option>
              <option value="التحكيم">التحكيم</option>
            </select>
          </label>
          <label
            htmlFor="conflict_days"
            title="اتفـق الطرفـان على أن أي خلاف أو نـزاع قـد ينشـأ بينـهما فـيما يتعلـق بتفـسير أو تنفيـذ بنـود هـذا العقـد أو مـا يتصـل بـه
فإنـه يتـم حـل ذلـك الـخلاف وديـًّاً خلال مـدة أقصاهـا [حـدد الـ مدة بالأيـام]  يومآ مـن تاريـخ نشـوء الـخلاف"
          >
            <p>فترة حل الخلاف وديآ بالايام</p>
            <input
              type="number"
              name="conflict_days"
              id="conflict_days"
              value={data.conflict_days || ""}
              onInput={(e) => {
                InputChange(e);
              }}
              placeholder="ادخل الفترة بالايام"
            />
          </label>
          <label htmlFor="conflict_solver">
            <p>تحديد الجهة الختصة بالبت في النزاع</p>
            <input
              type="text"
              name="conflict_solver"
              id="conflict_solver"
              value={data.conflict_solver || ""}
              onInput={(e) => {
                InputChange(e);
              }}
              placeholder="حدد الجهة المختصة"
            />
          </label>
          <label htmlFor="curt_specialization">
            <p>تحديد اختصاص الجهة القضائية</p>
            <input
              type="text"
              name="curt_specialization"
              id="curt_specialization"
              value={data.curt_specialization || ""}
              onInput={(e) => {
                InputChange(e);
              }}
              placeholder="حدد اختصاص الجهة"
            />
          </label>
        </div>
      </article>
      <article className="contract">
        <div className="sub-title">
          <h3 dir="rtl"> اصدار عقد </h3>
          <button
            onClick={print_contract}
            ref={printRef}
            id="printBtn"
            form="fake"
          >
            طباعة
          </button>
          <button
            onClick={() => {
              submit();
            }}
            form="fake"
          >
            حفظ العقد
          </button>
        </div>
        <div className="contract_pdf">
          <section>
            <div className="content">
              <p dir="rtl">عقد ( تقديم خدمات التقييم )</p>
              <p dir="rtl">اسم المشروع (........................)</p>
              <p dir="rtl">رقم العقد ( {contract_id} )</p>
              <p dir="rtl">
                تاريخ توقيع العقد: {date_ar}/ {data.city}
              </p>
            </div>
            <img src="" alt="" ref={QRCode_imgRef} />
            <canvas ref={canvasRef}></canvas>
          </section>
          <section>
            <div className="content">
              <h1>نموذج العقد الموحد لتقديم خدمات التقييم</h1>
              <h3 dir="rtl">مقدمة العقد</h3>
              <p dir="rtl">
                بعون الله وتوفيقه حرر هذا العقد في مدينة ({data.city})بــ (
                {data.country}) في يوم () وتاريخ {date_ar} هـ الموافق
                {formattedDate} بين كل من:
              </p>
              <h3 dir="rtl">أطراف العقد</h3>
              <p dir="rtl">
                أولاً: المقيــم المعتمد / شركة تثمينات الخليج وشريكه للتقييم
                العقاري، سجل تجاري ................. ترخيص رقم ...............
                وتاريخ .................... في فرع العقار, العنوان الوطني الرياض
                – حي الملك فهد – شارع العليا العام – مركز رياض العليا هاتف
                0552211439 بريد إلكتروني ped@tathminat.com ويمثله في التوقيع على
                هذا العقد عبدالرحمن الخضير الرئيس التنفيذي . ويشار إليه فيما بعد
                بالطرف الأول أو المقيّم المعتمد
              </p>
              <p dir="rtl">
                ثانياً: العميل {offer.client.name} هوية رقم{" "}
                {data.client.identity_number}
                سجل تجاري {data.client.marketing_record} العنوان الوطني{" "}
                {data.client.national_address} هاتف {offer.client.phone} بريد
                إلكتروني {offer.client.email} ويمثل في التوقيع على هذا
                العقد......................................... ويشار إليه فيما
                بعد بالطرف الثاني أو العميل.
              </p>
              <h3 dir="rtl">تمهدي</h3>
              <p dir="rtl">
                لما كان الطرف الأول حاصلاً على ترخيص مزاولة مهنة التقييم وفقاً
                لأحكام نظام المقيمين المعتمدين الصادر بالمرسوم الملكي رقم (م/43)
                وتاريخ 9/7/1433هـ وتعديلاته، ولحاجة الطرف الثاني لتقييم أصوله من
                قبل مقيم معتمد لغرض {offer.Purpose} , عليه فقد التقت إرادتا
                الطرفين وكل منهما بالحالة المعتبرة شرعاً والأهلية الصالحة
                للإبرام والتصرف والصفة المعتد بها نظاماً على إبرام هذا العقد
                وذلك بالشروط الأتية:
              </p>
              <h3 dir="rtl">البند الأول: صفة التمهيد:</h3>
              <p dir="rtl">
                يعتبر التمهيد السابق جزءاً لا يتجزأ من هذا العقد والتي تتضمن
                كافة ما اتفق عليه الطرفان من شروط وأحكام ولا يجوز التعديل أو
                التغيير فيها إلا بموافقة الطرفين الكتابية المسبقة ويلتزم كل طرف
                باحترام بنود العقد والتقيد بها.
              </p>
              <h3 dir="rtl"> البند الثاني: مصطلحات العقد:</h3>
              <ol dir="rtl">
                <li dir="rtl">
                  يقصد بالألفاظ والمصطلحات الآتية المعاني الموضحة أمام كل منها
                  ومالم يقتض السياق خلاف ذلك:
                </li>
                <li dir="rtl">النظام: نظام المقيمين المعتمدين.</li>
                <li dir="rtl">اللائحة: اللائحة التنفيذية للنظام.</li>
                <li dir="rtl">القواعد: قواعد سلوك مهنة التقييم وآدابها</li>
                <li dir="rtl">الهيئة: الهيئة السعودية للمقيمين المعتمدين.</li>
                <li dir="rtl">
                  الأحكام والقواعد المنظمة لمهنة التقييم: تشمل أحكام النظام،
                  واللائحة، والقواعد، والمعايير، والدليل، والقرارات، والتعليمات
                  الصادرة عن الهيئة المتعلقة بالمهمة.
                </li>
                <li dir="rtl">
                  الأنظمة الإلكترونية: الأنظمة أو الوسائل الإلكترونية التي
                  تعتمدها الهيئة لتنظيم مزاولة المهنة.
                </li>
                <li dir="rtl">
                  المقيم المعتمد: الشخص ذو الصفة الطبيعية أو الاعتبارية المرخص
                  له بمزاولة المهنة وفقاً للنظام.
                </li>
                <li dir="rtl">
                  منشأة التقييم: المنشأة الفردية أو الشركة المهنية المستوفية
                  للاشتراطات النظامية المتعلقة بممارسة مهنة التقييم.
                </li>
                <li dir="rtl">
                  تقرير التقييم: الوثيقة التي يصدرها المقيم المعتمد لعملائه،
                  متضمنة نتيجة التقييم، ومستوفية لالتزامات المقيم المعتمد
                  المبينة في النظام واللائحة والدليل، ومتوافقة مع معايير التقييم
                  المعتمدة.
                </li>
                <li dir="rtl">
                  العميل: الشخص ذو الصفة الطبيعية أو الاعتبارية الذي يكلف المقيم
                  بأداء مهمة التقييم.
                </li>
                <li dir="rtl">
                  العقد: العقد الموحد المعتمد من قبل الهيئة لتقديم خدمات
                  التقييم.
                </li>
              </ol>
              <h3 dir="rtl">البند الثالث: وثائق العقد:</h3>
              <p dir="rtl">1/3 يتكون العقد من الوثائق التالية:</p>
              <ul dir="rtl">
                <li dir="rtl">وثيقة العقد الأساسية.</li>
                <li dir="rtl"> ترخيص مزاولة المهنة للطرف الأول.</li>
                <li dir="rtl">صورة الصك.</li>
                <li dir="rtl">صورة رخصة البناء.</li>
              </ul>
            </div>
          </section>
          <section>
            <div className="content">
              <p dir="rtl">
                2/3 تشكل هذه الوثائق وحدة متكاملة وتعد كل وثيقة فيها جزءاً لا
                يتجزأ من العقد بحيث تفسر الوثائق المذكورة أعلاه ويتمم بعضها بعضا
                وفي حال وجود تعارض بين أحكام وثائق العقد، فإن الوثيقة المتقدمة
                تسود على الوثيقة التي تليها في الترتيب الوارد في الفقرة أو من
                هذا البند.
              </p>
              <p dir="rtl">
                3/3 تعد أي تعديلات لاحقة على هذه الوثائق بعد توقيع هذا العقد
                نافذة باتفاق الطرفين وتوقيعهما عليها، وتكون بهذه الصفة جزءاً لا
                يتجزأ من هذا العقد، وفي حال وجد أي تعارض فالعبرة بالأحدث
                تاريخاً.
              </p>
              <p dir="rtl">
                4/3 في حال وجود تعارض بين أحكام ونصوص العقد وبين أحكام نظام
                المقيمين المعتمدين، تكون أحكام النظام ولائحته وقواعده هي الواجب
                تطبيقها
              </p>
              <h3 dir="rtl">البند الرابع: الغرض من التعاقد:</h3>
              <h4 dir="rtl">-- {offer.Purpose}</h4>
              <h3 dir="rtl">البند الخامس: الأصل /ول محل التقييم:</h3>
              <p dir="rtl">
                .............................................................................................................
              </p>
              <h3 dir="rtl">البند السادس: مدة العقد: </h3>
              <p dir="rtl">
                يلتزم الطرف الأول بتنفيذ وإتمام جميع الخدمات المنوط بها بموجب
                هذا العقد وملحقاته وذلك خلال مدة ({"offer.contract_period"})
                ابتداء من تاريخ توقيع العقد وتنتهي هذه الاتفاقية في تاريخ{" "}
                {"offer.value_expiration_date"}م
              </p>
              <h3 dir="rtl">البند السابع: قيمة العقد وصرف المقابل المالي:</h3>
              <p dir="rtl">
                1/7 القيمة الإجمالية للعقد هي مبلغ قدره (
                {"offer.value_in_numbers"}) {"offer.value_in_letters"} ريال
                سعودي فقط، تشمل جميع التكاليف اللازمة لتنفيذ العقد وتشمل كذلك
                كافة الرسوم والضرائب، بما في ذلك ضريبة القيمة المضافة.
              </p>
              <p dir="rtl">2/7 تصرف مستحقات المتعاقد وفقاً للإجراءات الآتية:</p>
              <ul dir="rtl">{""}</ul>
              <p dir="rtl">
                3/7 لا يتم سداد المتبقي من المستحقات – بما لا يقل عن 30% من
                القيمة الإجمالية للعقد – إلا بعد قيام الطرف الأول بإيداع التقرير
                محل العقد في الأنظمة الإلكترونية المعتمدة لدى الهيئة ووضع رمز
                الإيداع عليها وتسليمها للطرف الثاني.
              </p>
              <h3 dir="rtl">البند الثامن: التزامات الطرفين:</h3>
              <p dir="rtl"> يلتزم الطرف الأول بما يلي:</p>
              <ol dir="rtl">
                <li dir="rtl">
                  إعداد التقرير وفقاً للأحكام والقواعد المنظمة لمهنة التقييم.
                </li>
                <li dir="rtl">
                  إبلاغ الطرف الثاني بأي ظرف قد يطرأ أثناء تنفيذ العقد ويكون
                  مؤثراً على نطاق العمل محل العقد.
                </li>
                <li dir="rtl">
                  إيداع التقرير محل العقد في حساب المنشأة ووضع رمز الإيداع عليه
                  قبل تسليمها للطرف الثاني.
                </li>
                <li dir="rtl">تسليم التقرير أو التقارير باللغة (العربية).</li>
                <li dir="rtl">
                  إعداد التقرير خلال المدة المتفق عليها في العقد
                </li>
                <li dir="rtl">إعداد التقرير محل العقد ({report_type}).</li>
                <li dir="rtl">
                  عد تقييم الأصل لأكثر من عميل في الموضوع ذاته إلا بعد أخذ
                  الموافقة المكتوبة من جميع العملاء.
                </li>
                <li dir="rtl">
                  أخذ موافقة الطرف الثاني حال استعانته بأطراف خارجية في إعداد
                  التقرير محل العقد.
                </li>
                <li dir="rtl">
                  تسليم التقرير محل العقد (نسخة إلكترونية) على البريد الإلكتروني
                </li>
                <li dir="rtl">
                  إبلاغ الهيئة بأي مخالفة للأحكام والقواعد المنظمة لمهنة
                  التقييم، أو في حال طلب من المقيم المعتمد أو أي من العاملين
                  لديه – عند ممارسته للمهنة – التلاعب أو الاحتيال أو التأثير
                  بطريقة غير مشروعة في تقرير التقييم، سواء تم ذلك من قبل العميل
                  مباشرة أو من قبل طرف آخر، ويمكن تقديم البلاغ على بريد الهيئة
                  الرسمي التالي: (CD@taqeem.gov.sa).
                </li>
              </ol>
            </div>
          </section>
          <section>
            <div className="content">
              <p dir="rtl"> يلتزم الطرف الثاني بما يلي:</p>
              <ul dir="rtl">
                <li dir="rtl">تزويد الطرف الأول بالبيانات والوثائق التالية:</li>
                <ul dir="rtl">{documents_}</ul>
                <li dir="rtl">
                  عدم إخفاء أي بيانات من شأنها التأثير على محل العقد والإفصاح
                  للطرف الأول عن أي معلومة قد تؤثر في محل العقد أثناء سريانه.
                </li>
                <li dir="rtl">
                  عدم استخدام التقرير محل العقد في غير الغرض المحدد له.
                </li>
                <li dir="rtl">
                  عدم تقديم التقرير لغير المستخدمين المحددين في التقرير محل
                  العقد
                </li>
                <li dir="rtl">
                  تمكين الطرف الأول أو من يعملون معه – من أعضاء الهيئة – من
                  معاينة الأصل محل التقييم.
                </li>
                <li dir="rtl">
                  عدم التدخل أو التأثير – بشكل مباشر أو غير مباشر – على
                  استقلالية الطرف الأول وتابعيه في تنفيذ الأعمال محل العقد.
                </li>
                <li dir="rtl">
                  إبلاغ الهيئة بأي مخالفة للأحكام والقواعد المنظمة لمهنة التقييم
                  يرتكبها المقيم المعتمد أو المشاركين معه في إعداد التقرير،
                  ويمكن تقديم البلاغ على بريد الهيئة الرسمي التالي:
                  (CD@taqeem.gov.sa).
                </li>
              </ul>
              <h3 dir="rtl">البند التاسع: فسخ وإنهاء العقد: </h3>
              <p dir="rtl">
                إذا لم يفي أحد الطرفين بأي من الالتزامات الواجبة عليه، جاز للطرف
                الآخر – بعد إعذاره كتابة ومنحه مدة قدرها ({waring_pireod}) يوماً
                من تاريخ الإعذار لاستدراك الخلل أو التقصير الصادر منه، فسخ العقد
                دون اللجوء إلى القضاء مع بقاء حقه في التعويض عما لحقه من أضرار
                فعلية.
              </p>
              <p dir="rtl">
                يحق للطرفين الاتفاق كتابة على إنهاء هذا العقد، مع مراعاة
                استمرارهما في تنفيذ التزاماتهما وفق ما هو منصوص عليه في هذا
                العقد حتى تاريخ الإنهاء، ومع مراعاة تسوية أي مستحقات مالية أو
                تعاقدية غير مستوفاة للطرفين
              </p>
              <h3 dir="rtl">
                البند العاشر: النظام الواجب التطبيق والاختصاص القضائي:
              </h3>
              <p dir="rtl">
                اتفق الطرفان على أن أي خلاف أو نزاع قد ينشأ بينهما فيما يتعلق
                بتفسير أو تنفيذ بنود هذا العقد أو ما يتصل به فإنه يتم حل ذلك
                الخلاف ودياً خلال مدة أقصاها (ثلاثون يوماً) من تاريخ نشوء
                الخلاف، وإذا تعذر ذلك فيتم إحالة الخلال أو النزاع إلى الجهات
                القضائية المختصة وفقاً للأنظمة والأحكام المعمول بها في المملكة،
                وفي جميع الأحوال فإن الخلاف أو النزاع لا يحول دون التزام الطرفين
                بالاستمرار في تنفيذ هذا العقد.
              </p>
              <h3 dir="rtl">البند الحادي عشر: المراسلات:</h3>
              <p dir="rtl">
                اتفق الطرفان على أن أي مراسلات أو اتصال يخص هذا العقد يجب أن
                يكون كتابياً وباللغة (العربية أو الإنجليزية)، كما يجب أن يستلم
                شخصياً أو يرسل بالبريد المسجل، أو البريد الإلكتروني وفق العناوين
                الموضحة في صدر هذا العقد، وتعتبر المراسلات مستلمة من قبل الطرف
                الآخر وفقاً لهذا البند.
              </p>
              <h3 dir="rtl">البند الثاني عشر: القوة القاهرة:</h3>
              <p dir="rtl">
                إذا استحال على أي من الطرفين تنفيذ التزاماته بسبب ظروف قاهرة –
                وبدون أي تقصير أو إهمال من جانبه – وكان مثل هذا الظرف غير متوقع
                عند توقيع العقد، فإن على الطرفين السعي ودياً للتسوية والموازنة
                بين حقوقهما، وإذا تعذر ذلك فيتم إعمال ما ورد في البند (العاشر)
                من هذا العقد.
              </p>
              <h3 dir="rtl">البند الثالث عشر: السرية:</h3>
              <p dir="rtl">
                يلتزم الطرف الأول بالحفاظ على سرية جميع المعلومات المتعلقة
                بعملية التقييم وألا يطلع أحداً عليها طيلة فترة التعاقد وبعد
                انقضائه لأي سبب كان، واستثناءً من ذلك يجوز له الإفصاح عنها وفقاً
                للأحكام المنصوص عليها في المادة (الثامنة عشرة) من النظام.
              </p>
            </div>
          </section>
          <section>
            <div className="content">
              <h3 dir="rtl">البند الرابع عشر: أحكام عامة:</h3>
              <ol dir="rtl">
                <li dir="rtl">
                  يسأل المقيم المعتمد عن تعويض الضرر الذي يصيب العميل أو الغير
                  بسبب الأخطاء الواقعة منه في أداء عمله وفقاً للأحكام النظامية
                  المنصوص عليها ضمن المادتين (التاسعة عشرة) و (الثامنة
                  والثلاثين) من النظام
                </li>
                <li dir="rtl">
                  على الطرفين تنفيذ الالتزامات الواردة بهذا العقد وفقاً لما تقضي
                  به الأنظمة واللوائح التي تحكم أعمال الطرف الأول وبما لا يتعارض
                  مع الأنظمة العامة والقرارات واللوائح النافذة في المملكة
                  العربية السعودية في مالم يرد فيه نص بهذا العقد.
                </li>
                <li dir="rtl">
                  لا يترتب على الطرف الثاني أي مسؤولية أو مسائلات أو مطالبات
                  مالية يطالب بها أي طرف ثالث تعاقد معه الطرف الأول للقيام
                  بتنفيذ البنود المنصوص عليها في هذا العقد، ويستثنى من ذلك ما تم
                  الاتفاق عليه كتابة بين طرفي العقد.
                </li>
                <li dir="rtl">
                  يتحمل الطرف لثاني المسؤوليات المترتبة على حقوق الملكية الفكرية
                  لما يشارك به من مواد.
                </li>
                <li dir="rtl">
                  لا يحق للطرف الأول أن يتعاقد من الباطن أو يتنازل لغيره عن أي
                  جزء من هذا العقد دون الحصول على موافقة كتابية مسبقة من الطرف
                  الثاني، وفي جميع الأحوال يبقى الطرف الأول مسؤولاً أمام الطرف
                  الثاني والغير بطريق التضامن مع المتنازل إليه عن تنفيذ هذا
                  العقد.
                </li>
                <li dir="rtl">
                  إذا نال أي بند أو شرط وارد في هذا العقد حكماً بعدم صحته أو
                  نظاميته أو تعذر تنفيذه بسبب حكم قضائي قطعي أو أي متطلب نظامي
                  أو تعليمات صادرة عن الهيئة، فإن جميع الشروط والأحكام الأخرى
                  الواردة بهذا العقد تبقى رغم ذلك سارية ونافذه.
                </li>
                <li dir="rtl">
                  يقر الأطراف باطلاعهم على جميع بنود هذا العقد وشروطه، وفهمهم
                  لها فهماً تاماً نافياً للنزاع والجهالة.
                </li>
                <li dir="rtl">
                  للطرفين الاتفاق على تحرير العقد بلغة أخرى إضافة إلى اللغة
                  العربية، وفي حال الخلاف على تفسير بنود العقد بتم الاعتماد على
                  اللغة العربية في تفسير بنود العقد.
                </li>
              </ol>
              <h3 dir="rtl">البند الخامس عشر: النسخ:</h3>
              <p dir="rtl">
                حرر هذا العقد باللغة العربية من تمهيد وأربعة عشر بنداً في 6
                أوراق، وأفرغ في نسختين، وقد تسلم كل طرف نسخته للعمل بموجبها،
                ولما ذكر حرر وعليه جرى التوقيع إقراراً بصحته وإنفاذاً لمضمونه
                والتزاماً بأحكامه.
              </p>
              <div className="sign">
                <div className="left">
                  <h3 dir="rtl">الطرف الثاني</h3>
                  <p dir="rtl">العميل / {client_info.name} </p>
                  <p dir="rtl">التوقيع /</p>
                  <p dir="rtl">
                    التاريخ / {formattedDate} م / الموافق / {date_ar} هـ
                  </p>
                </div>
                <div className="right">
                  <h3 dir="rtl">الطرف الأول</h3>
                  <p dir="rtl">
                    المقيم المعتمد / شركة تثمينات الخليج وشريكه للتقييم العقاري
                  </p>
                  <p dir="rtl">التوقيع / </p>
                  <p dir="rtl">
                    التاريخ / {formattedDate} م / الموافق / {date_ar} هـ
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}

export default Contract;
