// import {Link} from 'react-router-dom';
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "../../style/Prices.css";
import { useSearchParams } from "react-router-dom";

import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import { useParams } from "react-router-dom";
import localforage from "localforage";

function Data() {
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  const [search, setsearch] = useSearchParams({ data: true });
  useEffect(() => {
    setsearch(
      (prev) => {
        prev.set("data", true);
        return prev;
      },
      { replace: true }
    );
  }, []);
  useLayoutEffect(() => {
    var count = 1;
    document.querySelectorAll(".part").forEach((part) => {
      part.dataset.type = `s-` + count + `-`;
      count++;
    });
    var count_article = 1;
    document.querySelectorAll("article").forEach((article) => {
      if (count_article === 1) {
        article.dataset.active = true;
      } else {
        article.dataset.active = false;
      }
      article.id = `s-` + count_article;
      count_article++;
    });
  }, []);
  function calc(inputElement) {
    var total = 0;

    // 1. Get the containing element (assuming the calc function is called from the input change event)
    const calcContainer = inputElement.closest(".calc");

    // 2. Find other relevant input elements within the same container
    const input1 = calcContainer.querySelector(".i1");
    const input2 = calcContainer.querySelector(".i2");
    const input3 = calcContainer.querySelector(".i3");

    // 3. Extract values from input elements
    const value1 = parseFloat(input1.value) || 0; // Handle empty values
    const value2 = parseFloat(input2.value) || 0; // Handle empty values

    // 4. Perform calculation based on input element class (assuming i1 for area, i2 for price)
    let result = 0;
    if (inputElement.classList.contains("i1")) {
      result = value2 * value1; // Price per meter * Area
    } else if (inputElement.classList.contains("i2")) {
      result = value1 * value2; // Area / Price per meter
    }
    // 5. Update the "مجموع" (total) input element
    input3.value = result.toFixed(0); // Set value with 2 decimal places
    document.querySelectorAll(".i3").forEach((input) => {
      total += Number(input.value);
    });
    document.querySelector("#total").value = total;
  }
  return (
    <form className="menuClose" id="main_form">
      <div className="title">
        <h1>ادخال البيانات</h1>
      </div>
      <article data-active="true">
        <div className="inputs_feild">
          <div className="right max">
            <div className="sub-title" dir="rtl">
              <h3>البيانات الأساسية</h3>
            </div>
            <label htmlFor="agent_name">
              <p>العميل</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>نوع النموزج</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>الغرض من التقيم</p>
              <input type="text" />
            </label>
            <label htmlFor="company_email">
              <p>أساس القيمة</p>
              <input dir="rtl" type="gmail" />
            </label>
            <label htmlFor="company_phone">
              <p>المالك</p>
              <input dir="rtl" type="text" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>جوال المالك </p>
              <input dir="rtl" type="tel" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>رقم الهوية</p>
              <input dir="rtl" type="tel" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>طالب التقيم</p>
              <input dir="rtl" type="text" />
            </label>
            <label htmlFor="company_phone">
              <p>جوال طالب التقيم</p>
              <input dir="rtl" type="tel" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>إسم مستخدم التقرير</p>
              <input dir="rtl" type="tel" pattern="[0-9]" />
            </label>
            <label htmlFor="value_type">
              <p>تاريخ التكليف</p>
              <input dir="rtl" type="date" name="" id="" />
            </label>
            <label htmlFor="value_type">
              <p>تاريخ التسليم</p>
              <input dir="rtl" type="date" name="" id="" />
            </label>
            <label htmlFor="value_type">
              <p>رقم التكليف</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="value_type">
              <p>رقم التكليف الثاني</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="value_type">
              <p>رقم المخطط</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>عمولة المدخل ألارض</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>إسم المخطط</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>رقم الوحدة</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>رقم الوحدة</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>تاريخه</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>رقم القطعة</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>رقم الصك</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>تاريخ الصك</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>مكان اصدار الصك</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>رقم فسح البناء</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>تاريخ فسح البناء</p>
              <input dir="rtl" type="date" name="" id="" />
            </label>
            <label htmlFor="">
              <p>المثمن</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>المنطقة</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>المدينة</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>الحي</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>رقم البلوك</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>محضر التجزئة</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <div className="right bottom">
              <label htmlFor="type" id="checkbox-label">
                <p>تبديل لرقم العقار</p>
                <input dir="rtl" type="checkbox" />
              </label>
            </div>
            <div className="right bottom">
              <div className="sub-title" dir="rtl">
                <h3>الحدود و الاطوال</h3>
              </div>
              <div className="right sides">
                <label htmlFor="#">
                  <p dir="rtl">الحد الشمالي</p>
                  <span>
                    <input type="text" placeholder="وصف" />
                    <input type="number" placeholder="بطول" />
                  </span>
                </label>
                <label htmlFor="#">
                  <p dir="rtl">الحد الجنوبي</p>
                  <span>
                    <input type="text" placeholder="وصف" />
                    <input type="number" placeholder="بطول" />
                  </span>
                </label>
                <label htmlFor="#">
                  <p dir="rtl">الحد الشرقي</p>
                  <span>
                    <input type="text" placeholder="وصف" />
                    <input type="number" placeholder="بطول" />
                  </span>
                </label>
                <label htmlFor="#">
                  <p dir="rtl">الحد الغربي</p>
                  <span>
                    <input type="text" placeholder="وصف" />
                    <input type="number" placeholder="بطول" />
                  </span>
                </label>
              </div>
            </div>
            <label htmlFor="">
              <p>رقم الطلب</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>مصدر فسح البناء</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوع الطلب</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>تصنيف البنك الأهلي</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>الحالة للبنك العربي</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>إسم المقاول</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوع الرخصة</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>رقم العقد</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوع العقار</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>رقم عرض السعر</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <div className="right bottom">
              <label htmlFor="type" id="checkbox-label">
                <p>هل يحتوي علي تقرير هندسي؟ </p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>هل المعاملة عاجلة؟</p>
                <input dir="rtl" type="checkbox" />
              </label>
            </div>
          </div>
        </div>
      </article>
      <article>
        <div className="inputs_feild">
          <div className="right max">
            <div className="right bottom">
              <div className="sub-title" dir="rtl">
                <h3>بيانات العقار</h3>
              </div>
              <label htmlFor="agent_name">
                <p>تصنيف العقار</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>اقرب شارع</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>علي بعد</p>
                <input type="text" />
              </label>
            </div>
            <div className="right bottom grid">
              <div className="sub-title" dir="rtl">
                <h3>المحيط</h3>
              </div>
              <label htmlFor="type" id="checkbox-label2">
                <p>تحديد الكل </p>
                <input
                  dir="rtl"
                  type="checkbox"
                  onClick={() => {
                    document.querySelectorAll(".checkbox").forEach((choise) => {
                      choise.click();
                    });
                  }}
                />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>جامع </p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مرفق طبي </p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مرفق أمني </p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>سوق تجاري </p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>حديقة </p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>محطات وقود </p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>شقق مفروشة </p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>فنادق</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مطاعم</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>أسواق عامة</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>أسواق مركزية</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مراكز طبية</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مراكز تجارية </p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مستوصفات</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مستشفيات</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>دفاع مدني</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>بنوك</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مدارس</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>مركز شرطة</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>دوائر حكومية</p>
                <input dir="rtl" className="checkbox" type="checkbox" />
              </label>
            </div>
            <div className="right bottom">
              <div className="sub-title" dir="rtl">
                <h3>
                  المسح الشامل على منطقة العقار وتحلیل العوامل المھمة والمؤثرة
                  في قیمة العقار
                </h3>
              </div>
              <label htmlFor="agent_name">
                <p>شكل الأرض</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>موقع العقار بالنسبة للمدینة</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>موقع العقار بالنسبة للمخطط</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>نظام الأدوار المسموح به</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>لاستخدام الحالي للعقار</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>الاستخدام الامثل للعقار</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>سعر (المتر) للأستخدام التجاري</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>سعر (المتر) للأستخدام السكني</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>نسبة تغطية الموقع</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>عدد الطوابق المسموحة</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>نسبة البناء المسموحة</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>حالة الاشغال</p>
                <input type="text" />
              </label>
            </div>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>هل العقار في الطبيعة مطابق لرخصة البناء؟</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم </p>
              <input dir="rtl" type="radio" name="y6" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="y6" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>بدون</p>
              <input dir="rtl" type="radio" name="y6" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>المنسوب</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>مستوي </p>
              <input dir="rtl" type="radio" name="y" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مرتفع </p>
              <input dir="rtl" type="radio" name="y" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>منخفض</p>
              <input dir="rtl" type="radio" name="y" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3> أطلاق التيار</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم </p>
              <input dir="rtl" type="radio" name="y5" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="y5" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>الخدمات المتوفرة</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>مياه </p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>هاتف</p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مضاءة </p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>كهرباء </p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>صرف صحي </p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>شبكة تصريف سيول</p>
              <input dir="rtl" type="checkbox" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>الشوارع</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>مسفلتة </p>
              <input dir="rtl" type="radio" name="y1" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>غير مسفلتة </p>
              <input dir="rtl" type="radio" name="y1" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مضاءة </p>
              <input dir="rtl" type="radio" name="y2" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>غير مضاءة</p>
              <input dir="rtl" type="radio" name="y2" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مشجرة </p>
              <input dir="rtl" type="radio" name="y3" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>غير مشجرة</p>
              <input dir="rtl" type="radio" name="y3" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مرصفة </p>
              <input dir="rtl" type="radio" name="y4" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>غير مرصفة</p>
              <input dir="rtl" type="radio" name="y4" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>الموقع العام</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>داخل النطاق</p>
              <input dir="rtl" type="radio" name="x" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>المرحلة الأولى</p>
              <input dir="rtl" type="radio" name="x" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>المرحلة الثانية</p>
              <input dir="rtl" type="radio" name="x" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>خارج النطاق</p>
              <input dir="rtl" type="radio" name="x" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>اسلوب طريقة التقيم</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>اسلوب السوق</p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>اسلوب الدخل</p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>اسلوب التكلفة</p>
              <input dir="rtl" type="checkbox" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>كيف وصلت للقيمة السوقية العادلة؟</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>المبيعات المنافسة او المقارنة </p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>العائد الاستثماري</p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>التكلفة</p>
              <input dir="rtl" type="checkbox" />
            </label>
          </div>
        </div>
      </article>
      <article>
        <div className="inputs_feild">
          <div className="right max">
            <div className="sub-title">
              <h3> تفاصيل العقار</h3>
            </div>
            <label htmlFor="">
              <p>حالة المبني</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوع المبني</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>عمر العقار</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>العمر الأفتراضي المتبقي للعقار</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <div className="right inner">
              <div className="sub-title">
                <h3>التصميم المعماري</h3>
              </div>
              <label htmlFor="type" id="checkbox-label2">
                <p>جيد </p>
                <input dir="rtl" type="radio" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>ردئ </p>
                <input dir="rtl" type="radio" />
              </label>
              <label htmlFor="type" id="checkbox-label2">
                <p>بدون</p>
                <input dir="rtl" type="radio" />
              </label>
            </div>
            <label htmlFor="">
              <p>الواجهة الشمالية</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>الواجهة الجنوبية </p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>الواجهة الشرقية</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>الواجهة الغربية</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>ارضية الاحواش</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>ارضية الاستقبال</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>ارضية المدخل</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>ارضية الغرف</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>الابواب الدخلية</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>الابواب الخارجية</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوع التشطيب</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>الملكية التي يتم تقيمها؟</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوعية أرضيات الساحات الخارجية</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>مكونات الجدران الخارجية</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>إرضية المجالس</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>صالات الطعام</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوعية الشبابيك</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوعية التسليك والسباكة</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>نوعية الدهان الداخلي</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>أعمال الجبس</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>جودة عمال الجبس</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>مميزات العقار</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>تحديد الكل </p>
              <input
                dir="rtl"
                type="checkbox"
                onClick={() => {
                  document.querySelectorAll(".checkbox2").forEach((choise) => {
                    choise.click();
                  });
                }}
              />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>حائط مزدوج </p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> زجاج مزدوج </p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> إضاءة مخفية </p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> سلالم</p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> كراج كهربائي</p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> كراج عادي</p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> بوابات </p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مصاعد </p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> سخانات</p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> حمام عربي</p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p> حمام أفرنجي</p>
              <input dir="rtl" className="checkbox2" type="checkbox" />
            </label>
          </div>
          <div className="right max">
            <label htmlFor="">
              <p>عدد عدادات الكهرباء</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>ارقام عدادت الكهرباء</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>عدد عدادت المياه</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>ارقام عدادت المياه</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
            <label htmlFor="">
              <p>عدد الأدوار</p>
              <input dir="rtl" type="text" name="" id="" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>نوع التكيف</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>مركزي</p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>صحراوي</p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>شباك </p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>تكييف منفصل</p>
              <input dir="rtl" type="checkbox" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>توصيلات فقط</p>
              <input dir="rtl" type="checkbox" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title">
              <h3>التركيب</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>مركب</p>
              <input dir="rtl" type="radio" name="applied" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>غير مركب</p>
              <input dir="rtl" type="radio" name="applied" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>الهيكل الإنشائي</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>خرساني</p>
              <input dir="rtl" type="radio" name="structre" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مباني معدنية</p>
              <input dir="rtl" type="radio" name="structre" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مباني خشبية </p>
              <input dir="rtl" type="radio" name="structre" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>حوائط حاملة</p>
              <input dir="rtl" type="radio" name="structre" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>خرساني وكمرات حديدية</p>
              <input dir="rtl" type="radio" name="structre" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>خرساني + حوائط حاملة</p>
              <input dir="rtl" type="radio" name="structre" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>خرساني + معدنية</p>
              <input dir="rtl" type="radio" name="structre" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>بدون</p>
              <input dir="rtl" type="radio" name="structre" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>نوع الاسقف</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>خرساني</p>
              <input dir="rtl" type="radio" name="foor" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>كمرات حديديه </p>
              <input dir="rtl" type="radio" name="foor" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>كمرات خشبية</p>
              <input dir="rtl" type="radio" name="foor" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>خرساني وكمرات حديدية</p>
              <input dir="rtl" type="radio" name="foor" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مباني معدنية</p>
              <input dir="rtl" type="radio" name="foor" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>بدون</p>
              <input dir="rtl" type="radio" name="foor" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>نوع العزل</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>حراري</p>
              <input dir="rtl" type="radio" name="protection" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>حراري مائي</p>
              <input dir="rtl" type="radio" name="protection" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مائي</p>
              <input dir="rtl" type="radio" name="protection" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>بدون</p>
              <input dir="rtl" type="radio" name="protection" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>شاغرية المبني</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>خالي</p>
              <input dir="rtl" type="radio" name="availability" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مأهول </p>
              <input dir="rtl" type="radio" name="availability" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>مؤجر</p>
              <input dir="rtl" type="radio" name="availability" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>جزء مأهول وجزء غير مأهول</p>
              <input dir="rtl" type="radio" name="availability" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>العقار جاهز للسكن</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم</p>
              <input dir="rtl" type="radio" name="ready" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="ready" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>هل يوجد تداخل بين العقار والعقار المجاور؟</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم</p>
              <input dir="rtl" type="radio" name="building_intersection" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="building_intersection" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>هل يوجد زيادة في مسطحات البناء؟</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم</p>
              <input dir="rtl" type="radio" name="increase_in_space" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="increase_in_space" />
            </label>
          </div>
        </div>
      </article>
      <article>
        <div className="inputs_feild">
          <div className="right max">
            <div className="sub-title" dir="rtl">
              <h3>تقيم الأراضي والمباني</h3>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>نصيبها من الأرض</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>مساحة الشقة</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>الدور الأول</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>الدور المتكرر</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>الملحق الأرضي</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>الملحق العلوي</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>الأسوار</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>القبو</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>الميزان</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>المسبح</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>مواقف السيارات</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>أخرى</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>الخزان</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom grid calc">
              <div className="sub-title" dir="rtl">
                <h3>البيارة</h3>
              </div>
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i3"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
            <div className="right bottom calc">
              <label htmlFor="">
                <p>المساحة</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i1"
                />
              </label>
              <label htmlFor="">
                <p>سعر المتر</p>
                <input
                  dir="rtl"
                  type="number"
                  name=""
                  onChange={(e) => {
                    calc(e.target);
                  }}
                  id="input1"
                  className="i2"
                />
              </label>
              <label htmlFor="">
                <p>المجموع</p>
                <input
                  dir="rtl"
                  type="text"
                  name=""
                  id="total"
                  maxLength={0}
                  Style={"pointer-events:none;"}
                />
              </label>
            </div>
          </div>
        </div>
      </article>
      <article>
        <div className="inputs_feild">
          <div className="right max">
            <div className="sub-title" dir="rtl">
              <h3>تقيم الايجارات</h3>
            </div>
            <label htmlFor="agent_name">
              <p>عدد الشقق</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>إيجار الشقة</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>إجمالي الإيجارات</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>عدد المحلات</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>إيجار المحلات</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>إجمالي الإيجارات</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>مجموع الإيجارات</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>نسبة الصيانة</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>نسبة الشواغر</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>نسبة الدخل</p>
              <input type="text" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>الإيجار للشقق</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>الأيجار فعلي</p>
              <input dir="rtl" type="radio" name="rent" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>أيجار متوقع</p>
              <input dir="rtl" type="radio" name="rent" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>الإيجار للمحلات</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>الأيجار فعلي</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>أيجار متوقع</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>العقار مطابق لفسح البناء</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="agent_name">
              <p>ملاحظة</p>
              <input type="text" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>مخالفة العقار لأنظمة البناء</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="agent_name">
              <p>ملاحظة</p>
              <input type="text" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>السقف خرساني مسلح</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="agent_name">
              <p>ملاحظة</p>
              <input type="text" />
            </label>
          </div>
          <div className="right bottom grid">
            <div className="sub-title" dir="rtl">
              <h3>خضوع العقار لنظام الأراضي البيضاء</h3>
            </div>
            <label htmlFor="type" id="checkbox-label2">
              <p>لا</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="type" id="checkbox-label2">
              <p>نعم</p>
              <input dir="rtl" type="radio" name="rent_shops" />
            </label>
            <label htmlFor="agent_name">
              <p>اسباب الخضوع</p>
              <input type="text" />
            </label>
          </div>
          <div className="right max">
            <div className="sub-title" dir="rtl">
              <h3>عروض بيع المباني المماثلة بنفس منطقة العقار</h3>
            </div>
            <div className="sub-title" dir="rtl">
              <h3>المقارن 1</h3>
            </div>
            <label htmlFor="agent_name">
              <p>نوع العقار</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p> مساحة الأرض</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>مساحة المباني</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>إجمالي قيمة العقار</p>
              <input type="text" />
            </label>
            <div className="sub-title" dir="rtl">
              <h3>المقارن 2</h3>
            </div>
            <label htmlFor="agent_name">
              <p>نوع العقار</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p> مساحة الأرض</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>مساحة المباني</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>إجمالي قيمة العقار</p>
              <input type="text" />
            </label>
          </div>
          <div className="right max">
            <div className="sub-title" dir="rtl">
              <h3>عروض بيع الاراضي المشابهة بنفس منطقة العقار</h3>
            </div>
            <div className="sub-title" dir="rtl">
              <h3>المقارن 1</h3>
            </div>
            <label htmlFor="agent_name">
              <p>نوع العقار</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p> مساحة الأرض</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>مساحة المباني</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>إجمالي قيمة العقار</p>
              <input type="text" />
            </label>
            <div className="sub-title" dir="rtl">
              <h3>المقارن 2</h3>
            </div>
            <label htmlFor="agent_name">
              <p>نوع العقار</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p> مساحة الأرض</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>مساحة المباني</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>إجمالي قيمة العقار</p>
              <input type="text" />
            </label>
          </div>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}
export default Data;
