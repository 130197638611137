// import {Link} from 'react-router-dom';
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "../style/Prices.css";
import "../style/contract.css";
import "../style/show.css";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import { useParams } from "react-router-dom";
import localforage from "localforage";
import SignatureCanvas from "react-signature-canvas";

function Empolyee() {
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  const [signature, setSignature] = useState("");
  const [cleared, setCleared] = useState(false);
  const sigCanvas = useRef(null);

  const handleClear = () => {
    sigCanvas.current.clear();
    setCleared(true);
    setSignature("");
  };

  const handleEnd = (signatureData) => {
    setSignature(signatureData);
    setCleared(false);
  };

  const displaySignature = () => {
    if (!signature) return null;

    try {
      var imageUrl = sigCanvas.current.toDataURL("image/png");
      return <img src={imageUrl} alt="Signature" />;
    } catch (error) {
      console.error("Error decoding signature:", error);
      // Handle decode error gracefully (e.g., display message to user)
      return <p>Error displaying signature.</p>;
    }
  };
  return (
    <form className="menuClose" id="main_form">
      <div className="title">
        <h1>اضافة مستخدم</h1>
      </div>
      <article data-active="true">
        <div className="sub-title">
          <h3>بيانات المستخدم</h3>
        </div>
        <div className="inputs_feild">
          <div className="right">
            <label htmlFor="agent_name">
              <p>الأسم رباعي</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>المثمن البديل</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>كلمة المرور</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>تأكيد كلمة المرور</p>
              <input type="text" />
            </label>
            <label htmlFor="company_email">
              <p>الأيميل</p>
              <input
                dir="rtl"
                type="gmail"
                value={email}
                onInput={(e) => {
                  setemail(e.target.value);
                }}
              />
            </label>
            <label htmlFor="company_phone">
              <p>رقم الهاتف </p>
              <input
                dir="rtl"
                type="tel"
                pattern="[0-9]"
                value={phone}
                onInput={(e) => {
                  setphone(e.target.value);
                }}
              />
            </label>
            <label htmlFor="company_phone">
              <p>رقم العضوية</p>
              <input dir="rtl" type="tel" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>اتعاب المستخدم</p>
              <input dir="rtl" type="tel" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>رقم الهوية</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>صورة العضوية</p>
              <input dir="rtl" type="file" pattern="[0-9]" />
            </label>
            <label htmlFor="value_type">
              <p>المثمن البديل</p>
              <select dir="rtl" name="value_type" id="value_type">
                <option value=""></option>
              </select>
            </label>
            <div className="right">
              <label htmlFor="type" id="checkbox-label">
                <p>مدير عام</p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>مدخل بيانات</p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>مقيـم</p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>مشـرف</p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>محـاسب</p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>بنك</p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>رفع معاملات</p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>مدير دراسات</p>
                <input dir="rtl" type="checkbox" />
              </label>
            </div>
            <div className="right">
              <div className="signature-pad-container">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{ width: 500, height: 200 }}
                  onEnd={handleEnd}
                />
                <button onClick={handleClear} disabled={cleared}>
                  مسح التوقيع
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}
export default Empolyee;
