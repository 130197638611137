import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { IoIosAddCircleOutline, IoIosWarning } from "react-icons/io";
import { gsap } from "gsap";
var count = 0;
var num = 0;
var growth = 0;
const Flows = () => {
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  const RefAlert = useRef(null);
  const RefForm = useRef(null);
  const prop_info = useRef(null);
  const [capitalization, set_capitalization] = useState("");
  const [insurance_percent, set_insurance] = useState("");
  const [growth_rate, set_growth_rate] = useState("");
  const [discount, set_discount] = useState("");
  const [fixing, se_fixing] = useState("");
  const [years, set_years] = useState("");
  const [starting_years, set_starting_years] = useState("");
  function addProps() {
    gsap.to(prop_info.current, { display: "flex" });
    gsap.to(prop_info.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
      delay: 0.2,
    });
  }
  function fadeOut2() {
    gsap.to(prop_info.current, { display: "none", opacity: 0, duration: 0.5 });
  }
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  //   const [count, setcount] = useState(0);
  //   const [num, setnum] = useState(0);
  //   const [growth, setgrowth] = useState(0);
  useEffect(() => {
    count = Number(starting_years);
  }, [starting_years]);

  function createTableRow() {
    const years = document.querySelector(".years");
    const increase_rate = document.querySelector(".increase_rate");
    const total_sum = document.querySelector(".total_sum");
    const flows = document.querySelector(".flows");
    const features = document.querySelectorAll(".features");
    const insurance = document.querySelector(".insurance");
    const insurance_value = document.querySelector(".insurance_value");
    const income = document.querySelector(".income");
    const oparation_taxes = document.querySelector(".oparation_taxes");
    const total_oparation = document.querySelector(".total_oparation");
    const discount = document.querySelector(".discount");
    const current_value = document.querySelector(".current_value");
    // ? ******************

    features.forEach((feature) => {
      var features_value = document.createElement("td");
      features_value.dir = "rtl";
      features_value.dataset.connect = feature.dataset.title_count;
      features_value.dataset.value = "value";
      feature.appendChild(features_value);
    });

    // ? ******************
    var flows_value = document.createElement("th");
    flows_value.textContent = growth + "%";
    flows_value.dir = "rtl";
    flows_value.id = "flow";
    flows.appendChild(flows_value);
    // ? ******************
    var year = document.createElement("th");
    year.textContent = count;
    year.dataset.year_id = count;
    year.dir = "rtl";
    year.id = "new_th";
    years.appendChild(year);
    // ? ******************
    var increase_rate_value = document.createElement("th");
    increase_rate_value.textContent = num;
    increase_rate_value.dir = "rtl";
    increase_rate_value.id = "new_th";
    increase_rate_value.dataset.increase_rate = num;
    increase_rate.appendChild(increase_rate_value);
    // ? ******************
    var total_sum_value = document.createElement("th");
    total_sum_value.dir = "rtl";
    total_sum_value.id = "total_sum";
    total_sum_value.dataset.pointer = num;
    total_sum_value.dataset.value = "value";
    total_sum.appendChild(total_sum_value);
    // ? ******************
    var td_insurance = document.createElement("td");
    td_insurance.id = "insurance";
    td_insurance.dir = "ltr";
    td_insurance.textContent = insurance_percent + "%";
    td_insurance.dataset.value = "value";
    insurance.appendChild(td_insurance);
    // ? ******************
    var td_insurance_value = document.createElement("td");
    td_insurance_value.id = "insurance_value";
    td_insurance_value.dir = "ltr";
    td_insurance_value.dataset.id = num;
    td_insurance_value.dataset.value = "value";
    insurance_value.appendChild(td_insurance_value);
    // ? ******************
    var td_income_value = document.createElement("td");
    td_income_value.id = "main_feature";
    td_income_value.dir = "ltr";
    td_income_value.dataset.value = "value";
    td_income_value.dataset.income = num;
    income.appendChild(td_income_value);
    // ? ******************
    var td_oparation_taxes_value = document.createElement("td");
    td_oparation_taxes_value.id = "main_feature";
    td_oparation_taxes_value.dir = "ltr";
    td_oparation_taxes_value.dataset.value = "value";
    td_oparation_taxes_value.dataset.oparation_taxes = num;
    oparation_taxes.appendChild(td_oparation_taxes_value);
    // ? ******************
    var td_total_oparation = document.createElement("td");
    td_total_oparation.dir = "ltr";
    td_total_oparation.id = `total_oparation${count}`;
    td_total_oparation.dataset.value = "value";
    td_total_oparation.dataset.total_oparation = num;
    total_oparation.appendChild(td_total_oparation);
    // ? ******************
    var td_discount = document.createElement("td");
    td_discount.dataset.value = "value";
    td_discount.dir = "ltr";
    td_discount.dataset.discount = num;
    discount.appendChild(td_discount);
    // ? ******************
    var td_current_value = document.createElement("td");
    td_current_value.id = "des";
    td_current_value.dataset.value = "value";
    td_current_value.dataset.current_value = num;
    current_value.appendChild(td_current_value);
    // ? ******************

    // setcount(count + 1);
    count = count + 1;
    // setnum(num + 1);
    num = num + 1;
    // setgrowth(0);
    if (num % 3 === 0 && num !== 0) {
      growth = growth + Number(growth_rate);
    } else {
      growth = 0;
    }
  }

  function is_number(value) {
    var number = value.textContent;
    if (Number.isFinite(Number(number))) {
      value.style = "background:transparent;";
      //   setActive(Math.random());
      return true;
    } else {
      value.style = "background:#fa6464;";
      return false;
    }
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [Case, setCase] = useState("");
  const [CaseCount, setCaseCount] = useState(1);

  function addNewCase() {
    if (Case) {
      var tbody = document.querySelector(".prop_tbody");
      var tr = document.createElement("tr");
      tr.id = "index";
      tr.dataset.title_count = CaseCount;
      tr.classList.add("features");
      tr.addEventListener("input", () => {
        document.querySelectorAll("[data-title_count]").forEach((tr) => {
          tr.dataset.click = false;
        });
        tr.dataset.click = true;
      });
      tr.dataset.click = true;
      tr.addEventListener("focus", () => {
        document.querySelectorAll("[data-title_count]").forEach((tr) => {
          tr.dataset.click = false;
        });
        tr.dataset.click = true;
      });
      var td = document.createElement("td");
      td.textContent = Case;
      td.dir = "rtl";
      td.id = "title";
      var price = document.createElement("td");
      price.id = "price";
      price.dataset.count = CaseCount;
      price.dir = "ltr";
      price.contentEditable = true;
      price.addEventListener("input", () => {
        calc_all(price);
      });
      var space = document.createElement("td");
      space.id = "space";
      space.dataset.count = CaseCount;
      space.dir = "ltr";
      space.contentEditable = true;
      space.addEventListener("input", () => {
        calc_all(space);
      });
      tr.appendChild(td);
      tr.appendChild(price);
      tr.appendChild(space);
      if (num > 0) {
        for (let i = 0; i < num; i++) {
          var features_value = document.createElement("td");
          features_value.dir = "rtl";
          features_value.dataset.connect = tr.dataset.title_count;
          features_value.dataset.value = "value";
          tr.appendChild(features_value);
        }
      }
      tbody.appendChild(tr);
      setCaseCount(CaseCount + 1);
      setCase("");
      fadeOut2();
      for (let i = 0; i < years; i++) {
        createTableRow();
      }
      set_years(0);
    } else {
      showMessage("لايمكن ترك اسم عامل التسوية فارغ", true);
    }
  }
  function calc_all(x) {
    var result;
    document.querySelectorAll("[data-count]").forEach((element) => {
      result = is_number(element);
      if (result == false) {
        element.dataset.type = false;
      }
    });
    var key = x.dataset.count;

    if (result === true) {
      var price = Number(
        document.querySelector(`#price[data-count="${x.dataset.count}"]`)
          .textContent
      );
      var space = Number(
        document.querySelector(`#space[data-count="${x.dataset.count}"]`)
          .textContent
      );
      var value = document.querySelectorAll(
        `[data-connect="${x.dataset.count}"]`
      )[1];

      value.textContent = space * price;
      document.querySelectorAll("#index").forEach((feature) => {
        var x = feature.querySelectorAll("[data-value]")[0];
        x.textContent = 0;
      });
      var prop = document.querySelector(`[data-click="true"]`);
      var all_prop = document.querySelectorAll(`[data-title_count]`);
      var total_sum = document.querySelectorAll(`[data-pointer]`);
      var value = prop.querySelectorAll(`[data-connect="${key}"]`);

      for (let i = 1; i < value.length; i++) {
        var ele = prop.querySelectorAll(`[data-connect="${key}"]`)[i];
        var ele_value = Number(ele.textContent);
        var percent = document.querySelectorAll("#flow")[i + 1];
        var result_field = prop.querySelectorAll(`[data-connect="${key}"]`)[
          i + 1
        ];
        if (result_field && percent) {
          var x = percent.textContent.split("%");
          x.pop();
          var num = Number(x.join(""));
          var percent_value = (num / 100).toFixed(2);
          var y = Number(percent_value) + 1;
          result_field.textContent = (ele_value * y).toFixed(0);
        }
      }
      var sum = 0;
      var sum2 = 0;
      var arr = [];
      var arr_value = [];
      var final_arr = [];
      var final_discount;
      for (let i = 1; i < total_sum.length; i++) {
        for (let j = 0; j < all_prop.length; j++) {
          var values = all_prop[j].querySelectorAll("[data-connect]")[i];
          var column = values.dataset.connect;
          if (arr.indexOf(column) == -1) {
            arr_value.push(Number(values.textContent));
            for (let i = 0; i < arr_value.length; i++) {
              sum += arr_value[i];
            }
            document.querySelector(`[data-pointer="${i}"]`).textContent =
              numberWithCommas(sum);
            document.querySelector(`[data-id="${i}"]`).textContent =
              numberWithCommas(
                Number(sum * (insurance_percent / 100)).toFixed(0)
              );
            var value = Number(sum * (insurance_percent / 100)).toFixed(0);
            var income = sum - Number(value);
            document.querySelector(`[data-income="${i}"]`).textContent =
              numberWithCommas(income);
            var taxes = Number((income * (fixing / 100)).toFixed(0));
            document.querySelector(
              `[data-oparation_taxes="${i}"]`
            ).textContent = numberWithCommas(Number(taxes));
            var total_oparation = income - taxes;
            document.querySelector(
              `[data-total_oparation="${i}"]`
            ).textContent = numberWithCommas(total_oparation);
            var return_value =
              total_oparation * (1 / (Number(capitalization) / 100));
            document.querySelector(`#return_value`).textContent =
              numberWithCommas((return_value).toFixed(0));
            var increase_rate = document.querySelector(
              `[data-increase_rate="${i}"]`
            );
            var decount_percent = Number(
              (
                1 /
                (1 + Number(discount) / 100) **
                  increase_rate.dataset.increase_rate
              ).toFixed(5)
            );
            final_discount = decount_percent;
            document.querySelector(`[data-discount="${i}"]`).textContent =
              decount_percent ;
            var current_value = Number(
              (decount_percent * total_oparation).toFixed(0)
            );
            final_arr.push(current_value);
            document.querySelector(`[data-current_value="${i}"]`).textContent =
              numberWithCommas(current_value);

            //!
            sum = 0;
          }
        }

        arr_value = [];
      }
    }
    var retrun_value = document.querySelector("#return_value").textContent;
    var x = retrun_value.split(",");
    var y = x.join("");
    var z = Number(y) * final_discount;
    final_arr.push(z);
    for (let k = 0; k < final_arr.length; k++) {
      sum2 += final_arr[k];
    }
    document.querySelector(`.net_current_value`).textContent =
      numberWithCommas(Number(sum2.toFixed(0)));
    sum2 = 0;
    final_arr = [];
  }
  const [type, settype] = useState(null);
  const [state, setState] = useState(false);
  useEffect(() => {
    if (state == false) {
      gsap.fromTo(
        RefAlert.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5 }
      );
      gsap.fromTo(
        RefForm.current,
        { opacity: 0, y: 10 },
        { y: 0, opacity: 1, duration: 0.5, delay: 0.5 }
      );
      // setState(true);
    } else {
      gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
      gsap.to(RefAlert.current, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.5,
      });
    }
  }, [state]);
  function fadeOut() {
    if (
      capitalization == "" ||
      insurance_percent == "" ||
      growth_rate == "" ||
      discount == "" ||
      fixing == "" ||
      years == "" ||
      starting_years == ""
    ) {
      showMessage("يجب تحديد النوع", true);
    } else {
      addProps();
      setState(true);
      gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
      gsap.to(RefAlert.current, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.5,
      });
    }
  }

  return (
    <form className="menuClose land_val" id="main_form">
      <div className="alert" ref={RefAlert}>
        <div id="search" ref={RefForm}>
          <h1 dir="rtl"> افتراضات طريقة التدفقات النقدية </h1>
          <label htmlFor="">
            <p dir="rtl">معدل الرسملة</p>
            <input
              type="number"
              value={capitalization}
              onChange={(e) => {
                set_capitalization(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">الاشغال و الاتمان</p>
            <input
              type="number"
              value={insurance_percent}
              onChange={(e) => {
                set_insurance(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">معدل النمو </p>
            <input
              type="number"
              value={growth_rate}
              onChange={(e) => {
                set_growth_rate(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">معدل الخصم</p>
            <input
              type="number"
              value={discount}
              onChange={(e) => {
                set_discount(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">الصيانة والتشغيل</p>
            <input
              type="number"
              value={fixing}
              onChange={(e) => {
                se_fixing(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">سنوات التدفقات</p>
            <input
              type="number"
              value={years}
              onChange={(e) => {
                set_years(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">ابتداءا من سنة</p>
            <input
              type="text"
              value={starting_years}
              onChange={(e) => {
                set_starting_years(e.target.value);
              }}
              maxLength={4}
              name="how_to_calc_building"
            />
          </label>
          <span>
            <button form="fake" onClick={fadeOut}>
              تحديد
            </button>
          </span>
        </div>
      </div>
      <div className="title">
        <h1>التقييم بطريقة التدفقات</h1>
      </div>
      <article className="prop">
        <div className="sub_title">
          <div className="property_btn">
            <button form="fake" onClick={addProps}>
              <p>اضافة اصل</p>
              <IoIosAddCircleOutline />
            </button>
          </div>
        </div>
        <div className="input_prop" ref={prop_info}>
          <div className="title">
            <h1>اضف اصل </h1>
          </div>
          <div className="inputs-prop">
            <label htmlFor="property_number">
              <p>اسم الاصل</p>
              <input
                type="test"
                name="property_number"
                value={Case}
                onChange={(e) => setCase(e.target.value)}
              />
            </label>
          </div>
          <div className="props_actions">
            <button form="fake" id="save" onClick={addNewCase}>
              اضافة
            </button>
            <button form="fake" id="cancel" onClick={fadeOut2}>
              الغاء
            </button>
          </div>
        </div>
        <div className="table">
          <table dir="rtl">
            <thead>
              <tr className="years">
                <th colSpan={3}>السنوات</th>
              </tr>
              <tr className="flows">
                <th colSpan={3}>معدل النمو كل ثلاث سنوات</th>
              </tr>
              <tr className="increase_rate">
                <th colSpan={3}>الاصول</th>
              </tr>
            </thead>
            <tbody className="prop_tbody"></tbody>
            <thead>
              <tr id="index" className="total_sum">
                <th colSpan={3}>مجمل الدخل</th>
              </tr>
            </thead>
            <tbody className="main_tbody">
              <tr id="index" className="insurance">
                <td colSpan={3}>الشواغر والاتمان</td>
              </tr>
              <tr id="index" className="insurance_value">
                <td colSpan={3}>حساب قيمة الاشغار والاتمان</td>
              </tr>
              <tr id="index" className="income">
                <td colSpan={3}>اجمالي الدخل الفعال</td>
              </tr>
              <tr id="index" className="oparation_taxes">
                <td colSpan={3}>حساب الصيانة والتشغيل</td>
              </tr>
              <tr id="index" className="total_oparation">
                <td colSpan={3}>صافي الدخل التشغيلي</td>
              </tr>
              <tr>
                <td colSpan={3}>القيمة الاستردادية</td>
                <td colSpan={100} id="return_value"></td>
              </tr>
              <tr id="index" className="discount">
                <td colSpan={3}>معامل الخصم</td>
              </tr>
              <tr id="index" className="current_value">
                <td colSpan={3}>القيمة الحالية للتدفقات النقدية</td>
              </tr>
              <tr>
                <td colSpan={3}>صافي القيمة الحالية</td>
                <td colSpan={100} className="net_current_value"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
};

export default Flows;
