// import {Link} from 'react-router-dom';
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "../style/Prices.css";
import "../style/contract.css";
import "../style/show.css";
import { gsap } from "gsap";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import { useParams } from "react-router-dom";
import localforage from "localforage";
import QRCode from "qrcode";
import { Link } from "react-router-dom";
const days = new Map();
const payment_map = new Map();
function Prices() {
  const param = useParams();
  useLayoutEffect(() => {
    if (param.id) {
      localforage.setItem("company_id", param.id);
    }
  }, []);

  const [prop, setProp] = useState({
    table_row_no: "0",
    property_type: "",
    property_number: "",
    property_location: "",
    property_space: "",
    evaluation_cost: "",
    svg_edit: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g id="Edit"><g><path d="M3.548,20.938h16.9a.5.5,0,0,0,0-1H3.548a.5.5,0,0,0,0,1Z"></path><path d="M9.71,17.18a2.587,2.587,0,0,0,1.12-.65l9.54-9.54a1.75,1.75,0,0,0,0-2.47l-.94-.93a1.788,1.788,0,0,0-2.47,0L7.42,13.12a2.473,2.473,0,0,0-.64,1.12L6.04,17a.737.737,0,0,0,.19.72.767.767,0,0,0,.53.22Zm.41-1.36a1.468,1.468,0,0,1-.67.39l-.97.26-1-1,.26-.97a1.521,1.521,0,0,1,.39-.67l.38-.37,1.99,1.99Zm1.09-1.08L9.22,12.75l6.73-6.73,1.99,1.99Zm8.45-8.45L18.65,7.3,16.66,5.31l1.01-1.02a.748.748,0,0,1,1.06,0l.93.94A.754.754,0,0,1,19.66,6.29Z"></path></g></g></svg>`,
    svg_delete: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>`,
  });
  const [props_offer, set_props_offer] = useState({
    f_id: [],
    property_type: [],
    property_number: [],
    property_location: [],
    property_space: [],
    evaluation_cost: [],
  });
  const [main_Table, set_main_Table] = useState([]);
  var [Counter, set_Counter] = useState(0);
  const [payment, set_payment] = useState(1);
  const [phase, set_phase] = useState(1);
  const [select_purpose, set_purpose] = useState("");
  const [select_value_type, set_value_type] = useState("");
  const typeRef1 = useRef(null);
  const typeRef2 = useRef(null);
  const typeRef3 = useRef(null);
  const typeRef5 = useRef(null);
  const prop_info = useRef(null);
  const messageRef = useRef(null);
  const printRef = useRef(null);
  const canvasRef = useRef(null);
  const message_content_Ref = useRef(null);
  const QRCode_imgRef = useRef(null);
  const messageSVG = useRef(null);
  const [date, setdate] = useState();
  const [offer_id, set_offer_id] = useState();
  const [url, set_url] = useState();
  function fadeOut2() {
    gsap.to(prop_info.current, { display: "none", opacity: 0, duration: 0.5 });
  }
  function addProps() {
    gsap.to(prop_info.current, { display: "flex" });
    gsap.to(prop_info.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
      delay: 0.2,
    });
  }
  const handlePropChange = (event) => {
    const updatedPropData = { ...prop }; // Copy the existing formData object
    updatedPropData[event.target.name] = event.target.value; // Update specific property
    setProp(updatedPropData);
  };
  var stop = false;
  const [totalCost, set_TotalCost] = useState(0);
  const [totalCost2, set_TotalCost2] = useState(0);
  const [taxes, set_taxes] = useState(0);
  const [netCost, set_netCost] = useState(0);
  const [number_to_words, set_number_to_words] = useState("");
  useEffect(() => {
    var tax = Math.floor(totalCost * 0.15); //الضريبة
    set_taxes(numberWithCommas(tax));
    var newTotal = tax + totalCost;
    set_netCost(numberWithCommas(newTotal)); //الاجمالي
    var letters = tafqit(newTotal);
    set_number_to_words(numberWithCommas(letters)); //الارقام بالعربي
    set_TotalCost2(numberWithCommas(totalCost));
    var name = "totalCost";
    setData((prevData) => ({
      ...prevData,
      results: {
        ...prevData.results,
        [name]: totalCost, // Dynamic property access for any client field
      },
    }));
  }, [totalCost]);
  useEffect(() => {
    var name = "taxes";
    setData((prevData) => ({
      ...prevData,
      results: {
        ...prevData.results,
        [name]: taxes, // Dynamic property access for any client field
      },
    }));
  }, [taxes]);
  useEffect(() => {
    var name = "netCost";
    setData((prevData) => ({
      ...prevData,
      results: {
        ...prevData.results,
        [name]: netCost, // Dynamic property access for any client field
      },
    }));
  }, [netCost]);
  useEffect(() => {
    var name = "total_cost_AR";
    setData((prevData) => ({
      ...prevData,
      results: {
        ...prevData.results,
        [name]: number_to_words, // Dynamic property access for any client field
      },
    }));
  }, [number_to_words]);
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      props_offer, // Dynamic property access for any client field
    }));
  }, [props_offer]);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function createTableRow() {
    const tbody = document.querySelector(".main_tbody");
    const tr = document.createElement("tr");
    const cells = []; // Empty array to store cells
    const data = [];
    // Iterate through object properties using destructuring
    for (const [key, value] of Object.entries(prop)) {
      if (value === "") {
        for (const [key, value] of Object.entries(props_offer)) {
          props_offer[key].pop();
        }
        return showMessage("لايمكن ترك حقل فارع"), (stop = true);
      } else {
        tr.id = "table_row_" + Counter;
        tr.dataset.id = Counter;
        const td = document.createElement("td");
        // Clear the input element's value
        if (key == "svg_edit" || key == "svg_delete") {
          td.classList.add("btns");
          td.dataset.id = Counter;
          if (key == "svg_edit") {
            td.dataset.action = "svg_edit";
            td.dataset.clicked = "false";
            td.addEventListener("click", () => {
              var tr = document.querySelector(`#table_row_${td.dataset.id}`);
              if (tr) {
                if (td.dataset.clicked === "false") {
                  tr.querySelectorAll("#data").forEach((td) => {
                    if (td.classList.contains("btns")) {
                    } else {
                      td.classList.add("edit_on");
                      td.setAttribute("contentEditable", true);
                    }
                  });
                  td.dataset.clicked = "true";
                } else if (td.dataset.clicked === "true") {
                  tr.querySelectorAll("#data").forEach((td) => {
                    if (td.classList.contains("btns")) {
                    } else {
                      td.classList.remove("edit_on");
                      td.setAttribute("contentEditable", false);
                    }
                  });
                  td.dataset.clicked = "false";
                  resign(tr);
                }
              }
            });
          } else {
            td.dataset.action = "svg_delete";
            td.addEventListener("click", () => {
              var tbody = document.querySelector(".main_tbody");
              var tr = document.getElementById(`table_row_${td.dataset.id}`);
              console.log(tbody.querySelectorAll("tr"));
              if (tr) {
                for (let i = 0; i <= td.dataset.id; i++) {
                  if (props_offer.f_id.at(i) == td.dataset.id) {
                    props_offer.property_type.splice(td.dataset.id, 1);
                    props_offer.property_number.splice(td.dataset.id, 1);
                    props_offer.property_location.splice(td.dataset.id, 1);
                    props_offer.property_space.splice(td.dataset.id, 1);
                    props_offer.evaluation_cost.splice(td.dataset.id, 1);
                    tr.remove();
                    props_offer.f_id.pop();
                    show_table();
                    var count = 0;
                    tbody
                      .querySelectorAll("#table_row_count")
                      .forEach((tr, index) => {
                        tr.dataset.row_no = count;
                        count++;
                      });
                    count = 0;
                  }
                }
                recount();
              }
              set_TotalCost(0);
              var sum = 0;
              document.querySelectorAll(".cost").forEach((cost) => {
                sum = sum + Number(cost.textContent);
              });
              set_TotalCost(sum);
              tbody.querySelectorAll(`tr`).forEach((tr, index) => {
                if (tr) {
                  var x = props_offer.evaluation_cost.length - 1;
                  if (x > -1) {
                    set_Counter(props_offer.evaluation_cost.length);
                  } else {
                    set_Counter(0);
                  }
                  tr.id = `table_row_` + index;
                  tr.dataset.id = index;
                  tr.querySelectorAll(".btns")[0].dataset.id = index;
                  tr.querySelectorAll(".btns")[1].dataset.id = index;
                }
              });
            });
          }
          td.innerHTML = value;
          cells.push(td); // Push cell to the array
        } else if (key == "table_row_no") {
          td.textContent = Counter + 1;
          td.id = "table_row_count";
          props_offer.property_type.push(prop.property_type);
          props_offer.property_number.push(parseFloat(prop.property_number));
          props_offer.property_location.push(prop.property_location);
          props_offer.property_space.push(prop.property_space);
          props_offer.evaluation_cost.push(parseFloat(prop.evaluation_cost));
          var x = props_offer.evaluation_cost.length - 1;
          tr.dataset.id = x;
          props_offer.f_id.push(x);
          cells.push(td); // Push cell to the array
        } else if (key == "evaluation_cost") {
          td.classList.add("cost");
          td.dataset.type = key;
          td.id = "data";
          td.textContent = value;
          td.addEventListener("input", () => {
            set_TotalCost(0);
            var sum = 0;
            document.querySelectorAll(".cost").forEach((cost) => {
              sum = sum + Number(cost.textContent);
            });
            set_TotalCost(sum);
          });
          set_TotalCost(totalCost + Number(value));
          data.push(value);
          cells.push(td); // Push cell to the array
        } else {
          td.dataset.type = key;
          td.id = "data";
          td.textContent = value;
          data.push(value);
          cells.push(td); // Push cell to the array
        }
      }
    }
    if (stop == false) {
      // Reverse cell array order
      cells.reverse();
      // Append cells in reversed order
      for (const cell of cells) {
        tr.appendChild(cell);
      }
      main_Table.push(data.reverse());
      tbody.appendChild(tr);

      fadeOut2();
      show_table();
      var x = props_offer.evaluation_cost.length - 1;
      if (x > -1) {
        set_Counter((prevCount) => prevCount + 1);
      } else {
        set_Counter(0);
      }
      setProp({
        ...prop,
        property_type: "",
        property_number: "",
        property_location: "",
        property_space: "",
        evaluation_cost: "",
      });
    }
    recount();
  }
  function resign(tr) {
    const entries = Object.entries(props_offer);
    for (const [key, value] of entries) {
      if (key !== "f_id") {
        props_offer[key][tr.dataset.id] = tr.querySelector(
          `[data-type="${key}"]`
        ).textContent;
      }
    }
  }
  function recount() {
    document.querySelectorAll("#table_row_count").forEach((td, index) => {
      td.textContent = "";
      td.textContent = index + 1;
    });
  }
  function show_table() {
    const tbody = document.querySelector(".show_tbody");
    tbody.innerHTML = ""; // Clear existing content
    var count = 0;
    for (let i = 0; i < props_offer.evaluation_cost.length; i++) {
      const tr = document.createElement("tr");
      const cells = []; // Array to store cells temporarily
      for (const [key, value] of Object.entries(props_offer)) {
        // Create cell for all keys
        const td = document.createElement("td");
        td.textContent = value[count];
        // Optionally filter specific keys (if desired)
        if (key !== "evaluation_cost") {
          cells.push(td); // Add cell to the cells array
        }
      }
      count++;
      // Append cells in reverse order
      cells.reverse();
      for (const cell of cells) {
        tr.appendChild(cell);
      }

      tbody.appendChild(tr); // Add completed row to table body
    }
  }
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  function get_payment_info(key, data) {
    try {
      var ol = document.querySelector(".ol_show");
      ol.innerHTML = "";
      payment_map.set(key, data);
      const cells = [];
      for (let i = 1; i <= key; i++) {
        if (payment_map.get(i).text) {
          var li = document.createElement("li");
          var span = document.createElement("span");
          var span_value = document.createElement("span");
          span_value.dir = "rtl";
          span_value.textContent = payment_map.get(i).amount;
          var p = document.createElement("p");
          p.textContent = payment_map.get(i).text;
          span.appendChild(span_value);
          span.appendChild(p);
          li.appendChild(span);
          cells.push(li);
        }
      }
      var payments = Object.fromEntries(payment_map);
      setData((prevData) => ({
        ...prevData,
        payments, // Dynamic property access for any client field
      }));
      for (var cell in cells) {
        ol.appendChild(cells[cell]);
      }
    } catch (error) {}
  }
  function add_payment(x) {
    const key = payment;
    const payment_section = document.querySelector(".payment_section");
    const paymentBox = document.createElement("div");
    paymentBox.classList.add("payment_box");
    paymentBox.id = `payment_box_no_${payment}`;
    const label = document.createElement("label");
    label.setAttribute("for", `payment_no_${payment}`);

    const input1 = document.createElement("input");
    input1.id = `input_amount` + payment;
    input1.type = "text";
    input1.name = `payment_value_no_${payment}`;
    input1.placeholder = "قيمة الدفعة";
    if (x.amount) {
      input1.value = x.amount;
    }

    const input2 = document.createElement("input");
    input2.id = `input_text` + payment;
    input2.type = "text";
    input2.name = `payment_no_${payment}`;
    input2.placeholder = `الدفعة رقم ${payment}`;
    if (x.text) {
      input2.value = x.text;
      get_payment_info(key, { amount: x.amount, text: x.text });
    }
    label.appendChild(input1);
    label.appendChild(input2);

    const button = document.createElement("button");
    button.id = `payment_box_no_${payment}`;
    button.addEventListener("click", () => {
      document.querySelector(`#${button.id}`).remove();
      payment_map.delete(phase);
      var payments = Object.fromEntries(payment_map);
      setData((prevData) => ({
        ...prevData,
        payments, // Dynamic property access for any client field
      }));
      set_payment(payment - 1 + 1);
    });
    button.setAttribute("form", "fake"); // Assuming a form with id="fake" exists

    const span = document.createElement("span");
    span.innerHTML = prop.svg_delete;
    button.appendChild(span);
    paymentBox.appendChild(label);
    paymentBox.appendChild(button);
    payment_section.appendChild(paymentBox);

    input2.addEventListener("input", () => {
      const amount = document.getElementById(`input_amount${key}`);
      get_payment_info(key, { amount: amount.value, text: input2.value });
    });

    input1.addEventListener("input", () => {
      const text = document.getElementById(`input_text${key}`);
      console.log(text.value);
      get_payment_info(key, { amount: input1.value, text: text.value });
    });
    set_payment(payment + 1);
  }
  function get_phase_info(key, data) {
    try {
      const ev_body_tr = document.querySelector("#evaluation_tbody");
      ev_body_tr.innerHTML = "";
      days.set(key, data);
      for (let i = 1; i <= key; i++) {
        if (days.get(i).days) {
          var tr = document.createElement("tr");
          var td2 = document.createElement("td");
          td2.textContent = days.get(i).days;
          tr.appendChild(td2);
          var td = document.createElement("td");
          td.textContent = days.get(i).task;
          tr.appendChild(td);
          ev_body_tr.appendChild(tr);
        }
      }
      var Evaluation_stages = Object.fromEntries(days);
      setData((prevData) => ({
        ...prevData,
        Evaluation_stages, // Dynamic property access for any client field
      }));
    } catch (error) {}
  }
  function add_phase(x) {
    const key = phase;
    const phase_section = document.querySelector(".phases_section");
    const phaseBox = document.createElement("div");
    phaseBox.classList.add("phase_box");
    phaseBox.id = `phase_box_no_${phase}`;
    const label = document.createElement("label");
    label.setAttribute("for", `phase_no_${phase}`);

    const input1 = document.createElement("input");
    input1.id = `input` + phase;
    input1.type = "text";
    input1.name = `phase_value_no_${phase}`;
    input1.placeholder = "ايام العمل";
    if (x.days) {
      input1.value = x.days;
    }

    const input2 = document.createElement("input");
    input2.id = `input_` + phase;
    input2.type = "text";
    input2.name = `phase_no_${phase}`;
    input2.placeholder = `ادخل العملية`;
    if (x.task) {
      input2.value = x.task;
      get_phase_info(key, { days: x.days, task: x.task });
    }

    label.appendChild(input1);
    label.appendChild(input2);

    const button = document.createElement("button");
    button.id = `phase_box_no_${phase}`;
    button.addEventListener("click", () => {
      document.querySelector(`#${button.id}`).remove();
      days.delete(phase);
      var Evaluation_stages = Object.fromEntries(days);
      setData((prevData) => ({
        ...prevData,
        Evaluation_stages, // Dynamic property access for any client field
      }));
      set_phase(phase - 1 + 1);
    });
    button.setAttribute("form", "fake"); // Assuming a form with id="fake" exists

    const span = document.createElement("span");
    span.innerHTML = prop.svg_delete;
    button.appendChild(span);
    phaseBox.appendChild(label);
    phaseBox.appendChild(button);
    phase_section.appendChild(phaseBox);

    input2.addEventListener("input", () => {
      const days = document.getElementById(`input${key}`);
      get_phase_info(key, { days: days.value, task: input2.value });
    });

    input1.addEventListener("input", () => {
      const task = document.getElementById(`input_${key}`);
      get_phase_info(key, { days: input1.value, task: task.value });
    });
    set_phase(phase + 1);
  }
  const TableScales = [
      "",
      "ألف",
      "مليون",
      "مليار",
      "ترليون",
      "كوادرليون",
      "كوينتليون",
      "سكستليون",
    ], // Add here only
    TableScalesP = ["", "آلاف", "ملايين", "مليارات"], // Do not change this table
    TableMale = [
      "",
      "واحد",
      "اثنان",
      "ثلاثة",
      "أربعة",
      "خمسة",
      "ستة",
      "سبعة",
      "ثمانية",
      "تسعة",
      "عشرة",
    ],
    TableFemale = [
      "",
      "واحدة",
      "اثنتان",
      "ثلاث",
      "أربع",
      "خمس",
      "ست",
      "سبع",
      "ثمان",
      "تسع",
      "عشر",
    ];
  function tafqit(
    NumIn = 0,
    {
      Feminine,
      Comma,
      SplitHund,
      Miah,
      Billions,
      TextToFollow,
      AG,
      Subject,
      Legal,
    } = {}
  ) {
    if (NumIn == 0) return "صفر"; // if 0 or "0" then "zero"
    let Triplet,
      Scale,
      ScalePos,
      ScalePlural,
      TableUnits,
      Table11_19,
      NumberInWords = "",
      IsLastEffTriplet = false,
      Num_99;
    const ON = "on", // Flag to test if Option is ON
      IsAG = AG === ON, // Option Accusative or Genitive case Grammar?
      SpWa = " و", // AND word
      TanweenLetter = "ًا", // Tanween Fatih for Scale Names above 10
      Ahad = "أحد",
      Ehda = "إحدى", // Masculine/Feminine 11
      // ---- Setup constants for the AG Option (Accusative/Genitive or Nominative case Grammar)
      Taa = IsAG ? "تي" : "تا",
      Taan = IsAG ? "تين" : "تان", // Hundred 2's مئتا/مائتا مئتان/مائتان
      Aa = IsAG ? "ي" : "ا",
      Aan = IsAG ? "ين" : "ان", // Scale 2's الفا/مليونا الفان/مليونان
      Ethna = IsAG ? "اثني" : "اثنا",
      Ethnata = IsAG ? "اثنتي" : "اثنتا", // Masculine/Feminine 12 starting word
      Ethnan = IsAG ? "اثنين" : "اثنان",
      Ethnatan = IsAG ? "اثنتين" : "اثنتان", // Masculine/Feminine 2
      Woon = IsAG ? "ين" : "ون", // Second part of 20's to 90's
      IsSubject = Array.isArray(Subject) && Subject.length === 4; // Check for Subject Array Names

    TextToFollow = TextToFollow === ON; // TextToFollow Option Flag
    if (IsSubject) TextToFollow = true; // Enable TextToFollow Option if Subject Option is ON
    NumIn += ""; // Make numeric string
    NumIn = "" + NumIn.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d)); // Convert Arabic-Indic Numbers to Arabic if any
    Miah = Miah === ON ? "مئة" : "مائة"; // Select chosen Miah (Hundred) Option

    TableUnits = [...TableMale];
    Table11_19 = [...TableMale]; // Create copies of Masculine Table for manipulation
    Table11_19[0] = TableFemale[10]; // Borrow word "عشرة" from Feminine's Table for use in 11-19
    Table11_19[1] = Ahad; // Masculine starting words for 11
    Table11_19[2] = Ethna; // Masculine starting words for 12
    TableUnits[2] = Ethnan; // Masculine word for 2

    NumIn = "0".repeat((NumIn.length * 2) % 3) + NumIn; // Convert Number to a Triplets String

    let NumLen = NumIn.length;
    for (let digits = NumLen; digits > 0; digits -= 3) {
      // Loop and convert each Triplet
      Triplet = +NumIn.substr(NumLen - digits, 3); // Get a Triplet Number
      IsLastEffTriplet = !+NumIn.substr(NumLen - digits + 3); // Determine if Last Effective Triplet
      if (Triplet) {
        // If not Empty: Convert Triplet Number to Words
        ScalePos = digits / 3 - 1; // Position of Scale Name in Scale Table
        Scale = TableScales[ScalePos]; // Get Scale Name
        ScalePlural =
          ScalePos < 4 ? TableScalesP[ScalePos] : TableScales[ScalePos] + "ات"; // Make Scale Plural
        if (Billions && ScalePos === 3) {
          Scale = "بليون";
          ScalePlural = "بلايين";
        } // If Billions Option
        NumberInWords += oneTripletToWords(); // Convert 1 Triplet to Words
        if (!IsLastEffTriplet)
          NumberInWords += (Comma === ON ? "،" : "") + SpWa; // Add "و " and Option Comma
      }
    } // All done with conversion, Process Subject Name if any
    let SubjectName = "";
    if (IsSubject) {
      // Process Subject Name
      let space = !IsLastEffTriplet ? "" : " "; // Position correct spacing
      Triplet = +(Triplet + "").slice(-2); // Get last 2 digits of last Triplet
      SubjectName = space + Subject[0]; // Default Subject Name is at Pos 0
      if (Triplet > 10)
        SubjectName = space + Subject[3]; // Subject name with Tanween for 11-99
      else if (Triplet > 2)
        SubjectName = space + Subject[2]; // Subject name Plural for 3-10
      else if (Triplet > 0)
        SubjectName = Subject[Triplet - 1] + " " + TableUnits[Num_99]; // Reverse names for 1 or 2
    }
    return NumberInWords + SubjectName; // All done

    function oneTripletToWords() {
      Num_99 = Triplet % 100; // 00 to 99
      let Num_100 = ~~(Triplet / 100), // Hundreds (1 digit)
        Num_Unit = Num_99 % 10, // 0 to 9 (1 digit)
        Num_Tens = ~~(Num_99 / 10), // Tens   (1 digit)
        Word_100 = "",
        Word_99 = ""; // Holds words for Hundreds & 0-99

      if (Feminine === ON && !Scale) {
        // If Feminine, use the Feminine table if no scale
        TableUnits = [...TableFemale];
        Table11_19 = [...TableFemale]; // Create copies of Feminine Table for manipulation
        Table11_19[0] = TableMale[10]; // Borrow word "عشر" from Masculine's Table for use in 11-19
        Table11_19[1] = Ehda; // Feminine starting words for 11
        Table11_19[2] = Ethnata; // Feminine starting words for 12
        TableUnits[2] = Ethnatan; // Feminine word for 2
        if (Num_99 > 19) TableUnits[1] = Ehda; // Feminine word for 1 used in 20's to 90's
      }

      if (Num_100) {
        // ---- Do Hundreds (100 to 900)
        if (Num_100 > 2)
          Word_100 =
            TableFemale[Num_100] + (SplitHund === ON ? " " : "") + Miah;
        // 300-900
        else if (Num_100 === 1) Word_100 = Miah; // 100
        else
          Word_100 =
            Miah.slice(0, -1) +
            ((Scale && !Num_99) || TextToFollow ? Taa : Taan); // 200 Use either مئتا or مئتان
      }

      if (Num_99 > 19)
        Word_99 =
          TableUnits[Num_Unit] +
          (Num_Unit ? SpWa : "") + // 20-99 Units و and
          (Num_Tens === 2 ? "عشر" : TableFemale[Num_Tens]) +
          Woon;
      // Add Woon for 20's or 30's to 90's
      else if (Num_99 > 10)
        Word_99 = Table11_19[Num_99 - 10] + " " + Table11_19[0]; // 11-19
      else if (Num_99 > 2 || !Num_99 || !IsSubject)
        Word_99 = TableUnits[Num_99]; // 0 or 3-10 (else keep void for 1 &2)

      let Words999 = Word_100 + (Num_100 && Num_99 ? SpWa : "") + Word_99; // Join Hund, Tens, and Units

      if (Scale) {
        // Add Scale Name if applicable
        let legalTxt = Legal === ON && Num_99 < 3 ? " " + Scale : ""; // if Legal Option add Extra Word
        let Word_100Wa = (Num_100 ? Word_100 + legalTxt + SpWa : "") + Scale; // Default Scale Name
        if (Num_99 > 2) {
          Words999 +=
            " " + // Scale for for 3 to 99
            (Num_99 > 10
              ? Scale + (IsLastEffTriplet && TextToFollow ? "" : TanweenLetter) // Scale for 11 to 99 (Tanween)
              : ScalePlural); // Scale for 3 to 10 (Plural)
        } else {
          if (!Num_99) Words999 += " " + Scale; // Scale for 0
          else if (Num_99 === 1) Words999 = Word_100Wa; // Scale for 1
          else
            Words999 =
              Word_100Wa + (IsLastEffTriplet && TextToFollow ? Aa : Aan); // Scale for 2 ألفا or ألفان
        }
      }
      return Words999; //Return the Triple in Words
    }
  }
  const [count, setcount] = useState(0);
  var paymentArr = [
    {
      amount: "70%",
      text: "عند الموفقة و التعميد",
    },
    {
      amount: "30%",
      text: "عند استلام المسودة",
    },
  ];
  var phaseArr = [
    {
      days: "1",
      task: "المعاينة و جمع البيانات",
    },
    {
      days: "1",
      task: "تحليل البيانات و دراستها",
    },
    {
      days: "1",
      task: "اصدار القيمة و اعداد التقرير",
    },
  ];
  useLayoutEffect(() => {
    if (count < 2) {
      var x = paymentArr[count];
      add_payment(x);
    }
  }, [count]);
  useLayoutEffect(() => {
    if (count < 3) {
      var x = phaseArr[count];
      add_phase(x);
      setcount((prev) => prev + 1);
    }
  }, [count]);
  const handelOnclick = (type) => {
    var no_nav = type.split("-")[1];
    var arr = [];
    document.querySelectorAll("article").forEach((article) => {
      arr.push(article.id);
    });
    var ar_type = type.split("");
    ar_type.pop();
    ar_type = ar_type.join("");

    for (let i = 0; i < arr.length - 1; i++) {
      document.querySelectorAll("article[data-active=true]").forEach((item) => {
        item.dataset.active = false;
      });
      document.querySelector(`#s-${no_nav}`).dataset.active = true;
    }
  };
  useLayoutEffect(() => {
    var count = 1;
    document.querySelectorAll(".part").forEach((part) => {
      part.dataset.type = `s-` + count + `-`;
      count++;
    });
    var count_article = 1;
    document.querySelectorAll("article").forEach((article) => {
      if (count_article === 1) {
        article.dataset.active = true;
      } else {
        article.dataset.active = false;
      }
      article.id = `s-` + count_article;
      count_article++;
    });
  }, []);
  async function print_pdf() {
    var ref = document.querySelector(".show");
    var iframe = document.createElement("iframe");
    iframe.classList.add("show");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    var pri = iframe.contentWindow;

    await pri.document.open();
    await pri.document.write(
      `<style>
      @font-face {
  font-family: "main_font";
  src: url("../fonts/El_Messiri/static/ElMessiri-Regular.ttf");
}
::-webkit-scrollbar {
  width: 0.7rem;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: lightgray;
}

:root {
  --txt_clr: #3b4647;
  --sec_txt_clr: #6b6b6b;
  --bg_clr: #f7fafc;
  --bg_clr_2: #D1E2E9;
  --input_bg_clr:white;
  --clr:#65A9C3;
  --clr_light:#eaf1f4;
  --nav_bg_clr:#311c53;
  --nav_bg_clr_light:#472d6f;
  --sec_clr: #28445e;
  --main_size: 1rem;
  --sec_size: calc(1rem + 0.5rem);
  --title_size: calc(1.5rem + 1rem);
  --main_font: main_font;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.show,
body {
  position: relative;
  width: 1150px;
  aspect-ratio: 16/9;
  max-height: 780px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
}
.show > #print,
body > #print {
  width: 100%;
  position: relative;
  min-height: 780px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.show > #print > .img,
body > #print > .img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}
.show > #print > .img > img,
body > #print > .img > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
}
.show > #print .content,
body > #print .content {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  color: #3b4647;
}
.show > #print .content > .hero,
body > #print .content > .hero {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
}
.show > #print .content > .hero p span,
body > #print .content > .hero p span {
  color: #65a9c3;
}
.show > #print:nth-child(1),
body > #print:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.show > #print:nth-child(1) .content,
body > #print:nth-child(1) .content {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  left: -15%;
  top: 7%;
}
.show > #print:nth-child(1) .content .hero,
body > #print:nth-child(1) .content .hero {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  gap: 0.5rem;
}
.show > #print:nth-child(1) .content .hero h1,
body > #print:nth-child(1) .content .hero h1 {
  font-size: 1.5rem;
}
.show > #print:nth-child(1) canvas,
body > #print:nth-child(1) canvas {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(0, -100%);
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  color: black;
}
.show > #print:nth-child(1) > img,
body > #print:nth-child(1) > img {
  width: 120px;
  aspect-ratio: 1/1;
  position: absolute;
  top: 100%;
  left: 30px;
  transform: translate(0px, -100%);
  z-index: 10;
}
.show > #print:nth-child(3), .show > #print:nth-child(4),
body > #print:nth-child(3),
body > #print:nth-child(4) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0rem;
}
.show > #print:nth-child(3) > .content, .show > #print:nth-child(4) > .content,
body > #print:nth-child(3) > .content,
body > #print:nth-child(4) > .content {
  padding: 1rem;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.show > #print:nth-child(3) > .content > table, .show > #print:nth-child(4) > .content > table,
body > #print:nth-child(3) > .content > table,
body > #print:nth-child(4) > .content > table {
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.3rem;
}
.show > #print:nth-child(3) > .content > table > thead, .show > #print:nth-child(4) > .content > table > thead,
body > #print:nth-child(3) > .content > table > thead,
body > #print:nth-child(4) > .content > table > thead {
  background: #65a9c3;
  color: #f7fafc;
}
.show > #print:nth-child(3) > .content > table > tbody tr td, .show > #print:nth-child(4) > .content > table > tbody tr td,
body > #print:nth-child(3) > .content > table > tbody tr td,
body > #print:nth-child(4) > .content > table > tbody tr td {
  text-align: center;
  padding: 0.5rem 0rem;
  color: #3b4647;
}
.show > #print:nth-child(3) > .content > table > tbody tr .edit_on, .show > #print:nth-child(4) > .content > table > tbody tr .edit_on,
body > #print:nth-child(3) > .content > table > tbody tr .edit_on,
body > #print:nth-child(4) > .content > table > tbody tr .edit_on {
  background: #eaf1f4;
  animation: acitve_edit 2s ease infinite;
}
.show > #print:nth-child(3) > .content > table > tbody tr:nth-child(odd), .show > #print:nth-child(4) > .content > table > tbody tr:nth-child(odd),
body > #print:nth-child(3) > .content > table > tbody tr:nth-child(odd),
body > #print:nth-child(4) > .content > table > tbody tr:nth-child(odd) {
  background: #eaf1f4;
}
.show > #print:nth-child(3) > .content > table > tbody tr:nth-child(even), .show > #print:nth-child(4) > .content > table > tbody tr:nth-child(even),
body > #print:nth-child(3) > .content > table > tbody tr:nth-child(even),
body > #print:nth-child(4) > .content > table > tbody tr:nth-child(even) {
  background: #d1e2e9;
}
.show > #print:nth-child(5) .content > p,
body > #print:nth-child(5) .content > p {
  color: #65a9c3;
}
.show > #print .ol_show,
body > #print .ol_show {
  position: relative;
  padding: 0rem 1rem;
}
.show > #print:nth-child(1) > #link {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 100%;
  left: 30px;
  transform: translate(0px, -100%);
  z-index: 10;
}
.show > #print:nth-child(1) > #link img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  opacity: 0;
}
.show > #print .ol_show li span,
body > #print .ol_show li span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
}/*# sourceMappingURL=show.css.map */
      </style>`
    );
    console.log(pri.document);
    await pri.document.write(ref.innerHTML);
    await pri.document.close();
    await pri.focus();
    await pri.print();
    pri.onafterprint = () => {
      document.body.removeChild(iframe);
    };
    pri.oncancel = () => {
      document.body.removeChild(iframe);
    };
  }
  function formatDateToYYYYMMDDHHMMSS(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const currentDate = new Date();
  const [data, setData] = useState({
    client: {},
    results: {},
    props_offer: {},
    Evaluation_stages: {},
    payments: {},
    value_type: "",
    client_relation: "",
    purpose: "",
    date: formatDateToYYYYMMDDHHMMSS(currentDate),
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target; // Destructure event object
    setData((prevData) => ({
      ...prevData,
      client: {
        ...prevData.client,
        [name]: value, // Dynamic property access for any client field
      },
    }));
  };
  const InputChange = (event) => {
    const { name, value } = event.target; // Destructure event object
    setData((prevData) => ({
      ...prevData,
      [name]: value, // Dynamic property access for any client field
    }));
  };
  const handleSelectChange = (event) => {
    const { name, value } = event.target; // Destructure event object
    setData((prevData) => ({
      ...prevData,
      [name]: value, // Dynamic property access for any client field,
    }));
  };

  async function submit() {
    var tl = gsap.timeline({});
    try {
      tl.to(".fix", { opacity: 1, pointerEvents: "all" });
      const response = await axios.post(
        "https://auctionbackend.netlify.app/.netlify/functions/server/submit/offer",
        // "http://localhost:5001/submit/offer",
        data
      );
      console.log(response.data);
      const offerId = JSON.stringify(response.data.offer_id);
      if (offerId) {
        set_offer_id(offerId);
        var url = `https://actionksa.netlify.app/view_offer?item=${offerId}`;
        set_url(url);
        await QRCode.toCanvas(canvasRef.current, url);
        QRCode_imgRef.current.src = canvasRef.current.toDataURL("image/png");
        gsap.to(printRef.current, {
          opacity: 1,
          pointerEvents: "all",
          duration: 0.5,
        });
        gsap.to(QRCode_imgRef.current, {
          opacity: 1,
          duration: 0.5,
        });
      }
      showMessage(response.data.message, false);
    } catch (error) {
      console.log(error);
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }
  const [purpose, setpurpose] = useState();
  const [client_relation, setclient_relation] = useState();
  const [value_type, setvalue_type] = useState();
  useEffect(() => {
    resources();
  }, []);
  async function resources() {
    try {
      var tl = gsap.timeline({});
      tl.to(".fix", { opacity: 1, pointerEvents: "all" });

      // var res = await axios.get("http://localhost:5001/get/purpose");
      var res = await axios.get(
        "https://auctionbackend.netlify.app/.netlify/functions/server/get/purpose"
      );
      console.log(res.data);
      var result = res.data.map((data, index) => (
        <option key={index} value={data.Purpose_of_evaluation_id}>
          {data.Purpose}
        </option>
      ));
      setpurpose(result);
      // res = await axios.get("http://localhost:5001/get/client_relation");
      res = await axios.get(
        "https://auctionbackend.netlify.app/.netlify/functions/server/get/client_relation"
      );
      result = res.data.map((data, index) => (
        <option key={index} value={data.client_relation_id}>
          {data.relation_type}
        </option>
      ));
      setclient_relation(result);
      // res = await axios.get("http://localhost:5001/get/value_type");
      res = await axios.get(
        "https://auctionbackend.netlify.app/.netlify/functions/server/get/value_type"
      );
      result = res.data.map((data, index) => (
        <option key={index} value={data.value_type_id}>
          {data.type}
        </option>
      ));
      setvalue_type(result);
    } catch (error) {
      console.log(error);
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }

  return (
    <form className="menuClose" id="main_form">
      <div className="title">
        <h1>عرض السعر</h1>
      </div>
      <div className="navigation">
        <div
          className="part"
          ref={typeRef1}
          onClick={() => handelOnclick(typeRef1.current.dataset.type)}
        >
          بيانات العميل
        </div>
        <div
          className="part"
          ref={typeRef2}
          onClick={() => handelOnclick(typeRef2.current.dataset.type)}
        >
          محل التقييم
        </div>
        <div
          className="part"
          ref={typeRef3}
          onClick={() => handelOnclick(typeRef3.current.dataset.type)}
        >
          الدفعات و المراحل
        </div>

        <div
          className="part"
          ref={typeRef5}
          onClick={() => handelOnclick(typeRef5.current.dataset.type)}
        >
          عرض و حفظ
        </div>
      </div>
      <article>
        <div className="sub-title">
          <h3>بيانات العميل</h3>
        </div>
        <div className="inputs_feild">
          <div className="right grid2">
            <label htmlFor="agent_name" id="search_label">
              <p> ادخل اسم العميل</p>
              <input
                type="text"
                name="client_name"
                value={data.client.client_name || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="company_email">
              <p>البريد الالكتروني</p>
              <input
                dir="rtl"
                type="gmail"
                name="email"
                value={data.client.email || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="company_phone">
              <p>رقم الهاتف </p>
              <input
                dir="rtl"
                type="number"
                name="phone_number"
                value={data.client.phone_number || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="client_relation">
              <p>علاقة العميل</p>
              <select
                dir="rtl"
                name="client_relation"
                value={data.client_relation || ""}
                onChange={handleSelectChange}
                id="client_des"
              >
                <option value=""></option>
                {client_relation}
              </select>
            </label>
            <label htmlFor="purpose">
              <p>الغرض من التقيم</p>
              <select
                dir="rtl"
                name="purpose"
                value={data.purpose || ""}
                onChange={(e) => {
                  handleSelectChange(e);
                  set_purpose(e.target.selectedOptions[0].innerText);
                }}
                id="purpose"
              >
                <option value=""></option>
                {purpose}
              </select>
            </label>
            <label htmlFor="value_type">
              <p>نوع القيمة</p>
              <select
                dir="rtl"
                name="value_type"
                value={data.value_type || ""}
                onChange={(e) => {
                  handleSelectChange(e);
                  set_value_type(e.target.selectedOptions[0].innerText);
                }}
                id="value_type"
              >
                <option value=""></option>
                {value_type}
              </select>
            </label>
            <label htmlFor="work_period">
              <p> مدة العمل </p>
              <input
                type="number"
                name="work_period"
                id="work_period"
                placeholder="ادخل مدة العمل"
                value={data.work_period || ""}
                onChange={InputChange}
              />
            </label>
          </div>
        </div>
      </article>
      <article className="prop">
        <div className="sub-title">
          <h3>الاصل/ول محل التقييم</h3>
        </div>
        <div className="property_btn">
          <button form="fake" onClick={addProps}>
            <p>اضافة اصل</p>
            <IoIosAddCircleOutline />
          </button>
        </div>
        <div className="input_prop" ref={prop_info}>
          <div className="title">
            <h1>اضف اصل جديد</h1>
          </div>
          <div className="inputs-prop">
            <label htmlFor="property_type">
              <p>نوع الاصل</p>
              <select
                dir="rtl"
                name="property_type"
                id="property_type"
                value={prop.property_type}
                onChange={handlePropChange}
              >
                <option value="">اختار نوع الاصل</option>
                <option value="شقة">شقة</option>
                <option value="فيلا">فيلا</option>
                <option value="عمارة تجارية">عمارة تجارية</option>
                <option value="محل تجاري">محل تجاري</option>
                <option value="ارض">ارض</option>
                <option value="مول تجاري">مول تجاري</option>
                <option value="برج تجاري مكتبي">برج تجاري مكتبي</option>
                <option value="عمارة تجارية سكنية">عمارة تجارية سكنية</option>
                <option value="عمارة تجارية مكتبية">عمارة تجارية مكتبية</option>
                <option value="فيلا سكنية">فيلا سكنية</option>
                <option value="شقة روف">شقة روف</option>
              </select>
            </label>
            <label htmlFor="property_number">
              <p>رقم الصك</p>
              <input
                type="number"
                pattern="[0-9]"
                name="property_number"
                placeholder="ادخل رقم الصك"
                value={prop.property_number}
                onChange={handlePropChange}
              />
            </label>
            <label htmlFor="property_location">
              <p>موقع الاصل</p>
              <input
                type="text"
                name="property_location"
                placeholder="ادخل موقع الاصل "
                value={prop.property_location}
                onChange={handlePropChange}
              />
            </label>
            <label htmlFor="property_space">
              <p>المساحة</p>
              <input
                type="number"
                id="property_space"
                name="property_space"
                placeholder="ادخل المساحة"
                value={prop.property_space}
                onChange={handlePropChange}
              />
            </label>
            <label htmlFor="evaluation_cost">
              <p>اتعاب التقييم</p>
              <input
                type="number"
                id="evaluation_cost"
                name="evaluation_cost"
                placeholder="ادخل اتعاب التقييم"
                value={prop.evaluation_cost}
                onChange={handlePropChange}
              />
            </label>
          </div>
          <div className="props_actions">
            <button form="fake" id="save" onClick={createTableRow}>
              اضافة
            </button>
            <button form="fake" id="cancel" onClick={fadeOut2}>
              الغاء
            </button>
          </div>
        </div>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th colSpan="2">تعديل</th>
                <th>اتعاب التقييم</th>
                <th>المساحة</th>
                <th>موقع الاصل</th>
                <th>رقم الصك</th>
                <th>نوع الاصل</th>
                <th> الترتيب</th>
              </tr>
            </thead>
            <tbody className="main_tbody"></tbody>
          </table>
        </div>
        <div className="total">
          <span>
            <h3 dir="rtl">المبلغ كتابة</h3>
            <span>{number_to_words}</span>
          </span>
          <span>
            <h3 dir="rtl">الاجمالي</h3>
            <span>{netCost}</span>
          </span>
          <span>
            <h3 dir="rtl">قيمة الضريبة</h3>
            <span>{taxes}</span>
          </span>
          <span>
            <h3 dir="rtl">اتعاب التقييم</h3>
            <span>{totalCost2}</span>
          </span>
        </div>
      </article>
      <article className="contract">
        <div className="sub-title">
          <h3> الدفعات ومراحل التقييم </h3>
        </div>
        <div className="inputs_feild">
          <div className="right">
            <label htmlFor="contract_value_number" id="contract_value_number">
              <h1>  الدفعات</h1>
              <button form="fake" id="add_payment" onClick={add_payment}>
                <p> اضافة دفعة</p>
                <IoIosAddCircleOutline />
              </button>
              <div className="payment_section"></div>
            </label>
            <label htmlFor="contract_phase" id="contract_phase">
              <h1> مراحل التقييم</h1>
              <button form="fake" id="add_phase" onClick={add_phase}>
                <p> اضافة مرحلة</p>
                <IoIosAddCircleOutline />
              </button>
              <div className="phases_section"></div>
            </label>
          </div>
        </div>
      </article>
      <article>
        <div className="sub-title">
          <h3> عرض مبدئي</h3>
          <button form="fake" ref={printRef} id="printBtn" onClick={print_pdf}>
            طباعة
          </button>
          <button form="fake" onClick={submit}>
            حفظ
          </button>
        </div>
        <div className="show" id="show">
          <section id="print">
            <div className="img">
              <img
                src="https://res.cloudinary.com/dzi9hg6em/image/upload/v1716209556/bg_1_wdmcss.png"
                alt=""
              />
            </div>
            <div className="content">
              <div className="hero">
                <h1 dir="rtl">عرض سعر</h1>
                <p dir="rtl">خدمات تقييم عقاري بالمملكة العربية السعودية</p>
                <p dir="rtl">
                  مقدم إلى السيد/
                  <span dir="rtl">{data.client.client_name}</span>
                </p>
                <p dir="rtl">
                  رقم عرض السعر/
                  <span dir="rtl">{offer_id}</span>
                </p>
              </div>
            </div>
            <Link to={url} id="link">
              <img src="" alt="" ref={QRCode_imgRef} />
            </Link>

            <canvas ref={canvasRef}></canvas>
          </section>
          <section id="print">
            <div className="img">
              <img
                src="https://res.cloudinary.com/dzi9hg6em/image/upload/v1716209592/bg_2_nigsvt.png"
                alt=""
              />
            </div>
            <div className="content">
              <div className="hero">
                <h1 dir="rtl">مـقــدمـــــة :</h1>
                <p dir="rtl">
                  بـــدايــة نود أن نشكر سعادتكم وإدارتكم الموقرة على ثقتها
                  بإختياركم شركة لوكيشن للتقييم العقاري للتعــاون معكم في
                  مجـــال التقييــم العقـــاري حيث ؛ أن قسم التقييم العقاري
                  يقوم باتباع معايير التقييم الدولية (International Valuation
                  Standards) IVS ونلتـــزم بدستـور أخلاقيات المهنــة . Code Of
                  Ethics)) وننفذ تقارير التقييم العقارية حسب أساليب علميـــة
                  سليمــــة بالالتــزام مع (Uniform Standers Of Professional)
                  USPAP (Appraisal Practice) ولدينا فريق عمل أعضاء مسجلين في
                  (الهيئة السعودية للمقيمين المعتمدين) و ( RICS ) (المعهد الملكي
                  للمساحين القانونيين) ولديهم خبرة في السوق السعودي لضمان أعلى
                  المعايير المهنية والأخلاقية .{" "}
                </p>
              </div>
            </div>
          </section>
          <section id="print">
            <div className="img">
              <img
                src="https://res.cloudinary.com/dzi9hg6em/image/upload/v1716210188/%D9%86%D9%85%D9%88%D8%B0%D8%AC_%D8%B9%D8%B1%D8%B6_%D8%B3%D8%B9%D8%B1_%D8%B4%D9%86%D8%A7%D9%86005_olu3h3.png"
                alt=""
              />
            </div>
            <div className="content">
              <p dir="rtl">
                حيث يرغب الأستاذ /<span dir="rtl">{""}</span>
              </p>
              <p dir="rtl">في تقييم أصول عقاريه ، وهي مفصلة كالتالي: </p>
              <table>
                <thead>
                  <tr>
                    <th dir="rtl">المساحة</th>
                    <th dir="rtl">الموقع </th>
                    <th dir="rtl">رقم الصك</th>
                    <th dir="rtl">نوع الاصل</th>
                    <th dir="rtl">م</th>
                  </tr>
                </thead>
                <tbody className="show_tbody"></tbody>
              </table>
              <table dir="rtl">
                <thead>
                  <tr>
                    <th dir="rtl">نوع القيمة</th>
                    <th dir="rtl">تاريخ نفاذ القيمة</th>
                    <th dir="rtl">الغرض من التقييم </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td dir="rtl">{select_value_type}</td>
                    <td dir="rtl">{""}</td>
                    <td dir="rtl">{select_purpose}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section id="print">
            <div className="img">
              <img
                src="https://res.cloudinary.com/dzi9hg6em/image/upload/v1716210188/%D9%86%D9%85%D9%88%D8%B0%D8%AC_%D8%B9%D8%B1%D8%B6_%D8%B3%D8%B9%D8%B1_%D8%B4%D9%86%D8%A7%D9%86005_olu3h3.png"
                alt=""
              />
            </div>
            <div className="content">
              <p dir="rtl"> أتعاب ومراحل التقييم :</p>
              <table dir="rtl">
                <thead>
                  <tr>
                    <th dir="rtl">اتعاب التقييم</th>
                    <th dir="rtl">قيمة الضريبة</th>
                    <th dir="rtl">الإجمالي </th>
                    <th dir="rtl">مدة العمل </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td dir="rtl">{data.results.totalCost}</td>
                    <td dir="rtl">{data.results.taxes}</td>
                    <td dir="rtl">{data.results.netCost}</td>
                    <td dir="rtl">{data.work_period} ايام</td>
                  </tr>
                </tbody>
              </table>
              <p dir="rtl">ثانياً / مراحل التقييم : </p>

              <table>
                <thead id="evaluation_thead">
                  <tr>
                    <th dir="rtl">عدد الايام</th>
                    <th dir="rtl">النشاط</th>
                  </tr>
                </thead>
                <tbody id="evaluation_tbody"></tbody>
              </table>
            </div>
          </section>
          <section id="print">
            <div className="img">
              <img
                src="https://res.cloudinary.com/dzi9hg6em/image/upload/v1716210188/%D9%86%D9%85%D9%88%D8%B0%D8%AC_%D8%B9%D8%B1%D8%B6_%D8%B3%D8%B9%D8%B1_%D8%B4%D9%86%D8%A7%D9%86005_olu3h3.png"
                alt=""
              />
            </div>
            <div className="content">
              <p dir="rtl">طريقة الدفع :</p>
              <ol className="ol_show" dir="rtl"></ol>
            </div>
          </section>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}
export default Prices;
