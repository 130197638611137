import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { IoIosAddCircleOutline, IoIosWarning } from "react-icons/io";
import { gsap } from "gsap";
import "../../style/evaluator/CostEvaluation.css";
const dev_value = new Map();
const ResidualValueBuilding = () => {
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  const RefForm = useRef(null);
  const RefAlert = useRef(null);
  const [state, setState] = useState(false);
  const [type, settype] = useState("");
  const prop_info = useRef(null);

  const [ob, setob] = useState({
    comm_space: "",
    comm_price: "",
    offece_price: "",
    resid_price: "",
    land_space: "",
    building_percent: "",
    management_percent: "",
    investment: "",
    investment_year: "",
    fix: "",
    div: "",
    ava_p: "",
    invest_return: "",
    factor: "",
  });
  useEffect(() => {
    if (state == false) {
      gsap.fromTo(
        RefAlert.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5 }
      );
      gsap.fromTo(
        RefForm.current,
        { opacity: 0, y: 10 },
        { y: 0, opacity: 1, duration: 0.5, delay: 0.5 }
      );
      // setState(true);
    } else {
      gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
      gsap.to(RefAlert.current, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.5,
      });
    }
  }, [state]);
  function edit() {
    var tl = gsap.timeline({});
    tl.fromTo(
      RefAlert.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.5 }
    ).fromTo(
      RefForm.current,
      { opacity: 0, y: 10 },
      { y: 0, opacity: 1, pointerEvents: "all", duration: 0.5 }
    );
  }
  function fadeOut() {
    var x;
    if (x) {
      showMessage("يجب تحديد النوع", true);
    } else {
      gsap.to(RefForm.current, {
        y: -10,
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
      });
      gsap.to(RefAlert.current, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.5,
      });
    }
    calc();
  }
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  function calc() {
    var buildingcost =
      (Number(ob.building_percent) / 100).toFixed(2) * Number(ob.land_space);
    document.querySelector(".building-cost").textContent =
      numberWithCommas(buildingcost);
      // ! **************
    var total_building_cost = document
      .querySelector(".total-building-cost")
      .textContent.split(",");
    total_building_cost = Number(total_building_cost.join(""));

    var management_percent =
      total_building_cost * (Number(ob.management_percent) / 100).toFixed(2);
    document.querySelector("#management_percent").textContent =
      numberWithCommas(management_percent);
    var dev = (Number(ob.div) / 100).toFixed(2) * total_building_cost;
    document.querySelector("#dev").textContent = numberWithCommas(dev);
    var investment =
      Number(ob.investment_year) *
      (Number(ob.investment) / 100).toFixed(2) *
      total_building_cost;
    document.querySelector("#investment").textContent =
      numberWithCommas(investment);
    var prev = 0;
    document.querySelectorAll("#new3").forEach((value) => {
      if (value) {
        value = value.textContent.split(",");
        prev += Number(value.join(""));
      }
    });
    var tbody3_sum =
      investment + dev + management_percent + prev + total_building_cost;
    document.querySelector(".total_3").textContent =
      numberWithCommas(tbody3_sum);
    // ! ************
    var total_2 = document.querySelector(".total_2").textContent.split(",");
    total_2 = Number(total_2.join(""));
    var annual_fix = (total_2 * (Number(ob.fix) / 100).toFixed(2)).toFixed(0);
    document.querySelector("#annual_fix").textContent =
      numberWithCommas(annual_fix);
    var annual_fix_after = total_2 - annual_fix;
    document.querySelector("#annual_fix_after").textContent =
      numberWithCommas(annual_fix_after);
    var ava_p = (Number(ob.ava_p) / 100).toFixed(3) * annual_fix_after;
    document.querySelector("#ava_p").textContent = numberWithCommas(ava_p);
    var net_income = annual_fix_after - ava_p;
    document.querySelector("#net_income").textContent =
      numberWithCommas(net_income);
    var investment_value = (
      net_income / (Number(ob.invest_return) / 100).toFixed(2)
    ).toFixed(0);
    document.querySelector("#investment_value").textContent =
      numberWithCommas(investment_value);
    // ! ******
    var net_land = investment_value - tbody3_sum;
    document.querySelector("#net_land").textContent =
      numberWithCommas(net_land);
    var land_price_today = (
      net_land * (Number(ob.factor) / 100).toFixed(2)
    ).toFixed(0);
    document.querySelector("#land_price_today").textContent =
      numberWithCommas(land_price_today);
    var squar_metar = (land_price_today / Number(ob.land_space)).toFixed(0);
    document.querySelector("#squar_metar").textContent =
      numberWithCommas(squar_metar);
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function add1() {
    var tl = gsap.timeline({});
    tl.to(prop_info.current, { display: "flex" }).to(prop_info.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
    });
  }
  function remove() {
    var tl = gsap.timeline({});
    tl.to(prop_info.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
    }).to(prop_info.current, { display: "none" });
  }
  function create() {
    if (type == "1") {
      const tbody = document.querySelector("#tbody-1");
      var tr = document.createElement("tr");
      var td = document.createElement("td");
      td.textContent = ob.feild_name;
      tr.appendChild(td);
      var td = document.createElement("td");
      var value = Number(ob.space) * Number(ob.price);
      td.textContent = numberWithCommas(value);
      td.id = "new1";
      tr.appendChild(td);
      tbody.insertBefore(tr, tbody.querySelector("#end"));
      var prev = tbody.querySelector(".sum").textContent.split(",");
     
      prev = Number(prev.join("")) + value ;
      document.querySelector(".sum").textContent = numberWithCommas(prev);
      setob({ ...ob, feild_name: "", price: "", space: "" });
      calc();
    } else if (type == "2") {
      const tbody = document.querySelector("#tbody-2");
      var tr = document.createElement("tr");
      var td = document.createElement("td");
      td.textContent = ob.feild_name;
      tr.appendChild(td);
      var td = document.createElement("td");
      if (ob.feild_name == "التجاري")
        var value = Number(ob.space) * Number(ob.comm_price);
      if (ob.feild_name == "المكتبي")
        var value = Number(ob.space) * Number(ob.offece_price);
      if (ob.feild_name == "السكني")
        var value = Number(ob.space) * Number(ob.resid_price);
      console.log(value);
      td.textContent = numberWithCommas(value);
      td.id = "new2";
      tr.appendChild(td);
      tbody.insertBefore(tr, tbody.querySelector("#end"));
      var prev = tbody.querySelector(".sum").textContent.split(",");
      prev = Number(prev.join("")) + value;
      tbody.querySelector(".sum").textContent = numberWithCommas(prev);
      setob({ ...ob, feild_name: "", space: "" });
      calc();
    } else if (type == "3") {
      var tbody = document.querySelector("#tbody-3");
      var tr = document.createElement("tr");
      var td = document.createElement("td");
      td.textContent = ob.feild_name;
      tr.appendChild(td);
      var td = document.createElement("td");
      var value = Number(ob.price);
      td.textContent = numberWithCommas(value);
      td.id = "new3";
      tr.appendChild(td);
      tbody.insertBefore(tr, tbody.querySelector("#end"));
      var prev = tbody.querySelector(".sum").textContent.split(",");
      prev = Number(prev.join("")) + value;
      tbody.querySelector(".sum").textContent = numberWithCommas(prev);
      setob({ ...ob, feild_name: "", price: "" });
      calc();
    }
  }
  // useEffect(() => {
  //   console.log(ob);
  // }, [ob]);
  const list_1 = (
    <datalist id="list-1">
      <option value="قبو"></option>
      <option value="ارضي"></option>
      <option value="متكرر"></option>
    </datalist>
  );
  const list_2 = (
    <datalist id="list-1">
      <option value="التجاري"></option>
      <option value="السكني"></option>
      <option value="المكتبي"></option>
    </datalist>
  );
  const input1 = (
    <label htmlFor="property_number">
      <p>المساحة</p>
      <input
        type="number"
        value={ob.space}
        onChange={(e) => {
          setob({ ...ob, space: e.target.value });
        }}
        name="property_number"
      />
    </label>
  );
  const input2 = (
    <label htmlFor="property_number">
      <p>سعر المتر</p>
      <input
        type="number"
        value={ob.price}
        onChange={(e) => {
          setob({ ...ob, price: e.target.value });
        }}
        name="property_number"
      />
    </label>
  );
  const input3 = (
    <label htmlFor="property_number">
      <p>القيمة</p>
      <input
        type="number"
        value={ob.price}
        onChange={(e) => {
          setob({ ...ob, price: e.target.value });
        }}
        name="property_number"
      />
    </label>
  );
  return (
    <form
      className="menuClose land_val"
      id="main_form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="alert" ref={RefAlert}>
        <div id="search" ref={RefForm}>
          <h1 dir="rtl">الافتراضات</h1>
          {/* <label htmlFor="">
            <p dir="rtl">المساحة التاجيرية</p>
            <input
              type="number"
              value={ob.comm_space}
              onChange={(e) => {
                var x = { ...ob, comm_space: e.target.value };
                setob(x);
              }}
            />
          </label> */}
          <label htmlFor="">
            <p dir="rtl">سعر التاجير التجاري</p>
            <input
              type="number"
              value={ob.comm_price}
              onChange={(e) => {
                setob({ ...ob, comm_price: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">سعر تاجير المكتبي </p>
            <input
              type="number"
              value={ob.offece_price}
              onChange={(e) => {
                setob({ ...ob, offece_price: e.target.value });
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">سعر تاجير السكني</p>
            <input
              type="number"
              value={ob.resid_price}
              onChange={(e) => {
                setob({ ...ob, resid_price: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">مساحة الارض</p>
            <input
              type="number"
              value={ob.land_space}
              onChange={(e) => {
                setob({ ...ob, land_space: e.target.value });
              }}
              name="how_to_calc_building"
            />
          </label>

          <label htmlFor="">
            <p dir="rtl">نسبة البناء</p>
            <input
              type="number"
              value={ob.building_percent}
              onChange={(e) => {
                setob({ ...ob, building_percent: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">نسبة رسوم فنية وإدارية</p>
            <input
              type="number"
              value={ob.management_percent}
              onChange={(e) => {
                setob({ ...ob, management_percent: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">نسبة التمويل</p>
            <input
              type="text"
              value={ob.investment}
              onChange={(e) => {
                setob({ ...ob, investment: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">سنوات التمويل</p>
            <input
              type="text"
              value={ob.investment_year}
              onChange={(e) => {
                setob({ ...ob, investment_year: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">نسبة الصيانة السنوية</p>
            <input
              type="text"
              value={ob.fix}
              onChange={(e) => {
                setob({ ...ob, fix: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl"> نسبة أرباح المطور </p>
            <input
              type="text"
              value={ob.div}
              onChange={(e) => {
                setob({ ...ob, div: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">نسبة الشواغر</p>
            <input
              type="text"
              value={ob.ava_p}
              onChange={(e) => {
                setob({ ...ob, ava_p: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">عائد الاستثمار </p>
            <input
              type="text"
              value={ob.invest_return}
              onChange={(e) => {
                setob({ ...ob, invest_return: e.target.value });
              }}
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">معامل خصم النقود </p>
            <input
              type="number"
              value={ob.factor}
              onChange={(e) => {
                setob({ ...ob, factor: e.target.value });
              }}
            />
          </label>
          <span>
            <button form="fake" onClick={fadeOut}>
              تحديد
            </button>
          </span>
        </div>
      </div>
      <div className="title">
        <h1>طريقة التقييم بالقيمة المتبقية</h1>
      </div>
      <article className="prop">
        <div className="sub_title">
          <div className="property_btn">
            <button
              form="fake"
              onClick={() => {
                settype((prev) => (prev = "1"));
                add1();
              }}
            >
              <p>اضافة تكاليف انشاء</p>
              <IoIosAddCircleOutline />
            </button>
            <button
              form="fake"
              onClick={() => {
                settype((prev) => (prev = "3"));
                add1();
              }}
            >
              <p>اضافة تكاليف اخرى</p>
              <IoIosAddCircleOutline />
            </button>
            <button
              form="fake"
              onClick={() => {
                settype((prev) => (prev = "2"));
                add1();
              }}
            >
              <p>اضافة دخل</p>
              <IoIosAddCircleOutline />
            </button>
            <button
              form="fake"
              onClick={() => {
                edit();
              }}
            >
              <p>الفرصيات</p>
              <IoIosAddCircleOutline />
            </button>
          </div>
        </div>
        <div className="input_prop" ref={prop_info}>
          <div className="title">
            <h1>اضف حقل </h1>
          </div>
          <div className="inputs-prop">
            <label htmlFor="f">
              <p>اسم الحقل</p>
              <input
                type="text"
                list="list-1"
                value={ob.feild_name}
                onChange={(e) => {
                  setob({ ...ob, feild_name: e.target.value });
                }}
                name="f"
              />
              {type == "1" && list_1}
              {type == "2" && list_2}
            </label>
            {type == "1" && input1}
            {type == "1" && input2}
            {type == "2" && input1}
            {type == "3" && input3}
          </div>
          <div className="props_actions">
            <button form="fake" id="save" onClick={create}>
              موافق
            </button>
            <button form="fake" id="cancel" onClick={remove}>
              انهاء
            </button>
          </div>
        </div>
        <div className="table">
          <table dir="rtl">
            <thead>
              <tr>
                <th colSpan={2}>الكتلة المبنية</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>اجمالي المسطحات المبنية</td>
                <td className="building-cost"></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>تكاليف الانشاء</th>
              </tr>
            </thead>
            <tbody id="tbody-1">
              <tr id="end">
                <td>التكلفة الاجمالية للانشاء</td>
                <td className="total-building-cost sum"></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>التكاليف الاخرى</th>
              </tr>
            </thead>
            <tbody id="tbody-3">
              <tr id="end">
                <td>رسوم فنية وإدارية</td>
                <td id="management_percent"></td>
              </tr>
              <tr>
                <td>
                  تكلفة التمويل ( {ob.investment_year} سنوات / %{ob.investment}{" "}
                  )
                </td>
                <td id="investment"></td>
              </tr>
              <tr>
                <td>أرباح المطور</td>
                <td id="dev"></td>
              </tr>
              <tr>
                <td>اجمالي التكاليف للمشروع</td>
                <td className="sum total_3"></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>بيانات الدخل الافتراضي للمشروع بعد التطوير</th>
              </tr>
            </thead>
            <tbody id="tbody-2">
              <tr id="end">
                <td>اجمالي الدخل لكامل المشروع</td>
                <td className="sum total_2"></td>
              </tr>
              <tr>
                <td>تكلفة الصيانة السنوية</td>
                <td id="annual_fix"></td>
              </tr>
              <tr>
                <td>صافي الدخل بعد احتساب نسبة الصيانه</td>
                <td id="annual_fix_after"></td>
              </tr>
              <tr>
                <td>نسبة الشواغر</td>
                <td id="ava_p"></td>
              </tr>
              <tr>
                <td>صافي الدخل بعد خصم الصيانة والاشغار</td>
                <td id="net_income"></td>
              </tr>
              <tr>
                <td>القيمة الاستثمارية </td>
                <td id="investment_value"></td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan={2}>قيمة الأرض</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>صافي قيمة الأرض بعد التطوير </td>
                <td id="net_land"></td>
              </tr>
              <tr>
                <td>قيمة الأرض بسعر اليوم</td>
                <td id="land_price_today"></td>
              </tr>
              <tr>
                <td>سعر المتر المربع</td>
                <td id="squar_metar"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
};

export default ResidualValueBuilding;
