// import {Link} from 'react-router-dom';
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "../style/Prices.css";
import "../style/contract.css";
import "../style/show.css";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import { useParams } from "react-router-dom";
import localforage from "localforage";

function Agent() {
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);

  return (
    <form className="menuClose" id="main_form">
      <div className="title">
        <h1>اضافة عميل جديد</h1>
      </div>
      <article data-active="true">
        <div className="sub-title">
          <h3>بيانات العميل</h3>
        </div>
        <div className="inputs_feild">
          <div className="right">
            <label htmlFor="agent_name">
              <p> اسم العميل</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>الأسم المستعار</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>عنوان العميل</p>
              <input type="text" />
            </label>
            <label htmlFor="company_email">
              <p>الأيميل</p>
              <input
                dir="rtl"
                type="gmail"
                value={email}
                onInput={(e) => {
                  setemail(e.target.value);
                }}
              />
            </label>
            <label htmlFor="company_phone">
              <p>رقم الهاتف </p>
              <input
                dir="rtl"
                type="tel"
                pattern="[0-9]"
                value={phone}
                onInput={(e) => {
                  setphone(e.target.value);
                }}
              />
            </label>
            <label htmlFor="company_phone">
              <p>الأرض</p>
              <input dir="rtl" type="text" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>المباني</p>
              <input dir="rtl" type="tel" pattern="[0-9]" />
            </label>
            <label htmlFor="company_phone">
              <p>.</p>
              <input dir="rtl" type="file" pattern="[0-9]" />
            </label>
            <label htmlFor="value_type">
              <p>النموزج</p>
              <select dir="rtl" name="value_type" id="value_type">
                <option value=""></option>
              </select>
            </label>
            <label htmlFor="value_type">
              <p>نوع العميل</p>
              <select dir="rtl" name="value_type" id="value_type">
                <option value=""></option>
              </select>
            </label>
            <label htmlFor="value_type">
              <p>نوع الختم</p>
              <select dir="rtl" name="value_type" id="value_type">
                <option value=""></option>
              </select>
            </label>
            <label htmlFor="">
              <p>عمولة المدخل ألارض</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>عمولة مدخل المباني</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>عمولة مشرف ألارض</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>عمولة مشرف المباني</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>عمولة مدير قسم ألارض</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <label htmlFor="">
              <p>عمولة مدير قسم المباني</p>
              <input dir="rtl" type="number" pattern="[0-9]" />
            </label>
            <div className="right bottom">
              <label htmlFor="type" id="checkbox-label">
                <p>هل تريد مراقبة العميل ؟</p>
                <input dir="rtl" type="checkbox" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>هل العميل متاح للدراسات ؟</p>
                <input dir="rtl" type="checkbox" />
              </label>
            </div>
          </div>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}
export default Agent;
