import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

const Signature = () => {
  const [signature, setSignature] = useState("");
  const [cleared, setCleared] = useState(false);
  const sigCanvas = useRef(null);

  const handleClear = () => {
    sigCanvas.current.clear();
    setCleared(true);
    setSignature("");
  };

  const handleEnd = (signatureData) => {
    setSignature(signatureData);
    setCleared(false);
  };

  const displaySignature = () => {
    if (!signature) return null;

    try {
      var imageUrl = sigCanvas.current.toDataURL("image/png");
      return <img src={imageUrl} alt="Signature" />;
    } catch (error) {
      console.error("Error decoding signature:", error);
      // Handle decode error gracefully (e.g., display message to user)
      return <p>Error displaying signature.</p>;
    }
  };

  return (
    <div className="signature-pad-container">
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{ width: 500, height: 200 }}
        onEnd={handleEnd}
      />
      <button onClick={handleClear} disabled={cleared}>
        Clear Signature
      </button>
      <div className="signature-display">{displaySignature()}</div>
    </div>
  );
};

export default Signature;
