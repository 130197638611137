import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { IoIosAddCircleOutline, IoIosWarning } from "react-icons/io";
import { gsap } from "gsap";
import "../../style/evaluator/CostEvaluation.css";
const CostEvaluation = () => {
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  const RefAlert = useRef(null);
  const RefForm = useRef(null);

  const prop_info = useRef(null);
  function addProps() {
    gsap.to(prop_info.current, { display: "flex" });
    gsap.to(prop_info.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
      delay: 0.2,
    });
  }
  function fadeOut2() {
    gsap.to(prop_info.current, {
      display: "none",
      opacity: 0,
      duration: 0.5,
    });
  }
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  const [count, setcount] = useState(1);
  const [Case, setCase] = useState("");
  const [sum, setsum] = useState(0);

  function addNewCase() {
    if (Case) {
      var tbody_1 = document.querySelector(".tbody_1");
      var tr = document.createElement("tr");
      tr.id = "features";
      tr.dataset.count = count;
      tr.classList.add("new_tr");
      var title_td = document.createElement("td");
      title_td.textContent = Case;
      title_td.dir = "rtl";
      tr.appendChild(title_td);
      var td_space = document.createElement("td");
      td_space.contentEditable = true;
      td_space.id = "space";
      td_space.dataset.count = count;
      td_space.dir = "ltr";
      td_space.dataset.type = false;
      td_space.addEventListener("input", () => {
        calc_all(td_space);
      });
      tr.appendChild(td_space);
      var td_price = document.createElement("td");
      td_price.contentEditable = true;
      td_price.id = "price";
      td_price.dataset.count = count;
      td_price.dir = "ltr";
      td_price.dataset.type = false;
      td_price.addEventListener("input", () => {
        calc_all(td_price);
      });
      tr.appendChild(td_price);
      var td_result = document.createElement("td");
      td_result.id = "result";
      td_result.dataset.count = count;
      tr.appendChild(td_result);
      var tbody_1_result_tr = document.querySelector("#tbody_1_result_tr");
      tbody_1.insertBefore(tr, tbody_1_result_tr);
      setcount(count + 1);
      setCase("");
      fadeOut2();
    } else {
      showMessage("لايمكن ترك اسم عامل التسوية فارغ", true);
    }
  }
  function calc_all(x) {
    try {
      var arr = [];
      var space = document.querySelector(
        `#space[data-count="${x.dataset.count}"]`
      );
      var space_val = is_number(space);
      var price = document.querySelector(
        `#price[data-count="${x.dataset.count}"]`
      );
      var price_val = is_number(price);
      var result = document.querySelector(
        `#result[data-count="${x.dataset.count}"]`
      );
      var tbody_1_result_tr = document.querySelector(".tbody_1_result_value");

      if (space_val == true && price_val == true) {
        var space_value = Number(space.textContent) * Number(price.textContent);
        result.textContent = space_value.toFixed(2);
        document.querySelectorAll(`#result`).forEach((result) => {
          var value = Number(result.textContent);
          arr.push(value);
        });
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
          sum = sum + arr[i];
        }
        setsum(sum);
        tbody_1_result_tr.textContent = numberWithCommas(sum.toFixed(2));
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    document.querySelector("#technical_taxes").textContent = "%";
    document.querySelector("#contractor_margen").textContent = "%";
  }, []);
  useEffect(() => {
    undirected_expencess();
  }, [sum]);

  function undirected_expencess() {
    var technical_taxes_val = is_number2(
      document.querySelector("#technical_taxes")
    );
    var contractor_margen_val = is_number2(
      document.querySelector("#contractor_margen")
    );
    var actual_life_val = is_number(document.querySelector("#actual_life"));
    var hypothetical_life = document.querySelector("#hypothetical_life");
    var hypothetical_life_val;
    if (hypothetical_life) {
      hypothetical_life_val = is_number(hypothetical_life);
    } else {
      hypothetical_life_val = true;
    }
    if (
      contractor_margen_val === true &&
      technical_taxes_val === true &&
      hypothetical_life_val === true &&
      actual_life_val === true
    ) {
      var technical_taxes = document.querySelector("#technical_taxes");
      var technical_taxes_value = document.querySelector(
        "#technical_taxes_value"
      );

      var contractor_margen = document.querySelector("#contractor_margen");
      var contractor_margen_value = document.querySelector(
        "#contractor_margen_value"
      );
      var total_taxes = document.querySelector("#total_taxes");
      var total_building_taxes = document.querySelector(
        "#total_building_taxes"
      );

      var degit = technical_taxes.textContent.split("%");
      var degit2 = contractor_margen.textContent.split("%");
      var x = (Number(degit[0]) / 100) * sum;
      var y = (Number(degit2[0]) / 100) * sum;
      technical_taxes_value.textContent = numberWithCommas(x.toFixed(2));
      contractor_margen_value.textContent = numberWithCommas(y.toFixed(2));
      var z = (x + y).toFixed(2);
      total_taxes.textContent = numberWithCommas(z);
      var w = Number(z) + Number(sum);
      total_building_taxes.textContent = numberWithCommas(w.toFixed(0));
      if (type == "القسط المتناقص") {
        var actual_life = Number(
          document.querySelector("#actual_life").textContent
        );
        var new_w = w;
        var value_;
        if (actual_life > 200)
          return showMessage("القيمة المدخلة غير مسموح بها", true);
        for (let i = 0; i < actual_life; i++) {
          value_ = (0.025 * new_w).toFixed(0);
          new_w = Math.abs(new_w - value_);
        }
        var dep_value = w - new_w;
        var depreciation_value = document.querySelector("#depreciation_value");
        depreciation_value.textContent = numberWithCommas(dep_value.toFixed(0));
        var value_after_depreciation = document.querySelector(
          "#value_after_depreciation"
        );
        var value_after_depreciation_value = new_w;
        value_after_depreciation.textContent = numberWithCommas(
          value_after_depreciation_value.toFixed(0)
        );
        document.querySelector("#true_building_value").textContent =
          numberWithCommas(value_after_depreciation_value.toFixed(0));
        var land_value = document.querySelector("#land_value");
        var num = land_value.textContent.split(",");
        var value = Number(num.join(""));
        var net_value = document.querySelector("#net_value");
        net_value.textContent = numberWithCommas(
          value + value_after_depreciation_value
        );
        console.log(value_after_depreciation_value);
      } else {
        var actual_life = Number(
          document.querySelector("#actual_life").textContent
        );
        var hypothetical_life = Number(
          document.querySelector("#hypothetical_life").textContent
        );
        var life = hypothetical_life - actual_life;
        var remaining_life = document.querySelector("#remaining_life");
        remaining_life.textContent = life;
        var depreciation_rate = document.querySelector("#depreciation_rate");
        if (hypothetical_life <= 0) {
          depreciation_rate.textContent = "0%";
        } else {
          var rate = ((actual_life / hypothetical_life) * 100).toFixed(0);
          depreciation_rate.textContent = rate + "%";
          var dep_value = (w * rate) / 100;
          var depreciation_value = document.querySelector(
            "#depreciation_value"
          );
          depreciation_value.textContent = numberWithCommas(
            dep_value.toFixed(0)
          );
          var value_after_depreciation = document.querySelector(
            "#value_after_depreciation"
          );
          var value_after_depreciation_value = w - dep_value;
          value_after_depreciation.textContent = numberWithCommas(
            value_after_depreciation_value.toFixed(0)
          );
          document.querySelector("#true_building_value").textContent =
            numberWithCommas(value_after_depreciation_value.toFixed(0));
          var land_value = document.querySelector("#land_value");
          var num = land_value.textContent.split(",");
          var value = Number(num.join(""));
          var net_value = document.querySelector("#net_value");
          net_value.textContent = numberWithCommas(
            value + value_after_depreciation_value
          );
        }
      }
    }
  }
  function is_number(value) {
    var number = value.textContent;
    if (Number.isFinite(Number(number))) {
      value.style = "background:transparent;";
      //   setActive(Math.random());
      return true;
    } else {
      value.style = "background:#fa6464;";
    }
  }
  function is_number2(x) {
    var num = x.textContent.split("%");
    num.pop();
    var number = num.join();
    if (Number.isFinite(Number(number))) {
      x.style = "background:transparent;";
      x.textContent = number + "%";
      return true;
    } else {
      x.style = "background:#fa6464;";
    }
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [state, setState] = useState(false);
  const [type, settype] = useState(null);

  useLayoutEffect(() => {
    if (state == false || type == null) {
      gsap.fromTo(
        RefAlert.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5 }
      );
      gsap.fromTo(
        RefForm.current,
        { opacity: 0, y: 10 },
        { y: 0, opacity: 1, duration: 0.5, delay: 0.5 }
      );
      setState(true);
    } else {
      gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
      gsap.to(RefAlert.current, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.5,
      });
    }
  }, []);
  function fadeOut() {
    if (type == null) {
      showMessage("يجب تحديد النوع", true);
    } else {
      setState(true);
      gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
      gsap.to(RefAlert.current, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.5,
      });
    }
  }
  return (
    <form
      className="menuClose land_val"
      id="main_form"
      onSubmit={(e) => {
        e.preventDefault();
        addNewCase();
      }}
    >
      <div className="alert" ref={RefAlert}>
        <div id="search" ref={RefForm}>
          <h1 dir="rtl"> طريقة حساب عمر المبنى</h1>
          <label htmlFor="">
            <p dir="rtl">القسط الثابت</p>
            <input
              id="radio"
              type="radio"
              value={"القسط الثابت"}
              onChange={(e) => {
                settype(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">العمر الممتد</p>
            <input
              id="radio"
              type="radio"
              value={"العمر الممتد"}
              onChange={(e) => {
                settype(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <label htmlFor="">
            <p dir="rtl">القسط المتناقص</p>
            <input
              id="radio"
              type="radio"
              value={"القسط المتناقص"}
              onChange={(e) => {
                settype(e.target.value);
              }}
              name="how_to_calc_building"
            />
          </label>
          <span>
            <button form="fake" onClick={fadeOut}>
              تحديد
            </button>
          </span>
        </div>
      </div>
      <div className="title">
        <h1>التقييم بطربقة التكلفة</h1>
      </div>
      <article className="prop">
        <div className="sub_title">
          <div className="property_btn">
            <button form="fake" onClick={addProps}>
              <p>اضافة بيان</p>
              <IoIosAddCircleOutline />
            </button>
          </div>
        </div>
        <div className="input_prop" ref={prop_info}>
          <div className="title">
            <h1>اضف بيان </h1>
          </div>
          <div className="inputs-prop">
            <label htmlFor="property_number">
              <p>اسم البيان</p>
              <input
                type="test"
                name="property_number"
                value={Case}
                onChange={(e) => setCase(e.target.value)}
              />
            </label>
          </div>
          <div className="props_actions">
            <button form="fake" id="save" onClick={addNewCase}>
              اضافة
            </button>
            <button form="fake" id="cancel" onClick={fadeOut2}>
              الغاء
            </button>
          </div>
        </div>
        <div className="table">
          <table dir="rtl">
            <thead className="thead_1">
              <tr>
                <th colSpan={4}>حساب التكاليف المباشرة</th>
              </tr>
              <tr>
                <th>البيان</th>
                <th>المساحة</th>
                <th>سعر المتر</th>
                <th>الإجمالي</th>
              </tr>
            </thead>
            <tbody className="tbody_1">
              <tr id="tbody_1_result_tr">
                <td colSpan={3}>اجمالي التكاليف المباشرة</td>
                <td dir="ltr" className="tbody_1_result_value"></td>
              </tr>
            </tbody>
            <thead className="thead_2">
              <tr>
                <th colSpan={4}>حساب التكاليف غير مباشر</th>
              </tr>
            </thead>
            <tbody className="tbody_2">
              <tr>
                <td colSpan={2}>رسوم فنيةوادارية</td>
                <td
                  contentEditable={true}
                  id="technical_taxes"
                  dir="ltr"
                  onInput={undirected_expencess}
                ></td>
                <td id="technical_taxes_value"></td>
              </tr>
              <tr>
                <td colSpan={2}>أرباح مطور</td>
                <td
                  contentEditable={true}
                  id="contractor_margen"
                  dir="ltr"
                  onInput={undirected_expencess}
                ></td>
                <td id="contractor_margen_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>الإجمالي</td>
                <td id="total_taxes"></td>
              </tr>
              <tr>
                <td colSpan={3}>اجمالي تكلفة البناء</td>
                <td id="total_building_taxes"></td>
              </tr>
            </tbody>
            <thead className="thead_3">
              <tr>
                <th colSpan={4}>حساب الاهلاك ( {type} ) </th>
              </tr>
            </thead>
            <tbody className="tbody_3">
              <tr>
                <td colSpan={3} dir="rtl">
                  عمر المبنى
                </td>
                <td
                  contentEditable={true}
                  onInput={undirected_expencess}
                  dir="ltr"
                  id="actual_life"
                ></td>
              </tr>
              {type === "القسط المتناقص" ? null : (
                <>
                  <tr>
                    <td colSpan={3}>العمر الافتراضي</td>
                    <td
                      contentEditable={true}
                      onInput={undirected_expencess}
                      dir="ltr"
                      id="hypothetical_life"
                    ></td>
                  </tr>
                  <tr>
                    <td colSpan={3}>العمر المتبقي</td>
                    <td dir="ltr" id="remaining_life"></td>
                  </tr>
                  <tr>
                    <td colSpan={3}>معدل الاهلاك</td>
                    <td dir="ltr" id="depreciation_rate"></td>
                  </tr>
                </>
              )}

              <tr>
                <td colSpan={3}>قيمة الاهلاك</td>
                <td dir="ltr" id="depreciation_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>قيمة المبنى بعد الاهلاك</td>
                <td dir="ltr" id="value_after_depreciation"></td>
              </tr>
            </tbody>
            <thead className="thead_4">
              <tr>
                <th colSpan={4}>
                  اجمالي قيمة العقار بطريقة الاحلال أسلوب التكلفة
                </th>
              </tr>
            </thead>
            <tbody className="tbody_4">
              <tr>
                <td colSpan={3}>قيمة الأرض</td>
                <td dir="ltr" id="land_value">
                  2,800,000
                </td>
              </tr>
              <tr>
                <td colSpan={3}>قيمة المباني</td>
                <td dir="ltr" id="true_building_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>اجمالي قيمة العقار</td>
                <td dir="ltr" id="net_value"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
};

export default CostEvaluation;
