import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { IoIosAddCircleOutline, IoIosWarning } from "react-icons/io";
import { gsap } from "gsap";
import "../../style/evaluator/CostEvaluation.css";
const dev_value = new Map();
const ResidualValue = () => {
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  const prop_info = useRef(null);
  function addProps() {
    gsap.to(prop_info.current, { display: "flex" });
    gsap.to(prop_info.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
      delay: 0.2,
    });
  }
  function fadeOut2() {
    gsap.to(prop_info.current, { display: "none", opacity: 0, duration: 0.5 });
  }
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  const [Case, setCase] = useState("");

  function addNewCase() {
    if (Case) {
      var tbody_2 = document.querySelector(".tbody_2");
      var tr = document.createElement("tr");
      var td = document.createElement("td");
      td.textContent = Case;
      td.colSpan = 3;
      tr.appendChild(td);
      var td = document.createElement("td");
      td.id = "input_value";
      td.dir = "ltr";
      td.contentEditable = true;
      td.addEventListener("input", () => {
        calc2();
      });
      tr.appendChild(td);
      var commercial_meter_price = document.querySelector(
        "#commercial_meter_price"
      );
      tbody_2.insertBefore(tr, commercial_meter_price);
      setCase("");
      fadeOut2();
    } else {
      showMessage("لايمكن ترك اسم عامل التسوية فارغ", true);
    }
  }
  function is_number(value) {
    var number = value.textContent;
    if (Number.isFinite(Number(number))) {
      value.style = "background:transparent;";
      //   setActive(Math.random());
      return true;
    } else {
      value.style = "background:#fa6464;";
    }
  }
  function is_number2(x) {
    var num = x.textContent.split("%");
    num.pop();
    var number = num.join();
    if (Number.isFinite(Number(number))) {
      x.style = "background:transparent;";
      x.textContent = number + "%";
      return true;
    } else {
      x.style = "background:#fa6464;";
    }
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [sum, set_sum] = useState(0);
  const [dev_value_, set_dev_value] = useState(0);
  function calc1(x) {
    var result = is_number(x);
    if (result === true) {
      dev_value.set(x.className, x.textContent);
      var currentSum;
      currentSum =
        (dev_value.get("land_space") ?? 0) * (dev_value.get("land_price") ?? 0);

      set_sum(dev_value.get("land_space") ?? 0);
      document.querySelector(".dev_value").textContent =
        numberWithCommas(currentSum);
      set_dev_value(currentSum);
    }
  }
  useEffect(() => {
    calc2();
  }, [sum]);

  function calc2() {
    var result = is_number2(document.querySelector(".services_percent"));
    var result2 = is_number2(document.querySelector(".pure_space"));
    var result3 = is_number2(document.querySelector(".commercial_space"));
    var result4 = is_number2(document.querySelector(".residential"));
    var result5 = is_number(document.querySelector(".schema_cost"));
    var result6 = is_number2(document.querySelector(".dev_profit"));
    var result7 = is_number2(document.querySelector(".bank_interest"));
    var result8 = is_number(document.querySelector(".dev_period"));
    var result9 = is_number2(document.querySelector(".subtract_percent"));
    if (
      result === true ||
      result2 === true ||
      result3 === true ||
      result4 === true ||
      result5 === true ||
      result6 === true ||
      result7 === true ||
      result8 === true ||
      result9 === true
    ) {
      var value = document
        .querySelector(".services_percent")
        .textContent.split("%");
      value.pop();
      value.join("");
      var result = (value / 100) * sum;
      document.querySelector(".services_value").textContent = numberWithCommas(
        Number(result).toFixed(0)
      );
      var pure_space_value = document
        .querySelector(".pure_space")
        .textContent.split("%");
      pure_space_value.pop();
      pure_space_value.join("");
      var pure_space_result = (pure_space_value / 100) * sum;
      document.querySelector(".pure_space_value").textContent =
        numberWithCommas(Number(pure_space_result).toFixed(0));
      if (pure_space_result == 0) {
        pure_space_result = 1;
      }
      var commercial_value = document
        .querySelector(".commercial_space")
        .textContent.split("%");
      commercial_value.pop();
      commercial_value = Number(commercial_value.join("")) / 100;
      var commercial_result = (
        Number(commercial_value) * Number(pure_space_result)
      ).toFixed(0);
      document.querySelector(".commercial_value").textContent =
        commercial_result;
      var residential_value = document
        .querySelector(".residential")
        .textContent.split("%");
      residential_value.pop();
      residential_value = Number(residential_value.join("")) / 100;

      var residential_result = (
        Number(residential_value) * Number(pure_space_result)
      ).toFixed(0);
      document.querySelector(".residential_value").textContent =
        residential_result;
      var arr = [];
      document.querySelectorAll("#input_value").forEach((input) => {
        if (is_number(input)) {
          arr.push(Number(input.textContent));
        }
      });

      var sum_input = arr.reduce((a, b) => a + b, 0);

      var commercial_meter = Number(
        document.querySelector(".commercial_meter_price").textContent
      );
      var commercial_meter_value = commercial_meter * commercial_result;
      document.querySelector(".commercial_meter_value").textContent =
        numberWithCommas(commercial_meter_value);

      var residential_meter = Number(
        document.querySelector(".residential_meter_price").textContent
      );
      var residential_meter_value = residential_meter * residential_result;
      document.querySelector(".residential_meter_value").textContent =
        numberWithCommas(residential_meter_value);
      var total_sales =
        sum_input + residential_meter_value + commercial_meter_value;

      document.querySelector(".total_sales").textContent =
        numberWithCommas(total_sales);
      if (total_sales == 0) {
        total_sales = 1;
      }
      var schema_cost = Number(
        document.querySelector(".schema_cost").textContent
      );
      var value1 = ((schema_cost / total_sales) * 100).toFixed(2);
      document.querySelector(".schema_cost_value").textContent = value1 + "%";
      var managenemt = Number(
        document.querySelector(".managenemt").textContent
      );
      var value2 = ((managenemt / total_sales) * 100).toFixed(2);
      document.querySelector(".managenemt_value").textContent = value2 + "%";
      var x = document.querySelector(".dev_profit").textContent.split("%");
      x.pop();
      var dev_profit = Number(x.join("")) / 100;
      var value3 = dev_profit * total_sales;
      document.querySelector(".dev_profit_value").textContent =
        numberWithCommas(value3);
      var interest_amount = Number(
        document.querySelector(".interest_amount").textContent
      );
      var x = document.querySelector(".bank_interest").textContent.split("%");
      x.pop();
      var bank_interest = Number(x.join("")) / 100;
    }
    var res = interest_amount * bank_interest;
    document.querySelector(".interest_value").textContent =
      numberWithCommas(res);
    var dev_period = Number(document.querySelector(".dev_period").textContent);
    set_time(dev_period);
    var res1 = res * dev_period;
    document.querySelector(".interest_value_3years").textContent =
      numberWithCommas(res1);
    var res2 = ((res1 / total_sales) * 100).toFixed(2);
    document.querySelector(".interest_benefits_value").textContent = res2 + "%";
    var total_sum =
      dev_value_ + schema_cost + managenemt + value3 + res1 + interest_amount;
    document.querySelector(".final_total").textContent =
      numberWithCommas(total_sum);
    var net_total = total_sales - total_sum;
    document.querySelector(".net_total").textContent =
      numberWithCommas(net_total);
    var subtract_percent = document
      .querySelector(".subtract_percent")
      .textContent.split("%");
    subtract_percent.pop();
    subtract_percent = Number(subtract_percent.join("")) / 100;
    var z = 1 / (1 + subtract_percent) ** dev_period;
    document.querySelector(".today_value").textContent = numberWithCommas(
      (z * net_total).toFixed(0)
    );
  }
  const [time, set_time] = useState(0);
  return (
    <form
      className="menuClose land_val"
      id="main_form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="title">
        <h1>التقييم بطربقة التكلفة</h1>
      </div>
      <article className="prop">
        <div className="sub_title">
          <div className="property_btn">
            <button form="fake" onClick={addProps}>
              <p>اضافة تكاليف</p>
              <IoIosAddCircleOutline />
            </button>
          </div>
        </div>
        <div className="input_prop" ref={prop_info}>
          <div className="title">
            <h1>اضف حقل </h1>
          </div>
          <div className="inputs-prop">
            <label htmlFor="property_number">
              <p>اسم الحقل</p>
              <input
                type="test"
                name="property_number"
                value={Case}
                onChange={(e) => setCase(e.target.value)}
              />
            </label>
          </div>
          <div className="props_actions">
            <button form="fake" id="save" onClick={addNewCase}>
              اضافة
            </button>
            <button form="fake" id="cancel" onClick={fadeOut2}>
              الغاء
            </button>
          </div>
        </div>
        <div className="table">
          <table dir="rtl">
            <thead className="thead_1">
              <tr>
                <th colSpan={4}> التكاليف</th>
              </tr>
              <tr>
                <th colSpan={3}></th>
                <th>الناتج</th>
              </tr>
            </thead>
            <tbody className="tbody_1">
              <tr id="land_space">
                <td colSpan={3}>مساحة الأرض</td>
                <td
                  dir="ltr"
                  className="land_space"
                  contentEditable="true"
                  onInput={(e) => {
                    calc1(e.target);
                  }}
                ></td>
              </tr>
              <tr id="land_price">
                <td colSpan={3}>سعر متر التطوير</td>
                <td
                  dir="ltr"
                  className="land_price"
                  contentEditable="true"
                  onInput={(e) => {
                    calc1(e.target);
                  }}
                ></td>
              </tr>
            </tbody>
            <thead className="thead_2">
              <tr id="dev_value">
                <th colSpan={3}>قيمة التطوير</th>
                <th dir="ltr" className="dev_value"></th>
              </tr>
              <tr>
                <th colSpan={2}></th>
                <th>القيمة</th>
                <th>الناتج</th>
              </tr>
            </thead>
            <tbody className="tbody_2">
              <tr>
                <td colSpan={2}> مساحة الخدمات</td>
                <td
                  className="services_percent"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
                <td className="services_value"></td>
              </tr>
              <tr>
                <td colSpan={2}>المساحة الصافية</td>
                <td
                  className="pure_space"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
                <td className="pure_space_value"></td>
              </tr>
              <tr>
                <td colSpan={2}>مساحة التجاري</td>
                <td
                  className="commercial_space"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
                <td className="commercial_value"></td>
              </tr>
              <tr>
                <td colSpan={2}>مساحة السكني</td>
                <td
                  className="residential"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
                <td className="residential_value"></td>
              </tr>
              <tr id="commercial_meter_price">
                <td colSpan={3}>سعر متر التجاري</td>
                <td
                  className="commercial_meter_price"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
              </tr>
              <tr>
                <td colSpan={3}>سعر متر السكني</td>
                <td
                  className="residential_meter_price"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
              </tr>
              <tr>
                <td colSpan={3}>مبيعات التجاري</td>
                <td className="commercial_meter_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>مبيعات السكني</td>
                <td className="residential_meter_value"></td>
              </tr>
            </tbody>
            <thead className="thead_3">
              <tr>
                <th colSpan={3}>اجمالي المبيعات</th>
                <th className="total_sales"></th>
              </tr>
              <tr>
                <th colSpan={4}>خصم التكاليف</th>
              </tr>
            </thead>
            <tbody className="tbody_3">
              <tr>
                <td colSpan={2}>تكلفة المخططات</td>
                <td
                  className="schema_cost"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                  colSpan={1}
                ></td>
                <td className="schema_cost_value" colSpan={1}></td>
              </tr>
              <tr>
                <td colSpan={2}>إدارة وتسويق</td>
                <td
                  className="managenemt"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
                <td className="managenemt_value"></td>
              </tr>
              <tr>
                <td colSpan={2}>أرباح المطور</td>
                <td
                  className="dev_profit"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
                <td className="dev_profit_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>مبلغ التمويل</td>
                <td
                  className="interest_amount"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
              </tr>
              <tr>
                <td colSpan={3}>مدة التطوير/سنه</td>
                <td
                  className="dev_period"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
              </tr>

              <tr>
                <td colSpan={3}>نسبة الفائده للبنك</td>
                <td
                  className="bank_interest"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
              </tr>
              <tr>
                <td colSpan={3}>قيمة الفائده للسنه</td>
                <td className="interest_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>اجمالي الفوائد خلال {time} سنوات</td>
                <td className="interest_value_3years"></td>
              </tr>
              <tr>
                <td colSpan={3}>فوائد التمويل</td>
                <td className="interest_benefits_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>اجمالي التكاليف</td>
                <td className="final_total"></td>
              </tr>
              <tr>
                <td colSpan={3}>الصافي</td>
                <td className="net_total"></td>
              </tr>
              <tr>
                <td colSpan={3}>معدل الخصم</td>
                <td
                  className="subtract_percent"
                  contentEditable="true"
                  dir="ltr"
                  onInput={() => {
                    calc2();
                  }}
                ></td>
              </tr>
              <tr>
                <td colSpan={3}>حساب قيمة الأرض اليوم </td>
                <td className="today_value"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
};

export default ResidualValue;
