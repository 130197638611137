import { Link, useSearchParams } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import React, { useLayoutEffect, useRef, useEffect } from "react";
import { gsap } from "gsap";
import "../style/header.css";
import { RiMenu4Line } from "react-icons/ri";
import { IoPerson } from "react-icons/io5";
import { RiLogoutBoxFill } from "react-icons/ri";
function Header() {
  const [search, setsearch] = useSearchParams({ nav: false });
  const nav = search.get("nav") === "true";
  var tl = gsap.timeline({});
  const more = useRef(null);
  const menu = useRef(null);
  let show = false;
  function showMore() {
    if (show === false) {
      gsap.to(more.current, {
        y: -5,
        opacity: 0,
        pointerEvents: "none",
        duration: 0.3,
      });
      show = true;
    } else {
      gsap.to(more.current, {
        y: 0,
        opacity: 1,
        pointerEvents: "all",
        duration: 0.3,
      });
      show = false;
    }
  }

  return (
    <header>
      <div className="left">
        <div className="info">
          <span onClick={showMore}>
            <FaAngleDown />
          </span>
          <p>حسام حمزة</p>
          <div className="img">
            <IoPersonCircleOutline />
            {/* <img src="" alt="" /> */}
          </div>
          <div className="pluse" ref={more}>
            <ul>
              <li>
                <Link to={"/"}>
                  <p>ادارة حسابي</p>
                  <span>
                    <IoPerson />
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  <p>خروج من النظام</p>
                  <span>
                    <RiLogoutBoxFill />
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="menu"
        ref={menu}
        onClick={() => {
          if (nav === true && search.get("data") == "true") {
            setsearch(
              (prev) => {
                search.set("nav", "false");
                return prev;
              },
              { replace: true }
            );

            tl.to("#first_ul", {
              display: "block",
              opacity: 0,
              duration: 0,
            })
              .to("#first_ul", {
                opacity: 1,
                pointerEvents: "all",
                duration: 0,
              })
              .to("#sec_ul", {
                opacity: 0,
                pointerEvents: "none",
                duration: 0,
              })
              .to("#sec_ul", {
                display: "none",
                duration: 0,
              });
          } else if (nav === false && search.get("data") == "true") {
            setsearch(
              (prev) => {
                search.set("nav", "true");
                return prev;
              },
              { replace: true }
            );
            tl.to("#first_ul", {
              opacity: 0,
              pointerEvents: "none",
              duration: 0,
            })
              .to("#first_ul", {
                display: "none",
                duration: 0,
              })
              .to("#sec_ul", {
                opacity: 0,
                display: "block",
                duration: 0,
              })
              .to("#sec_ul", {
                opacity: 1,
                pointerEvents: "all",
                duration: 0,
              });
          }
        }}
      >
        <RiMenu4Line />
      </div>
    </header>
  );
}

export default Header;
