import "./style/Prices.css";
import "./style/contract.css";
import "./style/show.css";
import "./style/evaluator/land_value.css";

import Nav from "./components/Nav";

import Container  from './pages/container';
import { BrowserRouter, Route, Routes } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <Container />
      <Nav />
    </BrowserRouter>
  );
}

export default App;
