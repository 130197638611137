// import {Link} from 'react-router-dom';
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "../style/offer_view.css";
import { gsap } from "gsap";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import QRCode from "qrcode";
import { IoPersonCircleOutline } from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";

function View_offer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const item_id = searchParams.get("item");
  const typeRef1 = useRef(null);
  const typeRef2 = useRef(null);
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  const [res, set_res] = useState({});
  const [stage, set_stage] = useState([]);
  const [payment, set_payment] = useState([]);
  const [props, set_props] = useState([]);

  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }

  const handelOnclick = (type) => {
    var no_nav = type.split("-")[1];
    var arr = [];
    document.querySelectorAll("article").forEach((article) => {
      arr.push(article.id);
    });
    var ar_type = type.split("");
    ar_type.pop();
    ar_type = ar_type.join("");

    for (let i = 0; i < arr.length - 1; i++) {
      document.querySelectorAll("article[data-active=true]").forEach((item) => {
        item.dataset.active = false;
      });
      document.querySelector(`#s-${no_nav}`).dataset.active = true;
    }
  };
  useLayoutEffect(() => {
    var count = 1;
    document.querySelectorAll(".part").forEach((part) => {
      part.dataset.type = `s-` + count + `-`;
      count++;
    });
    var count_article = 1;
    document.querySelectorAll("article").forEach((article) => {
      if (count_article === 1) {
        article.dataset.active = true;
      } else {
        article.dataset.active = false;
      }
      article.id = `s-` + count_article;
      count_article++;
    });
  }, []);
  useEffect(() => {
    get_info();
  }, []);
  async function get_info() {
    try {
      var tl = gsap.timeline({});
      tl.to(".fix", { opacity: 1, pointerEvents: "all" });
      const data = {
        id: item_id,
      };
      const response = await axios.post(
        "https://auctionbackend.netlify.app/.netlify/functions/server/get/view_offer",
        data
      );
      if (response.data.status === true) {
        set_res(response.data.result);
        set_stage(Object.values(response.data.result.stage));
        set_payment(Object.values(response.data.result.payment));
        set_props(Object.values(response.data.result.props));
      } else {
        showMessage(response.data.message, true);
      }
    } catch (error) {
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }

  return (
    <form className="menuClose" id="main_form">
      <div className="navigation">
        <div
          className="part"
          ref={typeRef1}
          onClick={() => handelOnclick(typeRef1.current.dataset.type)}
        >
          البيانات
        </div>
        <div
          className="part"
          ref={typeRef2}
          onClick={() => handelOnclick(typeRef2.current.dataset.type)}
        >
          محل التقييم
        </div>
      </div>
      <article>
        <div className="main_info">
          <div id="title">
            <span>{res.date ? res.date.split("T")[0] : ""}</span>
            <span>{res.state == true ? "منجز" : "معلق"}</span>
            <h2>المعلومات الشخصية</h2>
          </div>
          <div className="box">
            <div className="personal">
              <p id="name" dir="rtl">
                الاسم : {res.client ? res.client.client_name : ""}
              </p>

              <Link
                to={`tel:${res.client ? res.client.phone_number : ""}`}
                id="phone"
                dir="rtl"
              >
                رقم الهاتف : {res.client ? res.client.phone_number : ""}
              </Link>
              <p id="email" dir="rtl">
                البديد الالكتروني : {res.client ? res.client.email : ""}
              </p>
            </div>
            <span>
              <IoPersonCircleOutline />
            </span>
          </div>
          <div className="details">
            <div className="label">
              <p id="type">علاقة العميل</p>
              <p id="value">{res.relation ? res.relation : ""}</p>
            </div>
            <div className="label">
              <p id="type">الغرض من التقيم</p>
              <p id="value">{res.Purpose ? res.Purpose : ""}</p>
            </div>
            <div className="label">
              <p id="type">نوع القيمة</p>
              <p id="value">{res.value_type ? res.value_type : ""}</p>
            </div>
            <div className="label">
              <p id="type">مدة العمل</p>
              <p id="value">{res.work_period ? res.work_period : ""}</p>
            </div>
          </div>
        </div>
        <section>
          <div className="table">
            <h1>الدفعات</h1>
            <table>
              <thead>
                <tr>
                  <th>القيمة</th>
                  <th>العنوان</th>
                  <th>no</th>
                </tr>
              </thead>
              <tbody>
                {res.payment ? (
                  payment.map((d, index) => (
                    <tr key={index}>
                      <td>{d.amount}</td>
                      <td>{d.text}</td>
                      <td>{index + 1}</td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="table">
            <h1>المراحل</h1>
            <table>
              <thead>
                <tr>
                  <th>اليام العمل</th>
                  <th>العملية</th>
                  <th>no</th>
                </tr>
              </thead>
              <tbody>
                {res.stage ? (
                  stage.map((d, index) => (
                    <tr key={index}>
                      <td>{d.days}</td>
                      <td>{d.task}</td>
                      <td>{index + 1}</td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </article>
      <article>
        <div className="table">
          <h1>الاصل</h1>
          <table>
            <thead>
              <tr>
                <th>اتعاب التقييم</th>
                <th>المساحة</th>
                <th>موقع الاصل</th>
                <th>رقم الصك</th>
                <th>نوع الاصل</th>
                <th>no</th>
              </tr>
            </thead>
            <tbody>
              {res.props ? (
                props.map((d, index) => (
                  <tr key={index}>
                    <td>{d.Evaluation_fees}</td>
                    <td>{d.space}</td>
                    <td>{d.location}</td>
                    <td>{d.Instrument_number}</td>
                    <td>{d.type}</td>
                    <td>{index + 1}</td>
                  </tr>
                ))
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="total">
          <span>
            <h3 dir="rtl">المبلغ كتابة</h3>
            <span>{res.results ? res.results.total_cost_AR : ""}</span>
          </span>
          <span>
            <h3 dir="rtl">الاجمالي</h3>
            <span>{res.results ? res.results.Total_evaluation_fees : ""}</span>
          </span>
          <span>
            <h3 dir="rtl">قيمة الضريبة</h3>
            <span>{res.results ? res.results.taxes : ""}</span>
          </span>
          <span>
            <h3 dir="rtl">اتعاب التقييم</h3>
            <span>{res.results ? res.results.total_cost : ""}</span>
          </span>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}
export default View_offer;
