// import {Link} from 'react-router-dom';
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "../style/Prices.css";
import "../style/contract.css";
import "../style/show.css";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import { useParams } from "react-router-dom";
import localforage from "localforage";
import SignatureCanvas from "react-signature-canvas";

function Report() {
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  return (
    <form className="menuClose" id="main_form">
      <div className="title">
        <h1> تقرير جديد</h1>
      </div>
      <article data-active="true">
        <div className="sub-title">
          <h3>معلومات التقرير</h3>
        </div>
        <div className="inputs_feild">
          <div className="right">
            <label htmlFor="agent_name">
              <p>عنوان التقرير</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>الغرض من التقييم</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>فرضية القيمة</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>اساس القيمة</p>
              <input type="text" />
            </label>
            <div className="right">
              <div className="sub-title" dir="rtl">
                <h3>نوع التقرير</h3>
              </div>
              <label htmlFor="type" id="checkbox-label">
                <p>تقرير مفصل</p>
                <input dir="rtl" type="radio" name="report-type" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>ملخص التقرير</p>
                <input dir="rtl" type="radio" name="report-type" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>مراجعة مع مقيم جديد</p>
                <input dir="rtl" type="radio" name="report-type" />
              </label>
              <label htmlFor="type" id="checkbox-label">
                <p>مراجعة بدون قيمة جديدة</p>
                <input dir="rtl" type="radio" name="report-type" />
              </label>
            </div>
            <label htmlFor="agent_name">
              <p>تاريخ القيمة</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>تاريخ اصدار التقرير</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>الافتراضات</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>الافتراضات الخاصة</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>الرآي النهائي في القيمة</p>
              <input type="text" />
            </label>
            <label htmlFor="agent_name">
              <p>عملة التقييم</p>
              <input type="text" value={"ريال سعودي"} />
            </label>
            <label htmlFor="agent_name">
              <p>ملف اصل التقرير</p>
              <input type="file" dir="rtl" />
            </label>
            <div className="right">
              <div className="sub-title" dir="rtl">
                <h3>بيانات العميل</h3>
              </div>
              <label htmlFor="agent_name">
                <p>ايم العميل</p>
                <input type="text" />
              </label>
              <label htmlFor="agent_name">
                <p>رقم الهاتف</p>
                <input type="tel" />
              </label>

              <label htmlFor="agent_name">
                <p>البريد الالكتروني</p>
                <input type="email" />
              </label>
              <div className="right">
                <label htmlFor="type" id="checkbox-label">
                  <p>للتقرير مستخدمون اخرون</p>
                  <input dir="rtl" type="checkbox" />
                </label>
              </div>
              <div className="sub-title" dir="rtl">
                <h3>المستخدمون الاخرون للتقرير</h3>
              </div>
              <div className="right">
                <label htmlFor="agent_name">
                  <p>اسم مستخدم التقرير</p>
                  <input type="text" />
                </label>
              </div>
              <div className="right">
                <div className="sub-title" dir="rtl">
                  <h3>بيانات المقيمين</h3>
                </div>
                <label htmlFor="agent_name">
                  <p>اسم المقييم</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>نسبة المساهمة</p>
                  <input type="text" />
                </label>
              </div>
              <div className="right">
                <div className="sub-title" dir="rtl">
                  <h3>اضافة اصل</h3>
                </div>
                <label htmlFor="agent_name">
                  <p>نوع الاصل محل التقييم</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>استخدام/قطاع الاصل محل التقييم</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>تاريخ معاينة الاصل</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>الرآي الهائي في القيمة</p>
                  <input type="text" />
                </label>
              </div>
              <div className="right">
                <div className="sub-title" dir="rtl">
                  <h3>معلومات اسلوب التقييم</h3>
                </div>
                <label htmlFor="agent_name">
                  <p>اسلوب السوق</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>اسلوب الدخل</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>اسلوب التكلفة</p>
                  <input type="text" />
                </label>
              </div>
              <div className="right">
                <div className="sub-title" dir="rtl">
                  <h3>معلومات الموقع</h3>
                </div>
                <label htmlFor="agent_name">
                  <p>رقم المربع</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>رقم المبنى</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>رقم الصك</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>نوع الملكية</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>الواجهات المطلة علي الشارع</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>المسافة عم وسط المدينة / كم</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>المرفق</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>مساحة الارص م٢</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>مساحة مسطحات البناء م٢</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>مساحة البناء المصرح بها / نسبة مئوية</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>عدد الاوار المصرح بها</p>
                  <input type="text" />
                </label>
              </div>
              <div className="right">
                <div className="sub-title" dir="rtl">
                  <h3>الارض تحت المبنى مستاجرة؟</h3>
                </div>
                <label htmlFor="type" id="checkbox-label">
                    <p>نعم</p>
                  <input dir="rtl" type="radio" name="isitranted" />
                </label>
                <label htmlFor="type" id="checkbox-label">
                    <p>لا</p>
                  <input dir="rtl" type="radio" name="isitranted" />
                </label>
              </div>
              <div className="right">
                <label htmlFor="agent_name">
                  <p>حالة البناء</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>حالة التشطيب</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>حالة التاثيث</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>التكييف</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>نوع المبنى</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>مميزات اضافية</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>عرض الشارع</p>
                  <input type="text" />
                </label>
                <label htmlFor="agent_name">
                  <p>عمر الاصل محل التقييم</p>
                  <input type="text" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}
export default Report;
