import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { IoIosAddCircleOutline, IoIosWarning } from "react-icons/io";

import { gsap } from "gsap";
const LandValue = () => {
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);

  const prop_info = useRef(null);
  function addProps() {
    gsap.to(prop_info.current, { display: "flex" });
    gsap.to(prop_info.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
      delay: 0.2,
    });
  }
  function fadeOut2() {
    gsap.to(prop_info.current, { display: "none", opacity: 0, duration: 0.5 });
  }
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  const [count, setcount] = useState(1);
  function createTableRow() {
    const theadـrow = document.querySelector(".theadـrow");
    const value_and_ev = document.querySelector(".value_and_ev");
    const price = document.querySelector(".price");
    const terms = document.querySelector(".terms");
    const market_status = document.querySelector(".market_status");
    const settlement_acc = document.querySelector(".settlement_acc");
    const settlement_value = document.querySelector(".settlement_value");
    const settlement_1st = document.querySelector(".settlement_1st");
    const space = document.querySelector(".space");
    const show_status = document.querySelector(".show_status");
    const use = document.querySelector(".use");
    const streets_no = document.querySelector(".streets_no");
    const ease_of_reatch = document.querySelector(".ease_of_reatch");
    const settlement_total = document.querySelector(".settlement_total");
    const settlements_value = document.querySelector(".settlements_value");
    const metar_value_after = document.querySelector(".metar_value_after");
    const evening_the_value = document.querySelector(".evening_the_value");
    const avarage_metar_value = document.querySelector(".avarage_metar_value");
    const total_land_value = document.querySelector(".total_land_value");

    // ? ******************
    var prop_to_compair = document.createElement("th");
    prop_to_compair.colSpan = 2;
    prop_to_compair.textContent = `المقارن${count}`;
    prop_to_compair.dir = "rtl";
    prop_to_compair.id = "new_th";
    theadـrow.appendChild(prop_to_compair);
    // ? ******************
    var td_value = document.createElement("td");
    td_value.textContent = "القيمة";
    value_and_ev.appendChild(td_value);
    var td_ev = document.createElement("td");
    td_ev.textContent = "التسوية";
    value_and_ev.appendChild(td_ev);
    // ? ******************
    var td_price = document.createElement("td");
    td_price.contentEditable = true;
    td_price.id = "price";
    td_price.dataset.count = count;
    td_price.dir = "ltr";
    td_price.colSpan = 2;
    td_price.addEventListener("input", () => {
      get_main_feature_percent(td_price);
    });
    price.appendChild(td_price);
    // ? ******************
    var td_market_status_select = document.createElement("td");
    var select = document.createElement("select");
    var option_arr = ["ممتازة", "جيدة", "سيئة"];
    for (let i = 0; i < 3; i++) {
      var option = document.createElement("option");
      option.value = option_arr[i];
      option.textContent = option_arr[i];
      select.appendChild(option);
    }
    select.id = "tabel_select";
    td_market_status_select.appendChild(select);
    market_status.appendChild(td_market_status_select);
    var td_market_status_value = document.createElement("td");
    td_market_status_value.id = "main_feature";
    td_market_status_value.contentEditable = true;
    td_market_status_value.dir = "ltr";
    td_market_status_value.textContent = "%";
    td_market_status_value.dataset.count = count;
    td_market_status_value.dataset.type = true;
    td_market_status_value.addEventListener("input", () => {
      get_main_feature_percent(td_market_status_value);
    });
    market_status.appendChild(td_market_status_value);
    // ? ******************
    var td_terms = document.createElement("td");
    td_terms.contentEditable = true;
    td_terms.textContent = "لايوجد";
    td_terms.id = "des";
    terms.appendChild(td_terms);
    var td_terms_value = document.createElement("td");
    td_terms_value.id = "main_feature";
    td_terms_value.contentEditable = true;
    td_terms_value.dir = "ltr";
    td_terms_value.textContent = "%";
    td_terms_value.dataset.count = count;
    td_terms_value.addEventListener("input", () => {
      get_main_feature_percent(td_terms_value);
    });
    terms.appendChild(td_terms_value);
    // ? ******************
    var td_settlement_acc = document.createElement("td");
    td_settlement_acc.colSpan = 2;
    td_settlement_acc.dir = "ltr";
    td_settlement_acc.id = `settlement_acc${count}`;
    settlement_acc.appendChild(td_settlement_acc);
    // ? ******************
    var td_settlement_value = document.createElement("td");
    td_settlement_value.colSpan = 2;
    td_settlement_value.dir = "ltr";
    td_settlement_value.id = `settlement_value${count}`;
    td_settlement_value.dataset.count = count;
    settlement_value.appendChild(td_settlement_value);
    // ? ******************
    var td_settlement_1st = document.createElement("td");
    td_settlement_1st.colSpan = 2;
    td_settlement_1st.id = `settlement_1st${count}`;
    td_settlement_1st.dataset.count = count;
    settlement_1st.appendChild(td_settlement_1st);
    // ? ******************
    var td_space = document.createElement("td");
    td_space.contentEditable = true;
    td_space.textContent = "لايوجد";
    td_space.id = "des";
    space.appendChild(td_space);
    var td_space_value = document.createElement("td");
    td_space_value.id = "feature_percent";
    td_space_value.dataset.count = count;
    td_space_value.dataset.type = false;
    td_space_value.contentEditable = true;
    td_space_value.dir = "ltr";
    td_space_value.textContent = "%";
    td_space_value.addEventListener("input", () => {
      get_feature_percent(td_space_value);
    });
    space.appendChild(td_space_value);
    // ? ******************
    var td_show_status = document.createElement("td");
    td_show_status.contentEditable = true;
    td_show_status.textContent = "لايوجد";
    td_show_status.id = "des";
    show_status.appendChild(td_show_status);
    var td_show_status_value = document.createElement("td");
    td_show_status_value.id = "feature_percent";
    td_show_status_value.dataset.count = count;
    td_show_status_value.dataset.type = false;
    td_show_status_value.contentEditable = true;
    td_show_status_value.dir = "ltr";
    td_show_status_value.textContent = "%";
    td_show_status_value.addEventListener("input", () => {
      get_feature_percent(td_show_status_value);
    });
    show_status.appendChild(td_show_status_value);
    // ? ******************
    var td_use = document.createElement("td");
    td_use.contentEditable = true;
    td_use.textContent = "لايوجد";
    td_use.id = "des";
    use.appendChild(td_use);
    var td_use_value = document.createElement("td");
    td_use_value.id = "feature_percent";
    td_use_value.dataset.count = count;
    td_use_value.contentEditable = true;
    td_use_value.dir = "ltr";
    td_use_value.textContent = "%";

    td_use_value.addEventListener("input", () => {
      get_feature_percent(td_use_value);
    });
    use.appendChild(td_use_value);
    // ? ******************
    var td_streets_no = document.createElement("td");
    td_streets_no.contentEditable = true;
    td_streets_no.textContent = "لايوجد";
    td_streets_no.id = "des";
    streets_no.appendChild(td_streets_no);
    var td_streets_no_value = document.createElement("td");
    td_streets_no_value.id = "feature_percent";
    td_streets_no_value.dataset.count = count;
    td_streets_no_value.contentEditable = true;
    td_streets_no_value.dir = "ltr";
    td_streets_no_value.textContent = "%";
    td_streets_no_value.addEventListener("input", () => {
      get_feature_percent(td_streets_no_value);
    });
    streets_no.appendChild(td_streets_no_value);
    // ? ******************
    var td_ease_of_reatch = document.createElement("td");
    td_ease_of_reatch.contentEditable = true;
    td_ease_of_reatch.textContent = "لايوجد";
    td_ease_of_reatch.id = "des";
    ease_of_reatch.appendChild(td_ease_of_reatch);
    var td_ease_of_reatch_value = document.createElement("td");
    td_ease_of_reatch_value.id = "feature_percent";
    td_ease_of_reatch_value.dataset.count = count;
    td_ease_of_reatch_value.contentEditable = true;
    td_ease_of_reatch_value.dir = "ltr";
    td_ease_of_reatch_value.textContent = "%";
    td_ease_of_reatch_value.addEventListener("input", () => {
      get_feature_percent(td_ease_of_reatch_value);
    });
    ease_of_reatch.appendChild(td_ease_of_reatch_value);
    // ? ******************
    var td_settlement_total = document.createElement("td");
    td_settlement_total.colSpan = 2;
    td_settlement_total.dir = "ltr";
    td_settlement_total.id = `td_settlement_total${count}`;
    settlement_total.appendChild(td_settlement_total);
    // ? ******************
    var td_settlements_value = document.createElement("td");
    td_settlements_value.colSpan = 2;
    td_settlements_value.dir = "ltr";
    td_settlements_value.id = `td_settlements_value${count}`;
    settlements_value.appendChild(td_settlements_value);
    // ? ******************
    var td_metar_value_after = document.createElement("td");
    td_metar_value_after.colSpan = 2;
    td_metar_value_after.dir = "ltr";
    td_metar_value_after.id = `td_metar_value_after${count}`;
    metar_value_after.appendChild(td_metar_value_after);
    // ? ******************
    var td_evening_the_value = document.createElement("td");
    td_evening_the_value.contentEditable = true;
    td_evening_the_value.colSpan = 2;
    td_evening_the_value.dir = "ltr";
    td_evening_the_value.textContent = "%";
    td_evening_the_value.dataset.count = count;
    td_evening_the_value.classList.add(`td_evening_the_value`);
    td_evening_the_value.id = `td_evening_the_value${count}`;
    td_evening_the_value.addEventListener("input", () => {
      get_feature_percent(td_evening_the_value);
      get_evening_the_value();
    });
    evening_the_value.appendChild(td_evening_the_value);
    // ? ******************
    var no = document.querySelectorAll("th").length;
    avarage_metar_value.colSpan = no;
    avarage_metar_value.dir = "ltr";
    total_land_value.colSpan = no;
    total_land_value.dir = "ltr";

    // ! ************
    var tbody = document.querySelector(".main_tbody");
    tbody.querySelectorAll(".new_tr").forEach((tr, index) => {
      var new_td = document.createElement("td");
      new_td.textContent = "لايوجد";
      new_td.contentEditable = true;
      var new_td2 = document.createElement("td");
      new_td2.contentEditable = true;
      new_td2.textContent = "%";
      new_td2.id = "feature_percent";
      new_td2.dataset.count = count;
      new_td2.addEventListener("input", () => {
        get_feature_percent(new_td2);
      });
      tr.appendChild(new_td);
      tr.appendChild(new_td2);
    });

    setcount(count + 1);
  }

  function get_feature_percent(x) {
    var element = document.querySelector(
      `#feature_percent[data-count="${x.dataset.count}"]`
    );
    element.dataset.type = true;
    var main_feature_element = document.querySelector(
      `#main_feature[data-count="${x.dataset.count}"]`
    );
    main_feature_element.dataset.type = true;
    x.dataset.type = true;
    var num = x.textContent.split("%");
    num.pop();
    var number = num.join();
    if (Number.isFinite(Number(number))) {
      x.style = "background:transparent;";
      //   setActive(Math.random());
      secondary_feature();
      main_feature();
      x.textContent = number + "%";
    } else {
      x.style = "background:#fa6464;";
    }
  }
  function get_main_feature_percent(x) {
    if (x.id == "price") {
      x.dataset.type = true;
      var element = document.querySelector(
        `#feature_percent[data-count="${x.dataset.count}"]`
      );
      element.dataset.type = true;
      var main_feature_element = document.querySelector(
        `#main_feature[data-count="${x.dataset.count}"]`
      );
      main_feature_element.dataset.type = true;
      main_feature();
      secondary_feature();
    } else {
      document.querySelectorAll(`#${x.id}`).forEach((field) => {
        field.dataset.type = false;
      });
      var element = document.querySelector(
        `#feature_percent[data-count="${x.dataset.count}"]`
      );
      element.dataset.type = true;
      var main_feature_element = document.querySelector(
        `#main_feature[data-count="${x.dataset.count}"]`
      );
      main_feature_element.dataset.type = true;
      x.dataset.type = true;

      var num = x.textContent.split("%");
      num.pop();
      var number = num.join();
      if (Number.isFinite(Number(number))) {
        x.style = "background:transparent;";
        // setActive2(Math.random());
        main_feature();
        secondary_feature();

        x.textContent = number + "%";
      } else {
        x.style = "background:#fa6464;";
      }
    }
  }
  function secondary_feature() {
    try {
      var arr = [];
      var feature_percent = document.querySelector(
        `#feature_percent[data-type="true"]`
      );
      if (feature_percent) {
        var order = feature_percent.dataset.count;
        document
          .querySelectorAll(`#feature_percent[data-count="${order}"]`)
          .forEach((value) => {
            var num = value.textContent.split("%");
            num.pop();
            var number = num.join();
            var percent = number / 100;
            arr.push(percent);
            let sum = 0;
            for (let i = 0; i < arr.length; i++) {
              sum = sum + arr[i];
            }
            var x = sum * 100;

            document.querySelector(`#td_settlement_total${order}`).textContent =
              x.toFixed(2) + "%";

            var price = document.querySelector(
              `#settlement_1st${order}`
            ).textContent;
            var total_settlements = sum * Number(price.split(",").join(""));
            document.querySelector(
              `#td_settlements_value${order}`
            ).textContent = numberWithCommas(total_settlements.toFixed(2));

            var meter_val =
              Number(price.split(",").join("")) + total_settlements;
            document.querySelector(
              `#td_metar_value_after${order}`
            ).textContent = numberWithCommas(meter_val.toFixed(2));
          });
        get_evening_the_value();
      }
      document.querySelectorAll(`#feature_percent`).forEach((field) => {
        field.dataset.type = false;
      });
    } catch (error) {
      console.log(error);
    }
  }
  function main_feature() {
    try {
      var arr = [];
      var main_feature = document.querySelector(
        `#main_feature[data-type="true"]`
      );
      if (main_feature) {
        var order = main_feature.dataset.count;
        document
          .querySelectorAll(`#main_feature[data-count="${order}"]`)
          .forEach((value) => {
            var num = value.textContent.split("%");
            num.pop();
            if (num[0] === "") {
              num.pop();
              num.push(0);
            }

            var number = num.join();
            var percent = number / 100;
            arr.push(percent);
            let sum = 0;
            for (let i = 0; i < arr.length; i++) {
              sum = sum + arr[i];
            }
            var x = sum * 100;

            document.querySelector(`#settlement_acc${order}`).textContent =
              x.toFixed(2) + "%";
          });
        var order = document.querySelector("#main_feature[data-type=true]")
          .dataset.count;
        var settlement_value = document
          .querySelector(`#settlement_acc${order}`)
          .textContent.split("%");
        settlement_value.pop();
        var value = Number(settlement_value.join()) / 100;
        var price = document.querySelector(
          `#price[data-count="${order}"]`
        ).textContent;
        var total = Number(price) * value;
        document.querySelector(`#settlement_value${order}`).textContent =
          numberWithCommas(total.toFixed(2));
        var settlemet_1st_value = total + Number(price);
        document.querySelector(`#settlement_1st${order}`).textContent =
          numberWithCommas(settlemet_1st_value);
        get_evening_the_value();
      }
      document.querySelectorAll(`#main_feature`).forEach((field) => {
        field.dataset.type = false;
      });
    } catch (error) {
      console.log(error);
    }
  }
  function get_evening_the_value() {
    var evening_the_value_arr = [];
    document
      .querySelectorAll(".td_evening_the_value")
      .forEach((value, index) => {
        var x = value.textContent.split("%");
        x.pop();
        var y = Number(x.join()) / 100;

        var meter = document.querySelector(
          `#td_metar_value_after${index + 1}`
        ).textContent;
        var meter_without_comma = meter.split(",");
        var value_meter = Number(meter_without_comma.join(""));
        var meter_value = value_meter.toFixed(2);
        var result = y * meter_value;
        evening_the_value_arr.push(result);
      });
    var avarage = document.querySelector(".avarage_metar_value");
    var sum_avarage = 0;
    for (let i = 0; i < evening_the_value_arr.length; i++) {
      sum_avarage = sum_avarage + evening_the_value_arr[i];
    }
    avarage.textContent = numberWithCommas(sum_avarage.toFixed(2));
    var myPropSpace = document.querySelector("#myPropSpace").textContent;
    var myPropSpaceValue = Number(myPropSpace).toFixed(2);
    var final_value = sum_avarage * myPropSpaceValue;
    document.querySelector(".total_land_value").textContent = numberWithCommas(
      final_value.toFixed(2)
    );
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [Case, setCase] = useState("");
  function addNewCase() {
    if (Case) {
      var tbody = document.querySelector(".main_tbody");
      var tr = document.createElement("tr");
      tr.id = "features";
      tr.classList.add("new_tr");
      var th = document.createElement("th");
      th.textContent = Case;
      var myProp = document.createElement("td");
      myProp.id = "myProp";
      tr.appendChild(th);
      tr.appendChild(myProp);
      document.querySelectorAll("#new_th").forEach((td, index) => {
        var new_td = document.createElement("td");
        new_td.textContent = "لايوجد";
        new_td.contentEditable = true;
        var new_td2 = document.createElement("td");
        new_td2.contentEditable = true;
        new_td2.textContent = "%";
        new_td2.id = "feature_percent";
        new_td2.dataset.count = index + 1;
        new_td2.addEventListener("input", () => {
          get_feature_percent(new_td2);
        });
        tr.appendChild(new_td);
        tr.appendChild(new_td2);
      });
      var settlement_total = document.querySelector(".settlement_total");
      tbody.insertBefore(tr, settlement_total);
      setCase("");
      fadeOut2();
    } else {
      showMessage("لايمكن ترك اسم عامل التسوية فارغ", true);
    }
  }
  return (
    <form className="menuClose land_val" id="main_form">
      <div className="title">
        <h1>ايجاد قيمة الارض</h1>
      </div>
      <article className="prop">
        <div className="sub_title">
          <div className="property_btn">
            <button form="fake" onClick={addProps}>
              <p>اضافة عامل تسوية</p>
              <IoIosAddCircleOutline />
            </button>
          </div>
          <div className="property_btn">
            <button form="fake" onClick={createTableRow}>
              <p>اضافة اصل</p>
              <IoIosAddCircleOutline />
            </button>
          </div>
        </div>
        <div className="input_prop" ref={prop_info}>
          <div className="title">
            <h1>اضف اصل للمقارنة</h1>
          </div>
          <div className="inputs-prop">
            <label htmlFor="property_number">
              <p>اسم عامل التسوية</p>
              <input
                type="test"
                name="property_number"
                value={Case}
                onChange={(e) => setCase(e.target.value)}
              />
            </label>
          </div>
          <div className="props_actions">
            <button form="fake" id="save" onClick={addNewCase}>
              اضافة
            </button>
            <button form="fake" id="cancel" onClick={fadeOut2}>
              الغاء
            </button>
          </div>
        </div>
        <div className="table">
          <table dir="rtl">
            <thead>
              <tr className="theadـrow">
                <th colSpan={2}></th>
              </tr>
            </thead>
            <tbody className="main_tbody">
              <tr className="value_and_ev">
                <th> عوامل التسوية</th>
                <th>عقار التقييم</th>
              </tr>
              <tr className="price">
                <th>السعر</th>
                <td></td>
              </tr>
              <tr className="market_status">
                <th>ظروف السوق</th>
                <td></td>
              </tr>
              <tr className="terms">
                <th>شروط التمويل</th>
                <td></td>
              </tr>
              <tr className="settlement_acc">
                <th>حساب التسوية</th>
                <td></td>
              </tr>
              <tr className="settlement_value">
                <th>قيمة التسوية</th>
                <td></td>
              </tr>
              <tr className="settlement_1st">
                <th>التسوية الأولية</th>
                <td></td>
              </tr>
              <tr className="space" id="features">
                <th>المساحة</th>
                <td id="myPropSpace">1500</td>
              </tr>
              <tr className="show_status" id="features">
                <th>حالة العرض</th>
                <td></td>
              </tr>
              <tr className="use" id="features">
                <th>الاستخدام</th>
                <td></td>
              </tr>
              <tr className="streets_no" id="features">
                <th>عدد الشوارع</th>
                <td></td>
              </tr>
              <tr className="ease_of_reatch" id="features">
                <th>سهولة الوصول</th>
                <td></td>
              </tr>
              <tr className="settlement_total">
                <th>اجمالي التسويات</th>
                <td></td>
              </tr>
              <tr className="settlements_value">
                <th>قيمة التسويات</th>
                <td></td>
              </tr>
              <tr className="metar_value_after">
                <th>قيمة المتر بعد التسوية</th>
                <td></td>
              </tr>
              <tr className="evening_the_value">
                <th>المرجح الموزون</th>
                <td></td>
              </tr>
              <tr>
                <th>متوسط قيمة المتر</th>
                <td colSpan={1} className="avarage_metar_value"></td>
              </tr>
              <tr>
                <th>اجمالي قيمة الأرض</th>
                <td colSpan={1} className="total_land_value"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
};

export default LandValue;
