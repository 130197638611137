import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack, IoIosWarning } from "react-icons/io";
import { gsap } from "gsap";
import "../style/evaluator/main.css";
import axios from "axios";
import {
  LineChart,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  XAxis,
  Legend,
} from "recharts";
const Main = () => {
  const messageSVG = useRef(null);
  const message_content_Ref = useRef(null);
  const messageRef = useRef(null);
  const [number_of_states, setnumber_of_states] = useState(false);
  const [data, setdata] = useState(false);

  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }

  const active_users = [
    { date: "6d ago", activeUsers: 80, data: 90 },
    { date: "5d ago", activeUsers: 100, data: 80 },
    { date: "4d ago", activeUsers: 150, data: 130 },
    { date: "3d ago", activeUsers: 100, data: 160 },
    { date: "2d ago", activeUsers: 120, data: 120 },
    { date: "1d ago", activeUsers: 90, data: 50 },
    { date: "today", activeUsers: 180, data: 200 },
  ];
  useEffect(() => {
    getstates();
  }, []);
  async function getstates() {
    var tl = gsap.timeline({});
    try {
      tl.to(".fix", { opacity: 1, pointerEvents: "all" });

      var response = await axios.get(
        "https://auctionbackend.netlify.app/.netlify/functions/server/get/number_of_states"
        // "http://localhost:5001/get/number_of_states"
      );
      if (response.data.status === false) {
        showMessage(response.data.message, true);
      } else {
        setnumber_of_states(response.data);
        setdata(response.data.date);
      }
    } catch (error) {
      showMessage("حدث خطاء", true);
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }

  const combinedData = [];
  Object.entries(data).forEach(([key, values]) => {
    values.forEach((item) => {
      const existingData = combinedData.find((d) => d.date === item.D);
      if (existingData) {
        existingData[key] = item.count;
      } else {
        combinedData.push({
          date: item.D,
          [key]: item.count,
        });
      }
    });
  });
  console.log(combinedData)
  const sortedData = combinedData.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  return (
    <form className="menuClose land_val" id="main_form">
      <div className="title">
        <h1> الرئيسية </h1>
      </div>
      <main dir="rtl">
        <div className="opration">
          <h1>العروض</h1>
          <div className="details">
            <span>
              <p>معلق</p>
              <p id="number">{number_of_states.offers_false}</p>
              <Link to={`/Offer_details?states=0`}>
                <IoMdArrowRoundBack />
              </Link>
            </span>
            <span>
              <p>منجز</p>
              <p id="number">{number_of_states.offers_true}</p>
              <Link to={`/Offer_details?states=1`}>
                <IoMdArrowRoundBack />
              </Link>
            </span>
          </div>
        </div>
        <div className="opration">
          <h1>العقود</h1>
          <div className="details">
            <span>
              <p>معلق</p>
              <p id="number">{number_of_states.contract_false}</p>
              <Link to={"/Contract_details?states=0"}>
                <IoMdArrowRoundBack />
              </Link>
            </span>
            <span>
              <p>منجز</p>
              <p id="number">{number_of_states.contract_true}</p>
              <Link to={"/Contract_details?states=1"}>
                <IoMdArrowRoundBack />
              </Link>
            </span>
          </div>
        </div>
        <div className="opration">
          <h1>المعاملات</h1>
          <div className="details">
            <span>
              <p>معلق</p>
              <p id="number">{number_of_states.transactions_false}</p>
              <Link to={"/Transaction_details?states=0"}>
                <IoMdArrowRoundBack />
              </Link>
            </span>
            <span>
              <p>منجز</p>
              <p id="number">{number_of_states.transactions_true}</p>
              <Link to={"/Transaction_details?states=1"}>
                <IoMdArrowRoundBack />
              </Link>
            </span>
          </div>
        </div>
        {/* <div className="opration">
          <h1>-</h1>
          <div className="details">
            <span>
              <p>معلق</p>
              <p id="number">100</p>
              <Link to={"#"}>
                <IoMdArrowRoundBack />
              </Link>
            </span>
            <span>
              <p>منجز</p>
              <p id="number">50</p>
              <Link to={"#"}>
                <IoMdArrowRoundBack />
              </Link>
            </span>
          </div>
        </div> */}
      </main>
      <div className="chart">
        <div id="line">
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <LineChart width={400} height={300} data={sortedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="offers_date"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="contract_date"
                stroke="#82ca9d"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="transactions_date"
                stroke="#ffc658"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div id="grid" dir="rtl">
          <div className="card c1"></div>
          <div className="card c2">
            <h1 id="title">الملاحظات</h1>
            <h1 id="urgent" dir="ltr">
              {number_of_states.notes}
            </h1>
          </div>
          <div className="card c3">
            <h1 id="title">المعاملات المستعجلة</h1>
            <h1 id="urgent" dir="ltr">
              {number_of_states.urgent}
            </h1>
          </div>
        </div>
      </div>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
};

export default Main;
