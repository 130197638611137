import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Empolyee from "./empolyee";
import Agent from "./agent";
import Prices from "./Pirices";
import Contract from "./contract";
import Totransaction from "./To_transaction";
import MyMap from "./Map";
import Signature from "./signature";
import Report from "./report";
import LandValue from "./evaluator/Land_value";
import CostEvaluation from "./evaluator/Cost_evaluation";
import Capitalization from "./evaluator/Capitalization";
import ResidualValue from "./evaluator/ResidualValue";
import ResidualValueBuilding from "./evaluator/ResidualValueBuilding";
import Data from "./evaluator/data";
import Flows from "./evaluator/Flows";
import Header from "../components/Header";
import "../style/container.css";
import { infinity } from "ldrs";
import Main from "./Main";
import Offer_details from "./Offer_details";
import Contract_details from "./contract_details";
import View_offer from "./view_offer";
import Transaction_details from "./transaction_details";
infinity.register();

const Container = () => {
  return (
    <div className="containerr">
      <div className="fix">
        <l-infinity
          size="55"
          stroke="4"
          stroke-length="0.15"
          bg-opacity="0.1"
          speed="1.3"
          color="black"
          className="ldrs"
        ></l-infinity>
      </div>
      <Header />
      <Routes>
        <Route index element={<Home />}></Route>
        <Route path="/empolyee" element={<Empolyee />}></Route>
        <Route path="/Agent" element={<Agent />}></Route>
        <Route path="/prices/:id" element={<Prices />}></Route>
        <Route path="/prices" element={<Prices />}></Route>
        <Route path="/contract" element={<Contract />}></Route>
        <Route path="/to_transaction" element={<Totransaction />}></Route>
        <Route path="/Report" element={<Report />}></Route>
        <Route path="/map" element={<MyMap />}></Route>
        <Route path="/signature" element={<Signature />}></Route>
        <Route path="/LandValue" element={<LandValue />}></Route>
        <Route path="/CostEvaluation" element={<CostEvaluation />}></Route>
        <Route path="/Capitalization" element={<Capitalization />}></Route>
        <Route path="/Flows" element={<Flows />}></Route>
        <Route path="/ResidualValue" element={<ResidualValue />}></Route>
        <Route
          path="/ResidualValueBuilding"
          element={<ResidualValueBuilding />}
        ></Route>
        <Route path="/Main" element={<Main />}></Route>
        <Route path="/data" element={<Data />}></Route>
        <Route path="/Offer_details" element={<Offer_details />}></Route>
        <Route path="/Contract_details" element={<Contract_details />}></Route>
        <Route path="/View_offer" element={<View_offer />}></Route>
        <Route
          path="/Transaction_details"
          element={<Transaction_details />}
        ></Route>
      </Routes>
    </div>
  );
};

export default Container;
