import "../style/Nav.css";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { IoHomeOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { RiPriceTag2Line } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import { AiOutlineInteraction } from "react-icons/ai";
import { GrValidate } from "react-icons/gr";
import { BsCoin } from "react-icons/bs";
import { FaRegHandshake } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { PiMathOperationsBold } from "react-icons/pi";
import { TbListDetails } from "react-icons/tb";

import { PiBuildingApartment } from "react-icons/pi";
import { gsap } from "gsap";

function Nav() {
  const [search, setsearch] = useSearchParams({ nav: false });
  var nav = search.get("nav") === "true";
  var tl = gsap.timeline({});
  useEffect(
    () => async () => {
      if (nav === false) {
        await tl
          .to("#first_ul", {
            display: "block",
            opacity: 0,
            duration: 0,
          })
          .to("#first_ul", {
            opacity: 1,
            pointerEvents: "all",
            duration: 0,
          })
          .to("#sec_ul", {
            opacity: 0,
            pointerEvents: "none",
            duration: 0,
          })
          .to("#sec_ul", {
            display: "none",
            duration: 0,
          });
      } else {
        await tl
          .to("#first_ul", {
            opacity: 0,
            pointerEvents: "none",
            duration: 0,
          })
          .to("#first_ul", {
            display: "none",
            duration: 0,
          })
          .to("#sec_ul", {
            opacity: 0,
            display: "block",
            duration: 0,
          })
          .to("#sec_ul", {
            opacity: 1,
            pointerEvents: "all",
            duration: 0,
          });
      }
    },
    []
  );

  const navRef = useRef(null);
  const typeRef1 = useRef(null);
  const typeRef2 = useRef(null);
  const typeRef3 = useRef(null);
  const typeRef4 = useRef(null);
  const typeRef5 = useRef(null);
  const handelOnclick = (type) => {
    var no_nav = type.split("-")[1];
    var arr = [];
    document.querySelectorAll("article").forEach((article) => {
      arr.push(article.id);
    });
    var ar_type = type.split("");
    ar_type.pop();
    ar_type = ar_type.join("");

    for (let i = 0; i < arr.length - 1; i++) {
      document.querySelectorAll("article[data-active=true]").forEach((item) => {
        item.dataset.active = false;
      });
      document.querySelector(`#s-${no_nav}`).dataset.active = true;
    }
  };
  return (
    <nav ref={navRef}>
      <div className="head">
        <p>Action.co </p>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" />
          </svg>
        </span>
      </div>
      <ul id="first_ul">
        <li>
          <Link to={"/main"}>
            <p>الرئيسية</p>{" "}
            <span>
              <IoHomeOutline />
            </span>
          </Link>
        </li>
        <li>
          <span>
            <details>
              <summary>العملاء</summary>
              <ul>
                <li>
                  <Link to={"/agent"}>اضافة عميل</Link>
                </li>
                <li>
                  <Link to={"/"}>جميع العملاء</Link>
                </li>
              </ul>
            </details>
            <span>
              <FaRegHandshake />
            </span>
          </span>
        </li>
        <li>
          <span>
            <details>
              <summary>المستخدمين</summary>
              <ul>
                <li>
                  <Link to={"/empolyee"}>اضافة مستخدم</Link>
                </li>
                <li>
                  <Link to={"/"}>جميع المستخدمين</Link>
                </li>
              </ul>
            </details>
            <span>
              <FiUsers />
            </span>
          </span>
        </li>

        <li>
          <span>
            <details>
              <summary>اتفاقات و عروض</summary>
              <ul>
                <li>
                  <Link to={"/prices"}> عرض سعر</Link>
                </li>
                <li>
                  <Link to={"/contract"}>عقد عمل</Link>
                </li>
              </ul>
            </details>
            <span>
              <RiPriceTag2Line />
            </span>
          </span>
        </li>
        <li>
          <span>
            <details>
              <summary>المعاملات</summary>
              <ul>
                <li>
                  <Link to={"/to_transaction"}> عقد الى معاملة</Link>
                </li>
                <li>
                  <Link to={"/contract"}>معاملة جديدة</Link>
                </li>
              </ul>
            </details>
            <span>
              <AiOutlineInteraction />
            </span>
          </span>
        </li>
        <li>
          <Link to={"/"}>
            <p>تقييم الكتروني</p>{" "}
            <span>
              <GrValidate />
            </span>
          </Link>
        </li>
        <li>
          <Link to={"/"}>
            <p> الاستثمار</p>{" "}
            <span>
              <BsCoin />
            </span>
          </Link>
        </li>
        {/* <li>
          <Link to={"/"}>
            <p> التدفقات النقدية</p>{" "}
            <span>
              <FaMoneyBillTrendUp />
            </span>
          </Link>
        </li> */}
        {/* <li>
          <Link to={"/"}>
            <p> القيمة المتبقية</p>{" "}
            <span>
              <TbArrowsSplit />
            </span>
          </Link>
        </li> */}
        <li>
          <Link to={"/Report"}>
            <p>التقارير</p>
            <span>
              <TbReportSearch />
            </span>
          </Link>
        </li>
        {/* <li>
          <Link to={"/"}>
            <p>الرسملة المباشرة </p>{" "}
            <span>
              <FaSheetPlastic />
            </span>
          </Link>
        </li> */}
      </ul>
      <ul id="sec_ul">
        <li
          className="part"
          ref={typeRef1}
          onClick={(e) => {
            setsearch((prev) => {
              prev.set("nav", "true");
              return prev;
            });
            e.preventDefault();
            handelOnclick(typeRef1.current.dataset.type);
          }}
        >
          <Link to={"#"}>
            <p>البيانات الأساسية</p>
            <span>
              <IoIosInformationCircleOutline />
            </span>
          </Link>
        </li>
        <li
          className="part"
          ref={typeRef2}
          onClick={(e) => {
            e.preventDefault();
            setsearch((prev) => {
              prev.set("nav", "true");
              return prev;
            });
            handelOnclick(typeRef2.current.dataset.type);
          }}
        >
          <Link to={"#"}>
            <p>بيانات العقار</p>
            <span>
              <MdOutlineRealEstateAgent />
            </span>
          </Link>
        </li>
        <li
          className="part"
          ref={typeRef3}
          onClick={(e) => {
            e.preventDefault();
            setsearch((prev) => {
              prev.set("nav", "true");
              return prev;
            });
            handelOnclick(typeRef3.current.dataset.type);
          }}
        >
          <Link to={"/"}>
            <p>تفاصيل العقار</p>
            <span>
              <TbListDetails />
            </span>
          </Link>
        </li>
        <li
          className="part"
          ref={typeRef4}
          onClick={(e) => {
            e.preventDefault();
            setsearch((prev) => {
              prev.set("nav", "true");
              return prev;
            });
            handelOnclick(typeRef4.current.dataset.type);
          }}
        >
          <Link to={"/"}>
            <p>تقييم الارض و المباني</p>
            <span>
              <PiMathOperationsBold />
            </span>
          </Link>
        </li>
        <li
          className="part"
          ref={typeRef5}
          onClick={(e) => {
            e.preventDefault();
            setsearch((prev) => {
              prev.set("nav", "true");
              return prev;
            });
            handelOnclick(typeRef5.current.dataset.type);
          }}
        >
          <Link to={"/"}>
            <p>تقيم الإيجارات والمالية</p>
            <span>
              <PiBuildingApartment />
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
