import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { IoIosAddCircleOutline, IoIosWarning } from "react-icons/io";
import { gsap } from "gsap";
import "../../style/evaluator/CostEvaluation.css";
const Capitalization = () => {
  const messageRef = useRef(null);
  const message_content_Ref = useRef(null);
  const messageSVG = useRef(null);
  const RefAlert = useRef(null);
  const RefForm = useRef(null);

  const prop_info = useRef(null);
  function addProps() {
    gsap.to(prop_info.current, { display: "flex" });
    gsap.to(prop_info.current, {
      opacity: 1,
      pointerEvents: "all",
      duration: 0.5,
      delay: 0.2,
    });
  }
  function fadeOut2() {
    gsap.to(prop_info.current, {
      display: "none",
      opacity: 0,
      duration: 0.5,
    });
  }
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  const [count, setcount] = useState(1);
  const [Case, setCase] = useState("");
  const [sum, setsum] = useState(0);

  function addNewCase() {
    if (Case) {
      var tbody_1 = document.querySelector(".tbody_1");
      var tr = document.createElement("tr");
      tr.id = "features";
      tr.dataset.count = count;
      tr.classList.add("new_tr");
      var title_td = document.createElement("td");
      title_td.textContent = Case;
      title_td.dir = "rtl";
      tr.appendChild(title_td);
      var td_space = document.createElement("td");
      td_space.contentEditable = true;
      td_space.id = "space";
      td_space.dataset.count = count;
      td_space.dir = "ltr";
      td_space.dataset.type = false;
      td_space.addEventListener("input", () => {
        calc_all(td_space);
      });
      tr.appendChild(td_space);
      var td_price = document.createElement("td");
      td_price.contentEditable = true;
      td_price.id = "price";
      td_price.dataset.count = count;
      td_price.dir = "ltr";
      td_price.dataset.type = false;
      td_price.addEventListener("input", () => {
        calc_all(td_price);
      });
      tr.appendChild(td_price);
      var td_result = document.createElement("td");
      td_result.id = "result";
      td_result.dataset.count = count;
      tr.appendChild(td_result);
      var tbody_1_result_tr = document.querySelector("#tbody_1_result_tr");
      tbody_1.insertBefore(tr, tbody_1_result_tr);
      setcount(count + 1);
      setCase("");
      fadeOut2();
    } else {
      showMessage("لايمكن ترك اسم عامل التسوية فارغ", true);
    }
  }
  function calc_all(x) {
    try {
      var arr = [];
      var space = document.querySelector(
        `#space[data-count="${x.dataset.count}"]`
      );
      var space_val = is_number(space);
      var price = document.querySelector(
        `#price[data-count="${x.dataset.count}"]`
      );
      var price_val = is_number(price);
      var result = document.querySelector(
        `#result[data-count="${x.dataset.count}"]`
      );
      var tbody_1_result_tr = document.querySelector(".tbody_1_result_value");

      if (space_val == true && price_val == true) {
        var space_value = Number(space.textContent) * Number(price.textContent);
        result.textContent = space_value.toFixed(2);
        document.querySelectorAll(`#result`).forEach((result) => {
          var value = Number(result.textContent);
          arr.push(value);
        });
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
          sum = sum + arr[i];
        }
        setsum(sum);
        tbody_1_result_tr.textContent = numberWithCommas(sum.toFixed(2));
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    document.querySelector("#technical_taxes").textContent = "%";
  }, []);
  useEffect(() => {
    undirected_expencess();
  }, [sum]);

  function undirected_expencess() {
    var technical_taxes_val = is_number2(
      document.querySelector("#technical_taxes")
    );
    var oparating_val = is_number2(document.querySelector("#oparating"));
    var capitalization_val = is_number2(
      document.querySelector("#capitalization")
    );

    if (
      technical_taxes_val === true &&
      oparating_val === true &&
      capitalization_val === true
    ) {
      var technical_taxes = document.querySelector("#technical_taxes");
      var technical_taxes_value = document.querySelector(
        "#technical_taxes_value"
      );

      var total_building_taxes = document.querySelector(
        "#total_building_taxes"
      );
      var degit = technical_taxes.textContent.split("%");
      var x = (Number(degit[0]) / 100) * sum;
      technical_taxes_value.textContent = numberWithCommas(x.toFixed(2));
      var z = x.toFixed(2);
      var w = Number(sum) - Number(z);
      total_building_taxes.textContent = numberWithCommas(w.toFixed(0));
      //   ! ************
      var oparating = document.querySelector("#oparating");
      var degit2 = oparating.textContent.split("%");
      var x = (Number(degit2[0]) / 100) * w;
      var oparating_value = document.querySelector("#oparating_value");
      oparating_value.textContent = numberWithCommas(x.toFixed(0));
      var net_total_oparating = document.querySelector("#net_total_oparating");
      var z = x.toFixed(2);
      var y = Number(w) - Number(z);
      net_total_oparating.textContent = numberWithCommas(y.toFixed(0));
      //   ! ************
      var capitalization = document.querySelector("#capitalization");
      var degit2 = capitalization.textContent.split("%");
      if (degit2[0] == 0) {
          var capitalization_value = document.querySelector(
            "#capitalization_value"
          );
          capitalization_value.textContent = 0;
          var net_total_capitalization = document.querySelector(
            "#net_total_capitalization"
          );
          net_total_capitalization.textContent = 0;
      } else {
        var x = y / (Number(degit2[0]) / 100);
        var capitalization_value = document.querySelector(
          "#capitalization_value"
        );
        capitalization_value.textContent = numberWithCommas(x.toFixed(0));
        var net_total_capitalization = document.querySelector(
          "#net_total_capitalization"
        );
        net_total_capitalization.textContent = numberWithCommas(x.toFixed(0));
      }
    }
  }
  function is_number(value) {
    var number = value.textContent;
    if (Number.isFinite(Number(number))) {
      value.style = "background:transparent;";
      //   setActive(Math.random());
      return true;
    } else {
      value.style = "background:#fa6464;";
    }
  }
  function is_number2(x) {
    var num = x.textContent.split("%");
    num.pop();
    var number = num.join();
    if (Number.isFinite(Number(number))) {
      x.style = "background:transparent;";
      x.textContent = number + "%";
      //   setActive(Math.random());
      return true;
    } else {
      x.style = "background:#fa6464;";
    }
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <form
      className="menuClose land_val"
      id="main_form"
      onSubmit={(e) => {
        e.preventDefault();
        addNewCase();
      }}
    >
      <div className="title">
        <h1>التقييم بطربقة الرسملة</h1>
      </div>
      <article className="prop">
        <div className="sub_title">
          <div className="property_btn">
            <button form="fake" onClick={addProps}>
              <p>اضافة عقار</p>
              <IoIosAddCircleOutline />
            </button>
          </div>
        </div>
        <div className="input_prop" ref={prop_info}>
          <div className="title">
            <h1>اضف عقار </h1>
          </div>
          <div className="inputs-prop">
            <label htmlFor="property_number">
              <p>نوع العقار</p>
              <input
                type="test"
                name="property_number"
                value={Case}
                onChange={(e) => setCase(e.target.value)}
              />
            </label>
          </div>
          <div className="props_actions">
            <button form="fake" id="save" onClick={addNewCase}>
              اضافة
            </button>
            <button form="fake" id="cancel" onClick={fadeOut2}>
              الغاء
            </button>
          </div>
        </div>
        <div className="table">
          <table dir="rtl">
            <thead className="thead_1">
              <tr>
                <th colSpan={4}>طريقة رسملة الدخل</th>
              </tr>
              <tr>
                <th>النوع</th>
                <th>القيمة</th>
                <th>العدد</th>
                <th>الإجمالي</th>
              </tr>
            </thead>
            <tbody className="tbody_1">
              <tr id="tbody_1_result_tr">
                <td colSpan={3}>الاجمالي</td>
                <td dir="ltr" className="tbody_1_result_value"></td>
              </tr>
            </tbody>
            <thead className="thead_2">
              <tr>
                <th colSpan={4}>حساب خسائر عدم الاشغار</th>
              </tr>
            </thead>
            <tbody className="tbody_2">
              <tr>
                <td colSpan={2}>خسائر عدم الاشغار</td>
                <td
                  contentEditable={true}
                  id="technical_taxes"
                  dir="ltr"
                  onInput={undirected_expencess}
                ></td>
                <td id="technical_taxes_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>صافي الدخل التشغيلي</td>
                <td id="total_building_taxes"></td>
              </tr>
            </tbody>
            <thead className="thead_3">
              <tr>
                <th colSpan={4}>حساب مصاريف التشغيل و الصيانة</th>
              </tr>
            </thead>
            <tbody className="tbody_3">
              <tr>
                <td colSpan={2}>مصاريف التشغيل و الصيانة</td>
                <td
                  contentEditable={true}
                  id="oparating"
                  dir="ltr"
                  onInput={undirected_expencess}
                ></td>
                <td id="oparating_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>صافي الدخل التشغيلي</td>
                <td id="net_total_oparating"></td>
              </tr>
            </tbody>
            <thead className="thead_4">
              <tr>
                <th colSpan={4}>حساب الرسملة</th>
              </tr>
            </thead>
            <tbody className="tbody_4">
              <tr>
                <td colSpan={2}>معدل الرسملة</td>
                <td
                  contentEditable={true}
                  id="capitalization"
                  dir="ltr"
                  onInput={undirected_expencess}
                ></td>
                <td id="capitalization_value"></td>
              </tr>
              <tr>
                <td colSpan={3}>القيمة السوقية بطريقة رسملة الدخل</td>
                <td id="net_total_capitalization"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
};

export default Capitalization;
