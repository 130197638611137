import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "../style/to_transaction.css";
import { AiOutlineFileAdd } from "react-icons/ai";
import localforage from "localforage";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import gsap from "gsap";
const files = new Map();
const sides = new Map();

function To_transaction() {
  const typeRef0 = useRef(null);
  const typeRef1 = useRef(null);
  const typeRef2 = useRef(null);
  const typeRef3 = useRef(null);
  const RefAlert = useRef(null);
  const RefForm = useRef(null);
  const messageRef = useRef(null);
  const messageSVG = useRef(null);
  const message_content_Ref = useRef(null);
  const [search, set_search] = useState("");
  const [state, setState] = useState(false);
  const handelOnclick = (type) => {
    var no_nav = type.split("-")[1];
    var arr = [];
    document.querySelectorAll("article").forEach((article) => {
      arr.push(article.id);
    });
    var ar_type = type.split("");
    ar_type.pop();
    ar_type = ar_type.join("");

    for (let i = 0; i < arr.length - 1; i++) {
      document.querySelectorAll("article[data-active=true]").forEach((item) => {
        item.dataset.active = false;
      });
      document.querySelector(`#s-${no_nav}`).dataset.active = true;
    }
  };
  useLayoutEffect(() => {
    var count = 1;
    document.querySelectorAll(".part").forEach((part) => {
      part.dataset.type = `s-` + count + `-`;
      count++;
    });
    var count_article = 1;
    document.querySelectorAll("article").forEach((article) => {
      if (count_article === 1) {
        article.dataset.active = true;
      } else {
        article.dataset.active = false;
      }
      article.id = `s-` + count_article;
      count_article++;
    });
  }, []);
  async function get_files_info(key, data) {
    if (data.file) {
      files.set(key, data);
    } else {
      var object = files.get(key);
      if (object) {
        var file = object.file;
        object.file = file;
        object.des = data.des;
        files.set(key, object);
      } else {
        files.set(key, { file: null, des: data.des });
      }
    }
  }
  function showMessage(txt, state) {
    state == true
      ? (messageSVG.current.style.display = "block")
      : (messageSVG.current.style.display = "none");
    message_content_Ref.current.textContent = txt;
    gsap.fromTo(
      messageRef.current,
      { left: "-10rem", opacity: 0 },
      { left: "1rem", opacity: 1, duration: 0.5 }
    );
    gsap.fromTo(
      messageRef.current,
      { boxShadow: "0px 0px 0px 0px transparent" },
      { boxShadow: "0px 2px 5px 1px var(--clr)", duration: 0.5, delay: 1 }
    );
    gsap.to(messageRef.current, {
      boxShadow: "0px 0px 0px 0px transparent",
      duration: 0.5,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 3rem)",
      transform: "translate(0%,  calc(-100% - 3rem))",
      opacity: 0,
      duration: 0.5,
      delay: 3,
    });
    gsap.to(messageRef.current, {
      top: " calc(100vh - 1rem)",
      left: "-10rem",
      transform: "translate(0%,  calc(-100% - 1rem))",
      opacity: 0,
      duration: 0,
    });
  }
  const [documents_, setdocuments] = useState();
  async function get_files_info(key, data) {
    if (data.file) {
      files.set(key, data);
    } else {
      var object = files.get(key);
      if (object) {
        var file = object.file;
        object.file = file;
        object.des = data.des;
        files.set(key, object);
      } else {
        files.set(key, { file: null, des: data.des });
      }
    }

    // var document = Object.fromEntries(files);
    // setData((prevData) => ({
    //   ...prevData,
    //   document, // Dynamic property access for any client field
    // }));
    const listItems1 = [];

    files.forEach((doc, key) => {
      listItems1.push(
        <li dir="rtl" key={key}>
          {doc.des}
        </li>
      );
    });

    setdocuments(listItems1);
  }
  const [count, setCount] = useState(0); // State for file counter
  const add_new_file = () => {
    const key = count;

    const labelFiles = document.querySelector(".label_files"); // Get the container element

    // Create a new container element
    const newContainer = document.createElement("div");
    newContainer.classList.add("container"); // Add the 'container' class
    newContainer.id = `container_${count}`;
    // Create delete button
    const action_file = document.createElement("div");
    action_file.classList.add("action_file");
    action_file.id = count;
    action_file.addEventListener("click", () => {
      document.querySelector(`#container_${action_file.id}`).remove();
      var value = action_file.id;
      files.delete(value);
      // var document = Object.fromEntries(files);
      // setData((prevData) => ({
      //   ...prevData,
      //   document, // Dynamic property access for any client field
      // }));
      setCount(count - 1);
    });
    var delete_svg = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>`;

    // Create the input elements and icon
    const inputSpan = document.createElement("span");
    inputSpan.classList.add("span_input");
    const fileInput = document.createElement("input");
    fileInput.type = "text";
    fileInput.id = `file_des_${count}`; // Use template literal for clear naming
    fileInput.name = `file_des_${count}`; // Use template literal for clear naming
    fileInput.placeholder = "اسم الملف";
    fileInput.dir = "rtl";

    const fileSpan = document.createElement("span");
    fileSpan.classList.add("span_file");
    const fileIcon = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g id="File_On"><path d="M19.485,7.35l-4.97-4.86a1.466,1.466,0,0,0-1.05-.43h-6.9a2.5,2.5,0,0,0-2.5,2.5V19.44a2.507,2.507,0,0,0,2.5,2.5h10.87a2.507,2.507,0,0,0,2.5-2.5V8.42A1.49,1.49,0,0,0,19.485,7.35Zm-1.27.15h-2.34a1.5,1.5,0,0,1-1.5-1.5V3.75Zm.72,11.94a1.5,1.5,0,0,1-1.5,1.5H6.565a1.5,1.5,0,0,1-1.5-1.5V4.56a1.5,1.5,0,0,1,1.5-1.5h6.81V6a2.5,2.5,0,0,0,2.5,2.5h3.06Z"></path></g></svg>`;
    const fileUploadInput = document.createElement("input");
    fileUploadInput.type = "file";
    fileUploadInput.id = `file_${count}`; // Use template literal for clear naming
    fileUploadInput.name = `file_${count}`; // Use template literal for clear naming
    fileUploadInput.className = "file_input"; // Add a class for styling

    // Append input elements to their respective spans
    inputSpan.appendChild(fileInput);
    fileSpan.innerHTML = fileIcon;
    fileSpan.appendChild(fileUploadInput);
    action_file.innerHTML = delete_svg;
    action_file.addEventListener("click", () => {
      files.delete(key);
    });
    // Append both spans to the new container
    newContainer.appendChild(inputSpan);
    newContainer.appendChild(fileSpan);
    newContainer.appendChild(action_file);
    // Append the new container to the `label_files` element
    labelFiles.appendChild(newContainer);
    fileInput.addEventListener("input", () => {
      get_files_info(key, {
        des: fileInput.value,
      });
    });

    fileUploadInput.addEventListener("change", (event) => {
      const file = event.target.files[0];
      console.log(file);
      const des = document.getElementById(`file_des_${count}`);
      get_files_info(key, {
        file: file,
        des: des.value,
      });
    });
    // Increment the counter for unique file names
    setCount(count + 1);
  };
  useEffect(() => {
    if (state == false) {
      gsap.fromTo(
        RefAlert.current,
        { opacity: 0 },
        { opacity: 1, duration: 0.5 }
      );
      gsap.fromTo(
        RefForm.current,
        { opacity: 0, y: 10 },
        { y: 0, opacity: 1, duration: 0.5, delay: 0.5 }
      );
      // setState(true);
    } else {
      gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
      gsap.to(RefAlert.current, {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.5,
        delay: 0.5,
      });
    }
  }, [state]);
  function fadeOut2() {
    setState(true);
    gsap.to(RefForm.current, { y: -10, opacity: 0, duration: 0.5 });
    gsap.to(RefAlert.current, {
      opacity: 0,
      pointerEvents: "none",
      duration: 0.5,
      delay: 0.5,
    });
  }
  async function get_contract() {
    try {
      var tl = gsap.timeline({});

      if (search !== null && search !== "") {
        tl.to(".fix", { opacity: 1, pointerEvents: "all" });

        const data = {
          id: search,
        };
        const response = await axios.post(
          "https://auctionbackend.netlify.app/.netlify/functions/server/get/contract",
          data
        );
        console.log(response.data);
        if (response.data.status == 200) {
          tl.to(".fix", { opacity: 0, pointerEvents: "none" });
          setData((prevData) => ({
            ...prevData,
            contract_id: search, // Dynamic property access for any client field
          }));
          fadeOut2();
        } else {
          showMessage(response.data.message, true);
        }
      } else {
        showMessage("الرجاء ادخال بيانات", true);
      }
    } catch (error) {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
      showMessage("حدث خطاء", true);
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }
  function formatDateToYYYYMMDDHHMMSS(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const currentDate = new Date();

  const [data, setData] = useState({
    transaction_base_info: {},
    north: {},
    south: {},
    east: {},
    west: {},
    engineering_report: false,
    urgent: false,
    date: formatDateToYYYYMMDDHHMMSS(currentDate),
  });
  useEffect(() => {
    console.log(data);
  }, [data]);
  const handleInputChange = (event) => {
    const { name, value } = event.target; // Destructure event object
    setData((prevData) => ({
      ...prevData,
      transaction_base_info: {
        ...prevData.transaction_base_info,
        [name]: value, // Dynamic property access for any client field
      },
    }));
  };
  const handleSelectChange = (event) => {
    const { name, value } = event.target; // Destructure event object
    setData((prevData) => ({
      ...prevData,
      transaction_base_info: {
        ...prevData.transaction_base_info,
        [name]: value,
      }, // Dynamic property access for any client field,
    }));
  };
  const handelmap = (e) => {
    const { name, id, value } = e.target;

    // Check if the Map already has an entry for the name
    const existingData = sides.get(name);

    // If the entry exists, update the corresponding value
    if (existingData) {
      existingData[id] = value;
    } else {
      // If the entry doesn't exist, create a new object
      const newData = {
        des: id === "des" ? value : existingData?.des,
        length: id === "length" ? value : existingData?.length,
      };
      sides.set(name, newData);
    }
    const jsonObject = Object.fromEntries(sides);

    setData((prevData) => {
      const newData = { ...prevData };
      for (const key in jsonObject) {
        newData[key] = {
          ...jsonObject[key],
        };
      }
      return newData;
    });
  };
  const InputChange = (event) => {
    const { name, value } = event.target; // Destructure event object
    setData((prevData) => ({
      ...prevData,
      [name]: value, // Dynamic property access for any client field
    }));
  };
  const checkbox = (event) => {
    const { name, checked } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: checked, // Dynamic property access for any client field
    }));
  };
  async function submit() {
    try {
      var tl = gsap.timeline({});
      tl.to(".fix", { opacity: 1, pointerEvents: "all" });
      const formData = new FormData();
      const arrayOfObjects = Array.from(files.entries()).map(
        ([key, value]) => ({
          key,
          value,
        })
      );
      for (let i = 0; i < arrayOfObjects.length; i++) {
        formData.append("file", arrayOfObjects[i].value.file);
        formData.append("des", arrayOfObjects[i].value.des);
        // console.log(arrayOfObjects[i].value.des);
      }
      formData.append("data", JSON.stringify(data));
      const response = await axios.post(
        "https://auctionbackend.netlify.app/.netlify/functions/server/submit/transaction",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      if (response.data.status == 200) {
        showMessage(response.data.message, false);
      } else {
        showMessage(response.data.message, true);
      }
    } catch (error) {
    } finally {
      tl.to(".fix", { opacity: 0, pointerEvents: "none" });
    }
  }
  const [model_type, set_model_type] = useState();
  useEffect(() => {
    get_model_type();
  }, []);
  async function get_model_type() {
    try {
      var res = await axios.get(
        "https://auctionbackend.netlify.app/.netlify/functions/server/get/model_type"
      );
      var result = res.data.map((data, index) => (
        <option key={index} value={data.model_type_id}>
          {data.type}
        </option>
      ));
      set_model_type(result);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <form className="menuClose" id="main_form">
      <div className="alert" ref={RefAlert}>
        <div action="#" id="search" ref={RefForm}>
          <label htmlFor="">
            <p dir="rtl">ادخل رقم العقد</p>
            <input
              type="text"
              value={search}
              onChange={(e) => set_search(e.target.value)}
            />
          </label>
          <span>
            <button form="fake" onClick={get_contract}>
              انشاء معاملة
            </button>
          </span>
        </div>
      </div>
      <div className="navigation">
        <div
          className="part"
          ref={typeRef0}
          onClick={() => handelOnclick(typeRef0.current.dataset.type)}
        >
          مقدمة المعاملة
        </div>
        <div
          className="part"
          ref={typeRef1}
          onClick={() => handelOnclick(typeRef1.current.dataset.type)}
        >
          بيانات الصك
        </div>
        <div
          className="part"
          ref={typeRef2}
          onClick={() => handelOnclick(typeRef2.current.dataset.type)}
        >
          معلومات اخرى
        </div>
        <div
          className="part"
          ref={typeRef3}
          onClick={() => handelOnclick(typeRef3.current.dataset.type)}
        >
          المرفقات
        </div>
      
      </div>
      <article>
        <div className="inputs_feild">
          <div className="right">
            <label htmlFor="Assignment_date">
              <p>تاريخ التكليف</p>
              <input
                type="date"
                id="Assignment_date"
                name="Assignment_date"
                value={data.transaction_base_info.Assignment_date || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="Assignment_number">
              <p>رقم التكليف</p>
              <input
                type="text"
                id="Assignment_number"
                name="Assignment_number"
                value={data.transaction_base_info.Assignment_number || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="handover_date">
              <p>تاريخ التسليم</p>
              <input
                type="date"
                id="handover_date"
                name="handover_date"
                value={data.transaction_base_info.handover_date || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="model_type_id">
              <p>نوع النموزج</p>
              <select
                id="model_type_id"
                dir="rtl"
                name="model_type_id"
                value={data.transaction_base_info.model_type_id || ""}
                onChange={handleSelectChange}
              >
                <option value=""></option>
                {model_type}
              </select>
            </label>
            <label htmlFor="region">
              <p>المنطقة</p>
              <input
                type="text"
                id="region"
                name="region"
                value={data.transaction_base_info.region || ""}
                onChange={handleInputChange}
                autoComplete="off"
              />
            </label>
            <label htmlFor="district">
              <p>الحي</p>
              <input
                type="text"
                id="district"
                name="district"
                value={data.transaction_base_info.district || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="city">
              <p>المدينة</p>
              <input
                type="text"
                id="city"
                name="city"
                value={data.transaction_base_info.city || ""}
                onChange={handleInputChange}
              />
            </label>

            <label htmlFor="evaluator_id">
              <p>المثمن</p>
              <select
                id="evaluator_id"
                name="evaluator_id"
                value={data.transaction_base_info.evaluator_id || ""}
                onChange={handleSelectChange}
                dir="rtl"
              >
                <option value=""></option>
                <option value="1">حسام</option>
              </select>
            </label>
            <label htmlFor="owner_name">
              <p>اسم المالك</p>
              <input type="text" id="owner_name" name="owner_name" />
            </label>
            <label htmlFor="owner_phone">
              <p>جوال المالك</p>
              <input
                type="text"
                id="owner_phone"
                name="owner_phone"
                value={data.transaction_base_info.owner_phone || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="request_name">
              <p>طالب التقيم </p>
              <input
                type="text"
                id="request_name"
                name="request_name"
                value={data.transaction_base_info.request_name || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="user_name">
              <p>إسم المستخدم </p>
              <input
                type="text"
                id="user_name"
                name="user_name"
                value={data.transaction_base_info.user_name || ""}
                onChange={handleInputChange}
              />
            </label>
          </div>
        </div>
      </article>
      <article>
        <div className="inputs_feild">
          <div className="right sides">
            <label htmlFor="north">
              <p dir="rtl">الحد الشمالي</p>
              <span>
                <input
                  type="text"
                  placeholder="وصف"
                  name="north"
                  id="des"
                  onChange={handelmap}
                />
                <input
                  type="number"
                  placeholder="بطول"
                  name="north"
                  id="length"
                  onChange={handelmap}
                />
              </span>
            </label>
            <label htmlFor="south">
              <p dir="rtl">الحد الجنوبي</p>
              <span>
                <input
                  type="text"
                  placeholder="وصف"
                  name="south"
                  id="des"
                  onChange={handelmap}
                />
                <input
                  type="number"
                  placeholder="بطول"
                  name="south"
                  id="length"
                  onChange={handelmap}
                />
              </span>
            </label>
            <label htmlFor="east">
              <p dir="rtl">الحد الشرقي</p>
              <span>
                <input
                  type="text"
                  placeholder="وصف"
                  name="east"
                  id="des"
                  onChange={handelmap}
                />
                <input
                  type="number"
                  placeholder="بطول"
                  name="east"
                  id="length"
                  onChange={handelmap}
                />
              </span>
            </label>
            <label htmlFor="west">
              <p dir="rtl">الحد الغربي</p>
              <span>
                <input
                  type="text"
                  placeholder="وصف"
                  name="west"
                  id="des"
                  onChange={handelmap}
                />
                <input
                  type="number"
                  placeholder="بطول"
                  name="west"
                  id="length"
                  onChange={handelmap}
                />
              </span>
            </label>
          </div>
          <div className="right">
            <label htmlFor="Instrument_number">
              <p>رقم الصك</p>
              <input
                type="text"
                id="Instrument_number"
                name="Instrument_number"
                value={data.Instrument_number || ""}
                onChange={InputChange}
              />
            </label>
            {/* <label htmlFor="paperNo">
              <p>تاريخ الصك</p>
              <input type="text" id="paperNo" name="Assignment_date"
                value={data.Assignment_date || ""}
                onChange={InputChange} />
            </label> */}
            <label htmlFor="Instrum_place">
              <p> مكان اصدار الصك</p>
              <input
                type="text"
                id="Instrum_place"
                name="Instrum_place"
                value={data.Instrum_place || ""}
                onChange={InputChange}
              />
            </label>
            <label htmlFor="block_number">
              <p>رقم البلوك</p>
              <input
                type="text"
                id="block_number"
                name="block_number"
                value={data.block_number || ""}
                onChange={InputChange}
              />
            </label>
            {/* <label htmlFor="paperNo">
              <p>رقم القطعة</p>
              <input
                type="text"
                id="paperNo"
                name="Assignment_date"
                value={data.transaction_base_info.Assignment_date || ""}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="paperNo">
              <p>المساحة</p>
              <input
                type="text"
                id="paperNo"
                name="Assignment_date"
                value={data.transaction_base_info.Assignment_date || ""}
                onChange={handleInputChange}
              />
            </label> */}
          </div>
        </div>
      </article>
      <article>
        <div className="inputs_feild">
          <div className="right">
            <label htmlFor="release_number">
              <p>رقم فسح البناء</p>
              <input
                type="number"
                id="release_number"
                name="release_number"
                value={data.release_number || ""}
                onChange={InputChange}
              />
            </label>
            <label htmlFor="release_date">
              <p>تاريخ فسح البناء</p>
              <input
                type="date"
                id="release_date"
                name="release_date"
                value={data.release_date || ""}
                onChange={InputChange}
              />
            </label>
            <label htmlFor="release_source">
              <p>مصدر فسح البناء</p>
              <input
                type="text"
                id="release_source"
                name="release_source"
                value={data.release_source || ""}
                onChange={InputChange}
              />
            </label>
            {/* <label htmlFor="paperNo">
              <p>رقم عرض السعر</p>
              <input type="text" id="paperNo"  name="Instrument_number"
                value={data.Instrument_number || ""}
                onChange={InputChange}/>
            </label> */}
            <label htmlFor="request_type">
              <p>نوع الطلب</p>
              <input
                type="text"
                id="request_type"
                name="request_type"
                value={data.request_type || ""}
                onChange={InputChange}
              />
            </label>
            <label htmlFor="status">
              <p>الحالة</p>
              <input
                type="text"
                id="status"
                name="status"
                value={data.status || ""}
                onChange={InputChange}
              />
            </label>
            <label htmlFor="license_type">
              <p>نوع الرخصة</p>
              <input
                type="text"
                id="license_type"
                name="license_type"
                value={data.license_type || ""}
                onChange={InputChange}
              />
            </label>
            {/* <label htmlFor="paperNo">
              <p>رقم العقد</p>
              <input type="text" id="paperNo"  name="Instrument_number"
                value={data.Instrument_number || ""}
                onChange={InputChange}/>
            </label> */}
            <label htmlFor="" id="checkbox">
              <p dir="rtl">هل المعاملة عاجلة؟</p>
              <input
                type="checkbox"
                id="checkbox"
                name="urgent"
                value={data.urgent || ""}
                onChange={checkbox}
              />
            </label>
            <label htmlFor="" id="checkbox">
              <p dir="rtl">هل يحتوي علي تقرير هندسي؟</p>
              <input
                type="checkbox"
                id="checkbox"
                name="engineering_report"
                value={data.engineering_report || ""}
                onChange={checkbox}
              />
            </label>
            <label htmlFor="notes" className="textarea_label">
              <p>ملاحظات</p>
              <textarea
                name="notes"
                id="notes"
                dir="rtl"
                value={data.notes || ""}
                onChange={InputChange}
              ></textarea>
            </label>
          </div>
        </div>
      </article>
      <article>
        <div className="left">
          <div className="sub-title">
            <h3>ارفاق مستندات</h3>
          </div>
          <div className="files">
            <label htmlFor="add_file_btn" id="files_btn">
              <button form="fake" onClick={submit}>
                <p>حفظ المعاملة</p>
              </button>
              <button form="fake" onClick={add_new_file}>
                <p>اضافة مستند</p>
                <AiOutlineFileAdd />
              </button>
            </label>
            <span className="label_files"></span>
          </div>
        </div>
      </article>
      <div className="message" ref={messageRef}>
        <p className="message_content" ref={message_content_Ref}></p>
        <span ref={messageSVG}>
          <IoIosWarning />
        </span>
      </div>
    </form>
  );
}
export default To_transaction;
