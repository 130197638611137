"use client";
import React, { useState } from "react";
import { APIProvider, Marker, Map } from "@vis.gl/react-google-maps";

const MyMap = () => {
  const apiKey = "AIzaSyB2hR-3IkT4O5eL_PPrhmN4xstN9Lafmcs";
  const [open, setopen] = useState(false);

  const center = { lat: 37.7749, lng: -122.4194 }; // Example coordinates (San Francisco)

  return (
    <APIProvider apiKey={apiKey}>
      <div style={{ height: "100%", width: "100%" }}>
        <Map zoom={9} center={center}></Map>
      </div>
    </APIProvider>
  );
};

export default MyMap;
